import React, { useState, useContext, useEffect } from 'react'
import {
    CContainer,
    CRow,
    CCol,
    CInput,
    CTextarea,
    CInputGroup,
    CInputGroupPrepend,
    CInputGroupText
} from "@coreui/react"
import Select from "react-select"

import PropTypes from "prop-types"
import { Tooltip } from "components/common/tooltip"
import { UserSelectArray, UserSelectDropdown } from "components/common/forms"
import Joi from "joi"
import FormActions from '../FormActions'
import classNames from "classnames"
import { useSelector } from "react-redux"
import { useExtension } from "utils"
import { useUserAuth } from "utils/hooks"
import { LoadingSpinnerOverlay } from "components/common/loading"
import { postRequestSandboxAccount } from "extensions/usoc/api/service"
import SandboxRequestSuccess from "../components/SandboxRequestSuccess"
import ToasterProvider from "components/common/Context/ToasterContext"
import wbsCodeValidation from "utils/validate/validateWBS"

const inputSchema = Joi.object({

    name: Joi.string()
        .required()
        .min(10)
        .max(30)
        .label('name')
        .messages({
            "string.empty": "Cannot be an empty field",
            "string.base": "Should be a type of 'text'",
            "any.required": "Required field"
        }),
    description: Joi.string()
        .required()
        .min(20)
        .max(300)
        .label('description')
        .messages({
            "string.empty": "Cannot be an empty field",
            "string.base": "Should be a type of 'text'",
            "any.required": "Required field"
        }),
    wbsCode: Joi.string()
        .required()
        .custom(wbsCodeValidation)
        .messages({
            "any.required": "Cannot be an empty field",
            "string.empty": "Cannot be an empty field",
            "string.pattern": "Not a valid WBS code pattern"
        }),
    wbsCodeOwner: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": "Cannot be an empty field",
            "any.required": "Required field"
        }),
    expectedSpend: Joi.number().min(20).precision(2).required().messages({
        "number.base": "Must be a number",
        "number.min": "Must be equal or greater than 20",
        "any.required": "Required field"
    }),
    functionGroup: Joi.string().required(),
    responsibleBusinessOwner: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": "Cannot be an empty field",
            "any.required": "Required field"
        }),
    primaryTechnicalContact: Joi.string()
        .email({ tlds: { allow: false } })
        .required()
        .messages({
            "string.empty": "Cannot be an empty field",
            "any.required": "Required field"
        }),
    additionalDevelopers: Joi.array()
        .items(
            Joi.object({
                email: Joi.string()
                    .email({ tlds: { allow: false } })
                    .required()
                    .messages({
                        "string.empty": "Cannot be an empty field",
                        "string.email": "Must be valid email"
                    })
            }).unknown(true)
        )
        .messages({ "any.invalid": "Please populate or removed any unused items" })
})

const formInputConfig = {
    name: {
        name: "name",
        type: "text",
        placeholder: "Name of the project",
        tooltip:
            "Please specify the name of the project that this sandbox should be aligned to. This could be the engagement name or a 'friendly' name. 30 characters maximum.",
        label: "Name"
    },
    description: {
        name: "description",
        type: "text",
        placeholder: "A detailed description around the purpose of this sandbox",
        tooltip:
            "Please provide a description of what you intend to use the sandbox for. This could be to try out new technology, training, proof of concept etc..",
        label: "Description",
        rows: 3
    },
    wbsCode: {
        name: "wbsCode",
        type: "text",
        placeholder: "e.g. XYZ12345-00-00-00-0000",
        tooltip:
          "The WBS code used for this account. This WBS code will be recharged all cloud spend incurred within this sandbox and it needs to be a vaild UK WBS code.",
        label: "Charge Back (WBS Code)"
    },
    wbsCodeOwner: {
        name: "wbsCodeOwner",
        type: "text",
        placeholder: "",
        tooltip:
          "This field displays the owner of the WBS code. They will be contacted for approval prior to account creation",
        label: "WBS Code Owner"
    },
    expectedSpend: {
        name: "expectedSpend",
        type: "text",
        placeholder: "20",
        tooltip:
          "Please provide details of how much you expect to spend per month within the account (in USD). We will notify you on breach of this expected spend limit. (Note that we will not restrict services and it is your responsibility to monitor and manage your spend)",
        label: "Expected Spend"
    },
    functionGroup: {
        name: "functionGroup",
        type: "select",
        tooltip: "Please select the service line you are part of.",
        label: "Service Line",
        placeholder: "Please choose one from the dropdown items.",
        options: [
            { value: 'ADV', label: 'Advisory' },
            { value: 'AUD', label: 'Audit' },
            { value: 'CON', label: 'Consulting' },
            { value: 'ITS', label: 'ITS' },
            { value: 'TAX', label: 'Tax' }
          ]
    },
    responsibleBusinessOwner: {
        name: "responsibleBusinessOwner",
        type: "text",
        placeholder: "",
        tooltip:
          "The name of a Partner or Director that can approve this request. This person will receive an approval email request. Once the approval has been confirmed, account provision will begin. Note, this person will have overall accountability for this sandbox",
        label: "Responsible Business Owner (Partner, Director, Senior Director)"
    },
    primaryTechnicalContact: {
        name: "primaryTechnicalContact",
        type: "text",
        placeholder: "",
        tooltip:
          "The name of the person that will be primarily responsible for technical ownership of this account. This person is responsible for ensuring that the account is used responsibly and in-line with the provided terms and conditions",
        label: "Primary Technical Contact"
    },
    additionalDevelopers: {
        name: "additionalDevelopers",
        type: "text",
        placeholder: "",
        tooltip:
          "Provide the name(s) of any additional users that will require console access to the sandbox account",
        label: "Additional Technical Users"
    }

}

const validateField = (field, value) => {
    return inputSchema.extract(field).validate(value, { abortEarly: false });
}


const SandboxRequestForm = ({
    serviceProvider,
    resetParentStates,
    isTermsAndConditionsChecked
}) => {

    const auth = useUserAuth()
    const extension = useExtension()
    const host = extension?.config?.env?.clientApiGateway?.URL
    const userName = useSelector((state) => {
        return state.user.email
    })

    const enqueueToast = useContext(ToasterProvider)

    const [projectName, setProjectName] = useState("");
    const [projectNameError, setProjectNameError] = useState("");

    const [projectDescription, setProjectDescription] = useState("");
    const [projectDescriptionError, setProjectDescriptionError] = useState("");
    const [descriptionCharacterCount, setDescriptionCharacterCount] = useState(0);

    const [wbsCode, setWbsCode] = useState("");
    const [wbsCodeError, setWbsCodeError] = useState("");

    const [wbsCodeOwner, setWbsCodeOwner] = useState("");
    const [wbsCodeOwnerError, setWbsCodeOwnerError] = useState("");

    const [expectedSpend, setExpectedSpend] = useState("");
    const [expectedSpendError, setExpectedSpendError] = useState("");

    const [functionGroup, setFunctionGroup] = useState("");
    const [functionGroupError, setFunctionGroupError] = useState("");

    const [businessOwner, setBusinessOwner] = useState("");
    const [businessOwnerError, setBusinessOwnerError] = useState("");

    const [primaryTechnicalContact, setPrimaryTechnicalContact] = useState("");
    const [primaryTechnicalContactError, setPrimaryTechnicalContactError] = useState("");

    const [additionalDevelopers, setAdditionalDevelopers] = useState([]);
    const [additionalDevelopersError, setAdditionalDevelopersError] = useState("");

    const [isLoading, setIsLoading] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [sandboxRequestNumber, setSandboxRequestNumber] = useState("")

    const resetFormInput = () => {
        setProjectName("");
        setProjectDescription("");
        setWbsCode("");
        setWbsCodeOwner("");
        setExpectedSpend("");
        setFunctionGroup("");
        setBusinessOwner("");
        setPrimaryTechnicalContact("");
        setAdditionalDevelopers([]);
    }

    const resetFormError = () => {
        setProjectNameError("");
        setProjectDescriptionError("");
        setWbsCodeError("");
        setWbsCodeOwnerError("");
        setExpectedSpendError("");
        setFunctionGroupError("");
        setBusinessOwnerError("");
        setPrimaryTechnicalContactError("");
        setAdditionalDevelopersError("");
    }
    
    const setFormError = (field, error) => {
        switch (field) {
            case "name": 
                setProjectNameError(error);
                break;
            case "description":
                setProjectDescriptionError(error);
                break;
            case "wbsCode":
                setWbsCodeError(error);
                break;
            case "wbsCodeOwner":
                setWbsCodeOwnerError(error);
                break;
            case "expectedSpend":
                setExpectedSpendError(error);
                break;
            case "functionGroup":
                setFunctionGroupError(error);
                break;
            case "responsibleBusinessOwner":
                setBusinessOwnerError(error);
                break;
            case "primaryTechnicalContact":
                setPrimaryTechnicalContactError(error);
                break;
            case "additionalDevelopers": 
                setAdditionalDevelopersError(error);
                break;
            default:
                break;
        }
    }

    const getSandboxRequestFormHeading = () => {
        switch (serviceProvider) {
            case "AWS":
                return "AWS Sandbox Account Request Form"
            case "AZURE":
                return "Azure Sandbox Subscription Request Form"
            case "GCP":
                return "GCP Sandbox Project Request Form"
            default:
                return null
        }
    }

    const getSandboxRequestFormDescription = () => {
        switch (serviceProvider) {
            case "AWS":
                return (
                    <p className="sub-heading">
                        Sandbox Accounts have a baseline charge of approximately $20 per month.
                        <strong> In addition to this, any resources created within the Account will be charged at list price. </strong> 
                        Once the account is set up, the requestor, primary technical user and any additional technical users will have access to the Account through Okta.
                    </p>
                )
            case "AZURE":
                return (
                    <p className="sub-heading">
                        Sandbox Subscriptions have a baseline charge of approximately $20 per month which are the mandatory security controls enforced by Deloitte.
                        <strong> In addition to this, any resources created within the Subscription will be charged at list price. </strong>
                        Once the subscription is set up, the requestor, primary technical user and any additional technical users will have access to the Subscription through Deloitte's internal Entra ID.
                    </p>
                )
            case "GCP":
                return (
                    <p className="sub-heading">
                        Sandbox GCP Projects have a baseline charge of approximately $20 per month which are the mandatory security controls enforced by Deloitte.
                        <strong> In addition to this, any resources created within the project will be charged at list price. </strong>
                        Once the project is set up, the requestor, primary technical user and any additional technical users will have access to the project through Deloitte's internal Entra ID.
                    </p>
                )
            default:
                return null
        }
    }


    const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
        const { error } = validateField('name', e.target.value);
        if (error) {
            error.details.forEach((eachError) => {
                setProjectNameError(eachError.message);
            });
        } else {
            setProjectNameError("");
        }
    }

    const handleProjectDescriptionChange = (e) => {
        setProjectDescription(e.target.value);
        setDescriptionCharacterCount(e.target.value.length);
        const { error } = validateField('description', e.target.value);
        if (error) {
            error.details.forEach((eachError) => {
                setProjectDescriptionError(eachError.message);
            });
        } else {
            setProjectDescriptionError("");
        }
    }

    const handleWbsCodeChange = (e) => {
        setWbsCode(e.target.value);
        const { error } = validateField('wbsCode', e.target.value);
        if (error) {
            error.details.forEach((eachError) => {
                setWbsCodeError(eachError.message);
            });
        } else {
            setWbsCodeError("");
        }
    }

    const handleExpectedSpendChange = (e) => {
        setExpectedSpend(e.target.value);
        const { error } = validateField('expectedSpend', e.target.value);
        if (error) {
            error.details.forEach((eachError) => {
                setExpectedSpendError(eachError.message);
            });
        } else {
            setExpectedSpendError("");
        }
    }

    const handleFunctionGroupChange = (item) => {
        setFunctionGroup(item.value);
        const { error } = validateField('functionGroup', item.value);
        if (error) {
            error.details.forEach((eachError) => {
                setFunctionGroupError(eachError.message);
            });
        } else {
            setFunctionGroupError("");
        }
    }

    useEffect(() => {
        setWbsCodeOwnerError("");
    }, [wbsCodeOwner]);

    useEffect(() => {
        setBusinessOwnerError("");
    }, [businessOwner]);

    useEffect(() => {
        setPrimaryTechnicalContactError("");
    }, [primaryTechnicalContact]);

    useEffect(() => {
        setAdditionalDevelopersError("");
    }, [additionalDevelopers]);


    const handleFormSubmit = async () => {
        resetFormError();

        const { error } = await inputSchema.validate({
            name: projectName,
            description: projectDescription,
            wbsCode: wbsCode,
            wbsCodeOwner: wbsCodeOwner,
            expectedSpend: expectedSpend,
            functionGroup: functionGroup,
            responsibleBusinessOwner: businessOwner,
            primaryTechnicalContact: primaryTechnicalContact,
            additionalDevelopers: additionalDevelopers
        }, { abortEarly: false });

        if (error) {
            error.details.forEach((eachError) => {
                setFormError(eachError.path?.[0], eachError.message);
            })
        } else {
            setIsLoading(true);
            const requestBody = {
                service_provider: serviceProvider,
                wbs_code: wbsCode,
                wbs_code_owner: wbsCodeOwner,
                primary_technical_contact: primaryTechnicalContact,
                responsible_business_owner: businessOwner,
                technical_users: additionalDevelopers.map((user) => user.email),
                name: projectName,
                description: projectDescription,
                expected_spend: expectedSpend,
                requested_by: userName,
                terms_and_conditions_checked: isTermsAndConditionsChecked,
                function_group: functionGroup,
                country_code: "GB"
            }
            const response = await postRequestSandboxAccount(
                {
                    auth,
                    host,
                    body: JSON.stringify(requestBody)
                },
                ""
            )

            if (response.error) {
                enqueueToast(
                    "failure",
                    "Sandbox Request Failed",
                    "Something went wrong... please try again later"
                )
            } else {
                setSandboxRequestNumber(response.data.request_number);
                setFormSubmitted(true);
            }
            setIsLoading(false)
        }
    }

    const handleFormCancel = () => {
        resetFormInput();
        resetFormError();
        resetParentStates();
    }

    if (formSubmitted) {
        return (
            <SandboxRequestSuccess 
                serviceProvider={serviceProvider}
                requestNumber={sandboxRequestNumber}
            />
        )
    }

    return (
        <CContainer className='service-request-container py-2'>
            <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
                <CRow className='py-2 justify-content-start'>
                    <CCol>
                        <p className="heading py-2">{getSandboxRequestFormHeading()}</p>
                        {getSandboxRequestFormDescription()}
                    </CCol>
                </CRow>

                <CRow className='py-2 justify-content-center'>
                    <CCol lg={6} md={8} sm={12}>
                        <h5>Project Details</h5>
                    </CCol>
                </CRow>
                <CContainer className='m-2'>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.name.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.name.tooltip}
                                />
                            </p>
                            <CInput
                                type={formInputConfig.name.type}
                                name={formInputConfig.name.name}
                                placeholder={formInputConfig.name.placeholder}
                                onChange={handleProjectNameChange}
                                className={classNames({ "is-invalid": (projectNameError) })}
                            />
                            <div className="text-danger pt-2"><small>{projectNameError}</small></div>
                        </CCol>
                    </CRow>

                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.description.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.description.tooltip}
                                />
                            </p>
                            <CTextarea
                                style={{ height: "auto" }}
                                rows={6}
                                type={formInputConfig.description.type}
                                name={formInputConfig.description.name}
                                placeholder={formInputConfig.description.placeholder}
                                onChange={handleProjectDescriptionChange}
                                className={classNames({ "is-invalid": (projectDescriptionError) })}
                            />
                            
                            <CRow className='pr-4 pt-2'>
                                <CCol md={11}>
                                    <div className="text-danger"><small>{projectDescriptionError}</small></div>
                                </CCol>
                                <CCol md={1}>
                                    <p>{descriptionCharacterCount}/300</p>
                                </CCol>
                            </CRow>
                            
                        </CCol>
                    </CRow>

                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.wbsCode.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.wbsCode.tooltip}
                                />
                            </p>
                            <CInput
                                type={formInputConfig.wbsCode.type}
                                name={formInputConfig.wbsCode.name}
                                placeholder={formInputConfig.wbsCode.placeholder}
                                onChange={handleWbsCodeChange}
                                className={classNames({ "is-invalid": (wbsCodeError) })}
                            />
                            <div className="text-danger pt-2"><small>{wbsCodeError}</small></div>
                        </CCol>
                    </CRow>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.wbsCodeOwner.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.wbsCodeOwner.tooltip}
                                />
                            </p>
                            <UserSelectDropdown
                                setState={setWbsCodeOwner}
                            />
                            <div className="text-danger pt-2"><small>{wbsCodeOwnerError}</small></div>
                        </CCol>
                    </CRow>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.expectedSpend.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.expectedSpend.tooltip}
                                />
                            </p>
                            <p>
                                <small>Base operating cost of approximately $20/month</small>
                            </p>
                            <CInputGroup className="input-prepend">
                                <CInputGroupPrepend>
                                    <CInputGroupText color="secondary">$</CInputGroupText>
                                </CInputGroupPrepend>
                                <CInput
                                    type={formInputConfig.expectedSpend.type}
                                    name={formInputConfig.expectedSpend.name}
                                    placeholder={formInputConfig.expectedSpend.placeholder}
                                    onChange={handleExpectedSpendChange}
                                    className={classNames({ "is-invalid": (expectedSpendError) })}
                                />
                            </CInputGroup>
                            <div className="text-danger pt-2"><small>{expectedSpendError}</small></div>
                        </CCol>
                    </CRow>

                </CContainer>
                <CRow className='py-2 justify-content-center'>
                    <CCol lg={6} md={8} sm={12}>
                        <h5>Ownership Information</h5>
                    </CCol>
                </CRow>
                <CContainer className='m-2'>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.functionGroup.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.functionGroup.tooltip}
                                />
                            </p>
                            <Select
                                placeholder={formInputConfig.functionGroup.placeholder} 
                                options={formInputConfig.functionGroup.options}
                                isSearchable={true}
                                name={formInputConfig.functionGroup.name}
                                onChange={handleFunctionGroupChange}
                            />
                            <div className="text-danger pt-2"><small>{functionGroupError}</small></div>
                        </CCol>
                    </CRow>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.responsibleBusinessOwner.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.responsibleBusinessOwner.tooltip}
                                />
                            </p>
                            <UserSelectDropdown
                                setState={setBusinessOwner}
                                titleFilter={["partner", "director", "senior director"]}
                            />
                            <div className="text-danger pt-2"><small>{businessOwnerError}</small></div>
                        </CCol>
                    </CRow>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.primaryTechnicalContact.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.primaryTechnicalContact.tooltip}
                                />
                            </p>
                            <UserSelectDropdown
                                setState={setPrimaryTechnicalContact}
                            />
                            <div className="text-danger pt-2"><small>{primaryTechnicalContactError}</small></div>
                        </CCol>
                    </CRow>
                    <CRow className='py-2 justify-content-center'>
                        <CCol lg={6} md={8} sm={12}>
                            <p className="field-label font-weight-bold">
                                {formInputConfig.additionalDevelopers.label}
                                {" "}
                                <Tooltip
                                    className="form-tooltip-icon"
                                    text={formInputConfig.additionalDevelopers.tooltip}
                                />
                            </p>
                            <UserSelectArray
                                state={additionalDevelopers}
                                setState={setAdditionalDevelopers}
                            />
                            <div className="text-danger pt-2"><small>{additionalDevelopersError}</small></div>
                        </CCol>
                    </CRow>

                </CContainer>

                <CRow className="justify-content-center m-4">
                    <CCol md={{span: 6, offset: 2}} className="mr-n5">
                        <FormActions onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
                    </CCol>
                </CRow>
            </LoadingSpinnerOverlay>
        </CContainer>

    )
}

SandboxRequestForm.propTypes = {
    serviceProvider: PropTypes.string,
    resetParentStates: PropTypes.func,
    isTermsAndConditionsChecked: PropTypes.bool
}

export default SandboxRequestForm;