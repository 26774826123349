import PropTypes from "prop-types"
import React from "react"

const { createContext, useState } = React
const AccountContext = createContext(null)

function AccountContextProvider(props) {
  const [accounts, setAccounts] = useState(props.accounts || [])
  const [selectedAccounts, setSelectedAccounts] = useState(
    props.selectedAccounts || []
  )
  const [projectBonding, setProjectBonding] = useState({})
  const [projectBondLoading, setProjectBondLoading] = useState(false)

  const setAllAccounts = (accounts) => {
    setAccounts(accounts)
    if (selectedAccounts.length === 0) {
      setSelectedAccounts(accounts)
    }
  }

  return (
    <AccountContext.Provider
      value={{
        accounts,
        selectedAccounts,
        setAllAccounts,
        setSelectedAccounts,
        projectBonding,
        setProjectBonding,
        projectBondLoading,
        setProjectBondLoading
      }}
    >
      {props.children}
    </AccountContext.Provider>
  )
}
AccountContextProvider.propTypes = {
  accounts: PropTypes.array,
  selectedAccounts: PropTypes.array,
  children: PropTypes.node
}
export default AccountContext
export { AccountContextProvider }
