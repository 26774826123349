import { CSpinner } from "@coreui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

export default function SpinnerOverlay({
  isLoading,
  opacity = 0.6,
  children,
  centerX = true,
  centerY = true,
  spinnerSize = "4rem"
}) {
  const spinnerContainerClasses = classNames(
    "m-0",
    "p-0",
    "h-100",
    "w-100",
    "position-absolute",
    "d-flex",
    {
      "justify-content-center": centerX,
      "align-items-center": centerY
    }
  )
  return (
    <div className="p-0 m-0 position-relative">
      {children}
      {isLoading ? (
        <div
          className={spinnerContainerClasses}
          style={{ opacity, backgroundColor: "white", top: 0, left: 0 }}
          data-testid="spinner-overlay"
        >
          <CSpinner
            className="m-4"
            style={{ width: spinnerSize, height: spinnerSize }}
            color="secondary"
          />
        </div>
      ) : (
        ""
      )}
    </div>
  )
}
SpinnerOverlay.propTypes = {
  isLoading: PropTypes.bool,
  opacity: PropTypes.number,
  children: PropTypes.node,
  centerX: PropTypes.bool,
  centerY: PropTypes.bool,
  spinnerSize: PropTypes.string
}
