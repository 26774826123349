import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, project, params) => {
    return `${host}/cp/${project}/update_client_portal_feedback?${params}`
  },
  checkRequiredArguments: (feedbackData) => {
    if (feedbackData === undefined)
      throw new Error("No feedback data supplied.")
  },
  makeResponseData: (json) => {
    return json
  },
  returnRawResponse: true
}
export default createFetcher(fetcherConfiguration)
