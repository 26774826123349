import { CButton, CSpinner } from "@coreui/react"
import download from "assets/icons/get_app.svg"
import ToasterProvider from "components/common/Context/ToasterContext"
import { fetchAttachments } from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useExtension } from "utils"
import { useUserAuth } from "utils/hooks"

function Attachments({ attachmentName, ticketNumber, projectId }) {
  const auth = useUserAuth()
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL

  const enqueueToast = useContext(ToasterProvider)
  const [attachmentURL, setAttachmentURL] = useState("")
  const [attachmentLoading, setAttachmentLoading] = useState(false)

  const downloadAttachment = (attachmentName, attachmentDownloadLink) => {
    const downloadLink = document.createElement("a")
    downloadLink.href = attachmentDownloadLink
    downloadLink.download = attachmentName
    downloadLink.target = "_newtab"
    downloadLink.click()
  }

  const attachmentClickHandler = async () => {
    setAttachmentLoading(true)
    const response = await fetchAttachments(
      { auth, host },
      projectId,
      ticketNumber,
      attachmentName
    )
    setAttachmentLoading(false)
    if (response.error || !response.data) {
      enqueueToast("failure", null, "Error in fetching the attachment.")
    } else {
      setAttachmentURL(response.data?.presigned_url)
      downloadAttachment(attachmentName, response.data?.presigned_url)
    }
  }
  return (
    <>
      <div className="attachment-container">
        <CButton
          disabled={attachmentLoading}
          onClick={
            attachmentURL
              ? () => {
                  downloadAttachment(attachmentName, attachmentURL)
                }
              : attachmentClickHandler
          }
          className={`pl-1 ${
            attachmentLoading ? "cursor-not-allowed" : "cursor-pointer"
          } `}
        >
          {attachmentLoading ? (
            <CSpinner size="sm" className="attachment-spinner" />
          ) : (
            <img
              src={download}
              className={`download-icon pr-1 ${
                attachmentLoading ? "cursor-not-allowed" : "cursor-pointer"
              }`}
              alt="download icon"
            />
          )}

          {attachmentName}
        </CButton>
      </div>
    </>
  )
}

export default Attachments
Attachments.propTypes = {
  attachmentName: PropTypes.string,
  ticketNumber: PropTypes.string,
  projectId: PropTypes.string
}
