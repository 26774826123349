export default function (policy) {
  const allowedPaths = []
  // eslint-disable-next-line no-unused-vars
  const _ = policy?.modules?.forEach?.((moduleObj) => {
    const modulePrefix = moduleObj.module ? `/${moduleObj.module}` : ""
    for (const path of moduleObj.paths) {
      if (path === "/" && modulePrefix === "") allowedPaths.push(path)
      if (path === "/") allowedPaths.push(modulePrefix)
      else {
        let formattedPath = path
        if (formattedPath[-1] === "/") {
          formattedPath = path.slice(0, formattedPath.length - 1)
        }
        if (formattedPath[0] !== "/") formattedPath = "/" + formattedPath
        allowedPaths.push(modulePrefix + formattedPath)
      }
    }
  })
  return allowedPaths
}
