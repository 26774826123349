import AccountProvider from "components/common/Context/AccountContext"
import { NoData, NoDataAndWentWrongHandler } from "components/common/noData"
import { Table } from "components/common/table"
import { getExcelDataFromTableData } from "components/common/table/Table"
import { getIncidentDetails } from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useCallback, useContext, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import useSWR from "swr"
import { useExtension } from "utils"
import { toStandardDateString } from "utils/date"
import { useUserAuth } from "utils/hooks"
import { isAnyAPIFailed } from "utils/nodata"

import IncidentModal from "./IncidentModal"

async function fetchIncidentDetails(auth, extension, projectId, params) {
  const host = extension?.config?.env?.clientApiGateway?.URL
  const promises = []
  for (const param of params) {
    promises.push(
      getIncidentDetails({ auth, host }, projectId, param).catch((err) => {
        throw err
      })
    )
  }
  const results = await Promise.all(promises)
  return results
}

const awaitingStates = [
  "Awaiting Problem",
  "Awaiting User Info",
  "Awaiting Evidence"
]

const categoryMap = {
  NETWORK: "Availability",
  SERVER: "Performance",
  SECURITY: "Security",
  "RISK MANAGEMENT": "Compliance"
}

export default function IncidentsTable({
  state,
  dateRange,
  defaultSelectedPriorities,
  defaultSelectedBreakdown
}) {
  const auth = useUserAuth()
  const userEmail = useSelector((state) => {
    return state.user.email
  })
  const { projectBonding } = useContext(AccountProvider)
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()

  const [isIncidentModalOpen, setIsIncidentModalOpen] = useState(false)
  const [selectedIncidentNumber, setselectedIncidentNumber] = useState()

  const dateParams = `start_date=${toStandardDateString(
    dateRange?.startDate
  )}&end_date=${toStandardDateString(dateRange?.endDate)}`

  const openParams = [
    `state=New,Assessment,More Info Required,In Progress,With 3rd Party,Paused with Change,Paused with Problem&${dateParams}`
  ]

  const resolvedParams = [`state=resolved,closed&${dateParams}`]

  const params = openParams.concat(resolvedParams)

  const { data: incResult } = useSWR(
    () =>
      projectID
        ? params.map(
            (param) => `/projects/${projectID}/get_incident_details?${param}`
          )
        : null,
    () => fetchIncidentDetails(auth, extension, projectID, params),
    { suspense: true }
  )

  const missedParams = [
    `state=New,Assessment,More Info Required,In Progress,With 3rd Party,Paused with Change,Paused with Problem,Resolved,closed&${dateParams}${
      state === "Resolution" || state === "Response"
        ? `&SLA_${state.toUpperCase()}_BREACHED=true`
        : ""
    }`
  ]

  const { data: missedResult } = useSWR(
    () =>
      projectID && (state === "Resolution" || state === "Response")
        ? missedParams.map(
            (param) => `/projects/${projectID}/get_incident_details?${param}`
          )
        : null,
    () => fetchIncidentDetails(auth, extension, projectID, missedParams),
    { suspense: true }
  )

  const openIncidents = useMemo(() => {
    return incResult[0].data
  }, [incResult])

  const resolvedIncidents = useMemo(() => {
    return incResult[1].data
  }, [incResult])

  function handleIncidentNoClick(incidentNum) {
    setselectedIncidentNumber(incidentNum)
    setIsIncidentModalOpen(true)
  }

  const headers = useMemo(
    () => [
      { key: "incident_number", text: "Ticket Id", showOnCollapsedCard: true },
      { key: "priority", text: "Priority", showOnCollapsedCard: true },
      {
        key: "created_date",
        text: "Date of Creation",
        showOnCollapsedCard: true
      },
      { key: "incident_state", text: "Status", showOnCollapsedCard: true },
      {
        key: "short_description",
        text: "Short Desc",
        tooltipRequired: true,
        showOnCollapsedCard: true
      },
      { key: "account_name", text: "Cloud Service Account" },
      { key: "category", text: "Category" },
      { key: "sub_category", text: "Sub-Category" },
      { key: "caller", text: "Created By" }
    ],
    []
  )

  const getTableData = useCallback(
    (incidents) =>
      incidents.map((eachIncData) => ({
        _key: eachIncData.incident_number,
        _onClick: !(
          projectBonding?.incident_e_bonded?.toLowerCase() !== "false"
        )
          ? () => handleIncidentNoClick(eachIncData.incident_number)
          : null,
        incident_number: {
          text: eachIncData.incident_number
        },
        priority: { text: `P${eachIncData.priority.split(" ")[0]}` },
        created_date: { text: eachIncData.created_date },
        incident_state: {
          text: eachIncData.incident_state,
          ele: (
            <div
              className={`status-cell ${
                !["Resolved", "Closed"].includes(eachIncData.incident_state)
                  ? "open"
                  : "solved"
              }`}
            >
              {eachIncData.incident_state}
            </div>
          )
        },
        short_description: { text: eachIncData.short_description },
        account_name: {
          text: eachIncData.account_name || "Not specified"
        },
        category: {
          text: categoryMap[eachIncData.category]
            ? categoryMap[eachIncData.category]
            : eachIncData.category
        },
        sub_category: { text: eachIncData.sub_category },
        caller: { text: eachIncData.caller }
      })),
    []
  )

  const data = useMemo(() => {
    let incidentsTableData = []
    if (state === "Response" || state === "Resolution") {
      incidentsTableData = missedResult.reduce(
        (sum, accountMissedResult) => [
          ...sum,
          ...(accountMissedResult?.data || [])
        ],
        []
      )
    } else if (state.includes("Awaiting")) {
      incidentsTableData = openIncidents.filter((data) =>
        awaitingStates.includes(data.incident_state)
      )
    } else if (state.includes("Breakdown")) {
      incidentsTableData = [...openIncidents, ...resolvedIncidents]
    } else if (state === "Open") {
      incidentsTableData = openIncidents
    } else if (state === "Resolved") {
      incidentsTableData = resolvedIncidents
    } else {
      incidentsTableData = [...openIncidents, ...resolvedIncidents].filter(
        (ticket) => ticket.caller_email === userEmail
      )
    }

    return getTableData(incidentsTableData)
  }, [
    state,
    missedResult,
    openIncidents,
    resolvedIncidents,
    userEmail,
    getTableData
  ])

  return (
    <>
      <NoDataAndWentWrongHandler
        wentWrongCondition={
          isAnyAPIFailed(incResult) || isAnyAPIFailed(missedResult)
        }
      >
        <Table
          headers={headers}
          data={data}
          defaultSortKey="created_date"
          defaultSelectedTags={{
            ...(defaultSelectedBreakdown?.length
              ? { sub_category: defaultSelectedBreakdown }
              : {}),
            ...(defaultSelectedPriorities?.length
              ? { priority: defaultSelectedPriorities }
              : {})
          }}
          excelFilename={
            ["Open", "Resolved", "My Tickets"].includes(state)
              ? "Incidents"
              : state
          }
          customExcelDatasets={
            ["Open", "Resolved", "My Tickets"].includes(state)
              ? [
                  getExcelDataFromTableData(
                    "Open",
                    headers,
                    getTableData(openIncidents)
                  ),
                  getExcelDataFromTableData(
                    "Resolved",
                    headers,
                    getTableData(resolvedIncidents)
                  ),
                  getExcelDataFromTableData(
                    "My Tickets",
                    headers,
                    getTableData(
                      [...openIncidents, ...resolvedIncidents].filter(
                        (ticket) => ticket.caller_email === userEmail
                      )
                    )
                  )
                ]
              : null
          }
          noDataComponent={<NoData />}
        />
      </NoDataAndWentWrongHandler>
      <IncidentModal
        show={isIncidentModalOpen}
        setShow={setIsIncidentModalOpen}
        incidentNumber={selectedIncidentNumber}
      />
    </>
  )
}
IncidentsTable.propTypes = {
  dateRange: PropTypes.object,
  state: PropTypes.string,
  defaultSelectedPriorities: PropTypes.array,
  defaultSelectedBreakdown: PropTypes.array
}
