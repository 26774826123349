import classNames from "classnames"
import { useTheme } from "components/common/Context/ThemeContext"
import PropTypes from "prop-types"
import React from "react"

export default function GreyedOut({ children, enable = false, setIsOpen, mblScreen = false }) {
  const theme = useTheme()
  const maskStyle = {
    top: `${mblScreen ? "3.8rem" : "-0.7rem"}`,
    left: `${mblScreen ?  "0" : "-12.5rem"}`,
    opacity: 0.8,
    height: "112.5%",
    backgroundColor: theme.greyedOutColor,
    zIndex: 1020
  }
  const maskClasses = classNames({"d-none": !enable}, ["position-fixed", "w-100"])

  function onClick() {
    if (setIsOpen && enable) {
      setIsOpen(false)
    }
  }

  return (
    <div className={`h-100 w-100 ${enable ? "scroll-disable" : ""}`}>
      {children}
      <div
        className={maskClasses}
        style={maskStyle}
        onClick={onClick.bind(this)}
        data-testid="grey-out"
      />
    </div>
  )
}

GreyedOut.propTypes = {
  children: PropTypes.node,
  enable: PropTypes.bool,
  setIsOpen: PropTypes.func,
  mblScreen: PropTypes.bool
}
