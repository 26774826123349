import { useSelector } from 'react-redux';
import { CRow, CCol, CInput, CButton, CSelect, CContainer, CInputRadio, CTextarea } from '@coreui/react';
import classNames from 'classnames';
import requestUserAccess from "api/helpers/postJobInitiateFunction";
import { useExtension, useUserAuth } from "utils";
import React, { useState, useEffect } from 'react';

const AddNewUserForm = () => {
  const accounts = useSelector(state => state.user.accounts?.account_attribute || []);
  const projectID = useSelector(state => state.settings.currentProject.id);
  const userid = useSelector(state => state.user.email);
  const userRole = useSelector((state) => state.user.role);
  const auth = useUserAuth();
  const extension = useExtension();
  const host = extension?.config?.env?.clientApiGateway?.URL;

  const [selectedAccount, setSelectedAccount] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [accessType, setAccessType] = useState('1hr');
  const [accessEndDate, setAccessEndDate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [responseStatusCode, setResponseStatusCode] = useState(null);
  const [justification, setJustification] = useState('');
  const [roleOptions, setRoleOptions] = useState([]);
  const [isFetchingRoles, setIsFetchingRoles] = useState(false);

  const [selectedAction, setSelectedAction] = useState('Add User');

  useEffect(() => {
    if (selectedAccount) {
      fetchRoleOptions();
    }
  }, [selectedAccount]);


  const fetchRoleOptions = async () => {
    setIsFetchingRoles(true);
    setMessage('');
    try {
      const requestData = {
        function_name: 'portal-awsaccountaccess',
        function_body: {
          action: 'get_roles',
          awsaccountnumber: selectedAccount,
          projectid: projectID,
        },
      };
      const response = await requestUserAccess({ auth, host, body: JSON.stringify(requestData) }, projectID);
      if (response && !response.error) {
        const responseData = JSON.parse(response.data);
        if (responseData.statuscode === 200) {
          const responseBody = JSON.parse(responseData.message);
          let roles = responseBody.roles.map(roleName => ({
            label: roleName,
            value: roleName
          }));
          // Exclude Administrative Roles unless you are a Portal-Admin
          if (userRole !== 'Portal-Admin') {
            roles = roles.filter(role => role.value !== 'OC-Administrator' && role.value !== 'OC-AdministratorDeveloper');
          }
          setRoleOptions(roles);
        } else {
          throw new Error('Failed to fetch role options.');
        }
      } else {
        throw new Error('API returned an error: ' + response.error);
      }
    } catch (error) {
      console.error('Fetch Error:', error);
      setMessage('Failed to fetch roles. Please try again.');
    } finally {
      setIsFetchingRoles(false);
    }
  };


  const initialFormState = {
    selectedAccount: '',
    emailAddress: '',
    selectedRole: '',
    accessType: '1hr',
    accessEndDate: '',
    justification: '',
  };
  const [formState, setFormState] = useState(initialFormState);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setMessage('');
    setResponseStatusCode(null);

    // Check if the email address is the same as the current user's email
    if (emailAddress === userid) {
      setMessage('You cannot grant access to yourself. Please enter a different email address.');
      setIsLoading(false);
      return;
    }

    const isPermanentAccess = accessType === 'Custom';
    const accessTimeFrame = accessType !== 'Custom' ? accessType : undefined;

    const requestData = {
      function_name: 'portal-awsaccountaccess',
      function_body: {
        projectid: projectID,
        awsaccountnumber: selectedAccount,
        emailaddress: emailAddress,
        rolename: selectedRole,
        ispermanentaccess: isPermanentAccess,
        access_end_date: isPermanentAccess ? accessEndDate : undefined,
        timeframe: accessTimeFrame,
        requestor_id: userid,
        action: selectedAction === 'Add User' ? 'add_permission' : 'remove_permission',
        justification: justification,
      },
    };

    try {
      const response = await requestUserAccess({ auth, host, body: JSON.stringify(requestData) }, projectID);
      console.log('Response:', response);

      if (!response || response.error) {
        setMessage('Failed to add the user. Please try again.');
      } else {
        const responseData = JSON.parse(response.data);
        setResponseStatusCode(responseData.statuscode);
        if (responseData && responseData.message) {
          setMessage(responseData.message);
        } else {
          setMessage('Access request submitted successfully, please allow up to 5 minutes for access to be provisioned.');
        }
      }
    } catch (err) {
      console.error('Error:', err);
      setMessage('An unexpected error occurred while processing your access request. Please try again later.');
      setResponseStatusCode(err.response?.status);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFormReset = () => {
    setFormState(initialFormState);
    setMessage('');
    setResponseStatusCode(null);
  };

  return (
    <CContainer>
      <div className="add-new-user-form" style={{ maxWidth: '600px', margin: '0 auto' }}>
        <CRow>
          <CCol>
            <h1>Manage access to AWS Account</h1>
            <p>Use this form to request the temporary addition or removal of users to an existing AWS account with a specified role.</p>
            <p>&nbsp;</p>
            <p>Please note that by initiating this request, you are responsible for ensuring all necessary approvals have been obtained for this access.</p>
            <p>&nbsp;</p>
            <p>The requestor assumes full accountability for the legitimacy and compliance of the access provisioned.</p>
            <p>&nbsp;</p>
          </CCol>
        </CRow>
        <CRow className="m-2">
          <CCol md="6">
            <CInputRadio
              className="mr-2"
              checked={selectedAction === 'Add User'}
              onChange={() => setSelectedAction('Add User')}
            />
            <p className={`field-label ml-2 mt-1 ${selectedAction === 'Add User' ? 'font-weight-bold' : ''}`}>Add User</p>
          </CCol>
          <CCol md="6">
            <CInputRadio
              className="mr-2"
              checked={selectedAction === 'Remove User'}
              onChange={() => setSelectedAction('Remove User')}
            />
            <p className={`field-label ml-2 mt-1 ${selectedAction === 'Remove User' ? 'font-weight-bold' : ''}`}>Remove User</p>
            <p>&nbsp;</p>
          </CCol>
        </CRow>
        {message && (
          <div className={classNames("alert", {
            "alert-info": responseStatusCode !== 404 && message !== 'You cannot grant access to yourself. Please enter a different email address.',
            "alert-danger": (responseStatusCode === 404 || message === 'You cannot grant access to yourself. Please enter a different email address.'),
          })}>{message}</div>
        )}
        <form onSubmit={handleSubmit} onReset={handleFormReset}>
          <CRow form>
            <CCol md="12">
              <div className="mb-3">
                <label htmlFor="accountSelect" className="form-label">AWS Account</label>
                <CSelect
                  id="accountSelect"
                  value={selectedAccount}
                  onChange={(e) => setSelectedAccount(e.target.value)}
                  className={classNames({ 'is-invalid': !selectedAccount })}
                >
                  <option value="">Select an account</option>
                  {accounts.map(account => (
                    <option key={account.account_number} value={account.account_number}>
                      {account.account_environment} ({account.account_number})
                    </option>
                  ))}
                </CSelect>
              </div>
            </CCol>
            <CCol md="12">
              <div className="mb-3">
                <label htmlFor="roleSelect" className="form-label">Role</label>
                <CSelect
                  id="roleSelect"
                  value={selectedRole}
                  onChange={(e) => setSelectedRole(e.target.value)}
                  className={classNames({ 'is-invalid': !selectedRole })}
                  disabled={isFetchingRoles}  // Disable selection while fetching
                >
                  <option value="">{isFetchingRoles ? "Fetching available roles..." : "Select a role"}</option>
                  {roleOptions.map(role => (
                    <option key={role.value} value={role.value}>
                      {role.label}
                    </option>
                  ))}
                </CSelect>
              </div>
            </CCol>
            <CCol md="12">
              <div className="mb-3">
                <label htmlFor="emailInput" className="form-label">User Email Address</label>
                <CInput
                  type="email"
                  id="emailInput"
                  placeholder="Enter user's email"
                  value={emailAddress}
                  onChange={(e) => setEmailAddress(e.target.value)}
                  className={classNames({ 'is-invalid': !emailAddress })}
                />
              </div>
            </CCol>
            <CCol md="12">
              <div className="mb-3">
                <label htmlFor="accessTypeSelect" className="form-label">Access Duration</label>
                <CSelect
                  id="accessTypeSelect"
                  value={accessType}
                  onChange={(e) => setAccessType(e.target.value)}
                  className={classNames({ 'is-invalid': !accessType })}
                >
                  <option value="1hr">1 hour (Default)</option>
                  <option value="2hrs">2 hours</option>
                  <option value="3hrs">3 hours</option>
                  <option value="4hrs">4 hours</option>
                  <option value="5hrs">5 hours</option>
                  <option value="6hrs">6 hours</option>
                  <option value="7hrs">7 hours</option>
                  <option value="8hrs">8 hours</option>
                  {/* <option value="Custom">Custom</option> */}
                </CSelect>
              </div>
              <div className="mb-3">
                <label htmlFor="justificationTextarea" className="form-label">Justification</label>
                <CTextarea
                  id="justificationTextarea"
                  rows="4"
                  value={justification}
                  onChange={(e) => setJustification(e.target.value)}
                  placeholder="Enter justification for this request"
                  className={classNames({ 'is-invalid': !justification })}
                />
              </div>
            </CCol>
            {accessType === 'Custom' && (
              <CCol md="12">
                <div className="mb-3">
                  <label htmlFor="dateSelect" className="form-label">Access End Date</label>
                  <CInput
                    type="date"
                    id="dateSelect"
                    value={accessEndDate}
                    onChange={(e) => setAccessEndDate(e.target.value)}
                    className={classNames({ 'is-invalid': !accessEndDate })}
                  />
                </div>
              </CCol>
            )}
            <CCol md="12">
              <CButton type="submit" color="primary" disabled={isLoading}>
                {isLoading ? 'Submitting...' : 'Submit'}
              </CButton>
            </CCol>
          </CRow>
        </form>
      </div>
    </CContainer>
  );
};

export default AddNewUserForm;
