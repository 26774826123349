/** @jsx jsx */
import { CButton, CCallout, CModal } from "@coreui/react"
import { css, jsx } from "@emotion/react"
import ModalClose from "assets/icons/modalClose.svg"
import { useTheme } from "components/common/Context/ThemeContext"
import PropTypes from "prop-types"

const useStyles = (theme) => {
  return {
    message: css`
      font-size: 16px;
    `,
    okayBtn: css`
      background-color: ${theme.serviceRequestButton};
      border-color: ${theme.serviceRequestButton};
      min-width: 100px;
      &:hover {
        background-color: ${theme.serviceRequestButton};
        border-color: ${theme.serviceRequestButton};
      }
    `,
    cancelBtn: css`
      background-color: ${theme.cancelBtnBg};
      border-color: ${theme.serviceRequestButton};
      color: ${theme.serviceRequestButton};
      border: 1px solid;
      min-width: 100px;
      &:hover {
        border-color: ${theme.serviceRequestButton};
        color: ${theme.serviceRequestButton};
      }
    `
  }
}

export default function Confirm({
  show,
  setShow,
  onCancel,
  onOkay,
  message,
  okayBtnText = "",
  className
}) {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <CModal
      show={show}
      onClose={() => setShow(false)}
      size=""
      centered
      className={`random ${className}`}
    >
      <CCallout color="warning" className="m-0 py-3">
        <div className="d-flex justify-content-between">
          <p css={classes.message} className="pr-1">{message}</p>
          <img
            src={ModalClose}
            alt="close-icon"
            className="h-100 cursor-pointer"
            onClick={() => setShow(false)}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <CButton
            css={classes.cancelBtn}
            variant="outline"
            color="primary"
            onClick={() => onCancel && onCancel()}
            className="bg-white mr-3"
          >
            Cancel
          </CButton>
          <CButton
            css={classes.okayBtn}
            color="primary"
            onClick={() => onOkay && onOkay()}
            className="text-white"
          >
            {okayBtnText || "OK"}
          </CButton>
        </div>
      </CCallout>
    </CModal>
  )
}

Confirm.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  onCancel: PropTypes.func,
  onOkay: PropTypes.func,
  message: PropTypes.string,
  okayBtnText: PropTypes.string,
  className:PropTypes.string
}
