import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CInputCheckbox
} from "@coreui/react"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { TiTick, TiTimes } from "react-icons/ti";

function MultiSelectHeader({
  options,
  selectedKeys,
  setSelectedKeys,
  headerText,
  buttonClassName
}) {

  const [internalSelectedKeys, setInternalSelectedKeys] = useState([])

  useEffect(() => {
    setInternalSelectedKeys(selectedKeys)
  }, [selectedKeys])

  const handleChange = (itemKey) => {
    setInternalSelectedKeys(
      internalSelectedKeys.includes(itemKey)
        ? internalSelectedKeys.filter((key) => key !== itemKey)
        : [...internalSelectedKeys, itemKey]
    )
  }

  return (
    <CDropdown>
      <CDropdownToggle
        className={`px-0 table-header-data font-weight-bold ${buttonClassName}`}
        caret
        onClick={() => setInternalSelectedKeys(selectedKeys)}
      >
        {headerText}
      </CDropdownToggle>
      <CDropdownMenu>
        {options.map((item) => (
          <div
            key={item.key}
            onClick={() => handleChange(item.key)}
            className="dropdown-item cursor-pointer pl-4 d-flex align-items-baseline"
          >
            <CInputCheckbox
              checked={internalSelectedKeys.includes(item.key)}
              readOnly
            />
            {item.text}
          </div>
        ))}
        <div className="d-flex">
          <CDropdownItem
            className="justify-content-center"
            onClick={() => setSelectedKeys(internalSelectedKeys)}
          >
            <TiTick size={20} />
          </CDropdownItem>
          <CDropdownItem
            className="justify-content-center hover-red"
            onClick={() => setInternalSelectedKeys(selectedKeys)}
          >
            <TiTimes size={20} />
          </CDropdownItem>
        </div>
      </CDropdownMenu>
    </CDropdown>
  )
}

MultiSelectHeader.defaultProps = {
  options: [],
  selectedkeys: [],
  setSelectedKeys: () => {},
  headerText: "",
  buttonClassName: ""
}

MultiSelectHeader.propTypes = {
  options: PropTypes.array,
  selectedKeys: PropTypes.array,
  setSelectedKeys: PropTypes.func,
  headerText: PropTypes.string,
  buttonClassName: PropTypes.string
}

export default MultiSelectHeader
