import { leftPadString } from "../string"
export default function (date) {
  /**
   * Returns dates in the format YYYY-MM-DD
   * @param {date} date - The date object
   */
  return `${date.getFullYear()}-${leftPadString(
    date.getMonth() + 1,
    2,
    "0"
  )}-${leftPadString(date.getDate(), 2, "0")}`
}
