import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, project) => {
    return `${host}/cp/${project}/delete_uploaded_report`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return json
  },
  returnRawResponse: true
}
export default createFetcher(fetcherConfiguration)
