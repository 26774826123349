import React from "react";
const ExtensionContext = React.createContext(null);

async function loadConfiguration(locale) {
  switch (locale) {
    case "usoc":
      return await import("./usoc");
    case "core":
      return await import("../core");
    default:
      throw new Error("Locale not recognised.");
  }
}

async function loadExtension() {
  const locale = process.env.REACT_APP_LOCALE ?? "core";
  const stage = process.env.REACT_APP_STAGE ?? "demo";

  const { default: extension } = await loadConfiguration(locale);
  const env = extension.environments[stage] ?? extension.environments.demo;
  const extensionConfig = { env };
  const extensionModules = extension.modules;
  const extensionStore = extension.store;

  return {
    config: extensionConfig,
    modules: extensionModules,
    store: extensionStore,
  };
}

const extension = loadExtension();
export default extension;
export { ExtensionContext };
