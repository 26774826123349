import "./UserSelect.scss"

import { CInput, CListGroupItem } from "@coreui/react"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useSWR from "swr"
import { useExtension } from "utils"
import { useDebounce, useUserAuth } from "utils/hooks"

async function fetcher(url, auth) {
  const headers = new Headers()

  headers.append("Authorization", `Bearer ${auth.token}`)
  headers.append("client_id", auth.email)

  const requestOptions = {
    method: "GET",
    headers: headers
  }

  const response = await fetch(url, requestOptions)

  if (!response.ok) {
    const error = new Error("An error has occurred on user lookup")
    error.info = response.json()
    error.status = response.status
    throw error
  }

  return response.json()
}

const UserSelect = ({
  setState,
  limit = "",
  titleFilter = [],
  validationError = ""
}) => {
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const auth = useUserAuth()

  const userItemsRef = useRef(null)
  const [query, setQuery] = useState("")
  const [selection, setSelection] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [showUserList, setShowUserList] = useState(true)
  const [active, setActive] = useState(0)

  const debouncedSearchValue = useDebounce(query, 500)

  function isQueryMinimumLength() {
    return debouncedSearchValue.length > 2
  }

  const { data: users, error } = useSWR(
    isQueryMinimumLength()
      ? `/users/lookup?search_term=${debouncedSearchValue}${
          limit ? "&limit=" + limit : ""
        }${
          titleFilter.length > 0 ? `&title=${titleFilter.join("&title=")}` : ""
        }`
      : null,
    () =>
      fetcher(
        `${host}/users/lookup?search_term=${query}${
          limit ? "&limit=" + limit : ""
        }${
          titleFilter.length > 0 ? `&title=${titleFilter.join("&title=")}` : ""
        }`,
        auth
      )
  )

  useEffect(() => {
    setState(selection)
  }, [selection, setState])

  useEffect(() => {
    if (!selection) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [selection])

  const onSearch = useCallback(
    (e) => {
      if (userItemsRef?.current) {
        userItemsRef.current.scrollTop = 0
      }
      setActive(0)
      setQuery(e.target.value)
      if (selection) {
        setSelection("")
      }
    },
    [selection]
  )

  const handleSelect = (email) => {
    setQuery(email)
    setSelection(email)
  }

  const handleOnFocus = () => {
    setShowUserList(true)
  }

  const handleOnBlur = () => {
    if (users) {
      if (users?.length === 1) {
        handleSelect(users[0].Email)
      }
    }

    setTimeout(() => {
      setShowUserList(false)
      setQuery("")
      setActive(0)
    }, 200)
  }

  const handleIsValid = () => {
    if (isValid) {
      return "is-valid"
    }

    if (!isValid && !query && validationError) {
      return "is-invalid"
    }

    return ""
  }

  const keyDownHandler = (event) => {
    if (event.keyCode === 38) {
      event.preventDefault()
      if (active > 0) {
        setActive(active - 1)
        const selected = userItemsRef?.current?.querySelector(".active-user")
        userItemsRef.current.scroll({
          top:
            selected.offsetTop -
            userItemsRef.current.offsetTop -
            selected.offsetHeight,
          behavior: "smooth"
        })
      }
    } else if (event.keyCode === 40) {
      event.preventDefault()
      if (active < users?.length - 1) {
        setActive(active + 1)
        const selected = userItemsRef?.current?.querySelector(".active-user")
        userItemsRef.current.scroll({
          top: selected.offsetTop - selected.parentNode.offsetTop,
          behavior: "smooth"
        })
      }
    }
    if (event.keyCode === 13) {
      if (users?.hasOwnProperty(active)) {
        const selectedUser = users[active];
        setSelection(selectedUser?.Email ?? "");
      }
    }
  }

  return (
    <>
      <div className="input-loading-container">
        <CInput
          type="text"
          autoComplete="off"
          aria-autocomplete="none"
          name="RequesterEmail"
          placeholder="Enter a first name, last name or email to search for a user"
          onKeyDown={keyDownHandler}
          value={selection || query}
          onChange={onSearch}
          onFocus={() => handleOnFocus()}
          onBlur={handleOnBlur}
          className={` ${
            !users && !error && isQueryMinimumLength() && !selection
              ? "input-is-fetching"
              : ""
          }  ${handleIsValid()}`}
        />
        <div className="invalid-feedback">{validationError}</div>
        {!users && !error && isQueryMinimumLength() && !selection && (
          <div className="icon-loading-container">
            <i className="icon-loader"></i>
          </div>
        )}
      </div>
      <div className="user-select-container">
        {!selection && query && showUserList && users?.length > 0 && !error && (
          <ul className="user-select-group" ref={userItemsRef}>
            {users &&
              users.map((user, i) => (
                <CListGroupItem
                  className={`user-select-item ${
                    active === i ? "active-user" : ""
                  }`}
                  key={user.Email}
                  onClick={() => handleSelect(user.Email)}
                >
                  {user.Email} - {user.FirstName} {user.LastName} ({user.Title})
                </CListGroupItem>
              ))}
          </ul>
        )}
      </div>
    </>
  )
}

UserSelect.propTypes = {
  setState: PropTypes.func,
  limit: PropTypes.number,
  titleFilter: PropTypes.array,
  validationError: PropTypes.string
}

export default UserSelect
