import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host, project, param) => {
    return `${host}/cp/${project}/get_feedback_image?${param}`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
