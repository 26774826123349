import { AuthenticatedTemplate } from "@azure/msal-react"
import * as pages from "pages"
import PropTypes from "prop-types"
import React from "react"
import { Redirect, Route, Switch } from "react-router-dom"
import { AnimatedSwitch } from "react-router-transition"
import {
  fadeInOutBounceMapStyles,
  makeFadeInOutBounce,
  useExtension
} from "utils"

const fadeInFadeOutBounce = makeFadeInOutBounce(100, 22)

const RouteWrapper = ({ children }) => {
  return (
    <AnimatedSwitch
      atEnter={fadeInFadeOutBounce.atEnter}
      atActive={fadeInFadeOutBounce.atActive}
      atLeave={fadeInFadeOutBounce.atLeave}
      mapStyles={fadeInOutBounceMapStyles}
      className="route-wrapper"
    >
      {children}
    </AnimatedSwitch>
  )
}

export default function MsalRoutes() {
  const { modules: extensionModules } = useExtension()
  const { routes: extensionRoutes } = extensionModules
  const { config } = useExtension()
  const [loginBaseURL] = React.useState(config?.env?.oktaConfig?.url)
  if (loginBaseURL === undefined) throw new Error("Cannot retrieve login url.")

  const landingRoute = extensionRoutes.filter((route) => {
    return route.props.path === "/landing"
  })[0]
  const sandboxRequestRoute = extensionRoutes.filter((route) => {
    return route.props.path === "/service/requests/sandbox"
  })[0]
  const cmsEnquiryRoute = extensionRoutes.filter((route) => {
    return route.props.path === "/service/requests/environment/enquiry"
  })[0]
  const cmsFollowUpRoute = extensionRoutes.filter((route) => {
    return route.props.path === "/service/requests/environment/new_cloud_hosting"
  })[0]

  return (
    <>
      <RouteWrapper>
        <AuthenticatedTemplate>
          <Switch>
            {sandboxRequestRoute}
            {landingRoute}
            {cmsEnquiryRoute}
            {cmsFollowUpRoute}
            <Redirect from="*" to="/landing" />
          </Switch>
        </AuthenticatedTemplate>
        <Route component={pages.Page404} />
      </RouteWrapper>
    </>
  )
}

RouteWrapper.propTypes = {
  children: PropTypes.node
}
