import { CToast, CToastBody, CToaster, CToastHeader } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"

import failureIcon from "../../../assets/icons/failure_icon.svg"
import successIcon from "../../../assets/icons/success_icon.svg"

const { createContext, useState } = React
const ToasterContext = createContext(null)

function ToasterContextProvider(props) {
  const [toasts, setToasts] = useState([])

  const enqueueToast = (type = "", title = "", body = "") => {
    if (type === "success") title = title || "Created Successfully!"
    if (type === "failure") title = title || "Something went wrong!"
    const toast = { type, title, body }
    setToasts([...toasts, toast])
  }

  return (
    <ToasterContext.Provider value={enqueueToast}>
      <CToaster position="top-right" className="mt-5 pt-3">
        {toasts.map((toast, index) => (
          <CToast
            key={`toast${index}`}
            show
            autohide={3000}
            className={`toast-container ${toast.type || ""}`}
          >
            <CToastHeader closeButton className="bg-white mt-2 border-bottom-0">
              {toast.type === "success" && (
                <img src={successIcon} alt="success" />
              )}
              {toast.type === "failure" && (
                <img src={failureIcon} alt="failure" />
              )}
              <p className="toast-title ml-3">{toast.title}</p>
            </CToastHeader>
            <CToastBody className="bg-white pt-1">{toast.body}</CToastBody>
          </CToast>
        ))}
      </CToaster>
      {props.children}
    </ToasterContext.Provider>
  )
}
ToasterContextProvider.propTypes = {
  children: PropTypes.node
}
export default ToasterContext
export { ToasterContextProvider }
