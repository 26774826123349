export default function (type) {
  switch (type) {
    case "warning":
      return "text-warning"
    case "success":
      return "text-success"
    case "error":
      return "text-danger"
    default:
      return ""
  }
}
