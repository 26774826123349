import PropTypes from "prop-types"
import React, { useCallback, useEffect } from "react"

// eslint-disable-next-line react/prop-types
const KeyboardDropdown = ({
  handleSelect,
  items,
  dropdownItemRef,
  cursor,
  children
}) => {
  const scrollIntoView = useCallback(
    (position) => {
      dropdownItemRef.current.parentNode.scrollTo({
        top: position,
        behavior: "smooth"
      })
    },
    [dropdownItemRef]
  )
  useEffect(() => {
    if (cursor < 0 || cursor > items.length - 1 || !dropdownItemRef.current) {
      return () => {}
    }
    const listItems = Array.from(dropdownItemRef.current.children)
    if (cursor <= 2) scrollIntoView(0)
    else listItems[cursor] && scrollIntoView(listItems[cursor].offsetTop)
  }, [cursor, items, scrollIntoView, dropdownItemRef])

  return (
    <div className="w-100 h-100" onKeyDown={handleSelect}>
      {children}
    </div>
  )
}
export default KeyboardDropdown

KeyboardDropdown.propTypes = {
  handleSelect: PropTypes.func,
  items: PropTypes.array,
  dropdownItemRef: PropTypes.object,
  cursor: PropTypes.number,
  children: PropTypes.node
}
