export default (text, threshold = 20) => {
  if (!text || text.length < threshold) return text
  const centerIndex = Math.floor(text.length / 2)
  const breakIndex = text.indexOf(" ", centerIndex)
  if (breakIndex < 0) return (text)
  return ([
    text.slice(0, breakIndex),
    text.slice(breakIndex + 1)
  ])
}
