import { CCol, CRow } from "@coreui/react"
import PropTypes from "prop-types"
import React, { Fragment } from "react"

export default function AwaitingRow(props) {
  const { clientInfo, user, setIsDrawerOpen, setDrawerState } = props

  let modalCanOpen = false
  const allValues = user.subType.map((data) => clientInfo[data.key])
  allValues.forEach((element) => {
    if (element !== null && element !== 0) {
      modalCanOpen = true
    }
  })

  const onClickHandler = () => {
    if (modalCanOpen) {
      setIsDrawerOpen(true)
      setDrawerState("Awaiting Incidents")
    }
  }
  return (
    <>
      <CCol
        md="12"
        className={`mt-4 ${
          modalCanOpen ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={onClickHandler}
      >
        <CRow>
          <CCol
            xs="3"
            className="padding-remove d-flex justify-content-end  align-items-center"
          >
            <img
              src={user.icon}
              alt="icon"
              width={25}
              height={25}
              className="pr-2"
            />
          </CCol>
          <CCol
            xs="9"
            className="padding-remove d-flex justify-content-left align-items-center"
          >
            <span className="small-Text cardtext">{user.mainText}</span>
          </CCol>

          {user.subType.map((data) => (
            <Fragment key={data.text}>
              <CCol
                xs="9"
                sm="6"
                md="11"
                lg="10"
                className="padding-remove list-padding d-flex justify-content-left align-items-center"
              >
                <span className="small-Text" style={{ fontWeight: 300 }}>
                  - &nbsp; {data.text}
                </span>
              </CCol>
              <CCol
                xs="3"
                sm="6"
                md="1"
                lg="2"
                className=" medium-Text padding-remove d-flex justify-content-left align-items-center"
                data-testid={data.key}
              >
                {clientInfo[data.key] || 0}
              </CCol>
            </Fragment>
          ))}
        </CRow>
      </CCol>
    </>
  )
}
AwaitingRow.propTypes = {
  clientInfo: PropTypes.object,
  user: PropTypes.object,
  setIsDrawerOpen: PropTypes.func,
  setDrawerState: PropTypes.func
}
