import { createSlice } from "@reduxjs/toolkit"

export const currencySymbols = {
  USD: "$",
  GBP: "£",
  EUR: "€",
  CAD: "C$",
  ZAR: "R"
}
const createCurrencySlice = (
  initialState = {
    selectedCurrencyFXRate: 1,
    selectedCurrencySymbol: "$"
  }
) =>
  createSlice({
    name: "currency",
    initialState,
    reducers: {
      setFXRate(state, action) {
        const rate = action.payload
        state.selectedCurrencyFXRate = rate
        return state
      },
      setSymbol(state, action) {
        const symbol = Object.keys(currencySymbols).some(
          (currency) => currency === action.payload
        )
          ? currencySymbols[action.payload]
          : action.payload + " "
        state.selectedCurrencySymbol = symbol
        return state
      }
    },
    // extraReducers: {}
  })

const currencySlice = createCurrencySlice()
const actions = currencySlice.actions
const reducer = currencySlice.reducer

export {
  actions as currencyActions,
  reducer as currencyReducer,
  createCurrencySlice
}
