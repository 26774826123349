import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, projectID, ticketID) => {
    return `${host}/itsm/${projectID}/${ticketID}/add_attachment`
  },
  checkRequiredArguments: (changeRequestData) => {
    if (changeRequestData === undefined)
      throw new Error("No change request data supplied.")
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
