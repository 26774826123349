export default function * (
  factor = 1,
  initial = 0,
  base = 2,
  max = Number.MAX_VALUE
) {
  let current = initial
  while (true) {
    const ret = factor * Math.pow(base, current)
    yield ret < max ? ret : max
    current++
  }
}
