import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host, user) => {
    return `${host}/users/${user}/projects`
  },
  checkRequiredArguments: (user) => {
    if (user === "undefined") throw Error("No user defined.")
  },
  makeResponseData: (data) => {
    return data?.projects || []
  }
}
export default createFetcher(fetcherConfiguration)
