import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host) => {
    return `${host}/api_key/status`
  },
  checkRequiredArguments: (user) => {
    if (user === "undefined") throw Error("No user defined.")
  },
  makeResponseData: (data) => {
    return data
  }
}
export default createFetcher(fetcherConfiguration)
