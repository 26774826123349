import { leftPadString } from "../string"
import { toStandardDateString } from "."

export default function (date) {
  /**
   * Returns dates in the format YYYY-MM-DD HH:MM:SS
   * @param {date} date - The date object
   */
  return `${toStandardDateString(date)} ${leftPadString(date.getHours(), 2, "0")}:${leftPadString(date.getMinutes(), 2, "0")}:${leftPadString(date.getSeconds(), 2, "0")}`
}
