import "../../pages/Drawer.scss"

import {
  CSidebar,
  CSidebarHeader,
  CSidebarNav
} from "@coreui/react"
import Close from "assets/img/close.png"
import classNames from "classnames"
import { OptionDropdown } from "components/common/dropdown"
import { withErrorBoundary } from "components/common/error"
import { LoadingSpinnerCard } from "components/common/loading"
import React, { useLayoutEffect, useState } from "react"

import DrawerTable from "./DrawerTable"

const options = [
  { text: "Incidents", key: "Incidents" },
  { text: "Service Requests", key: "Service Requests" }
]

export default withErrorBoundary(function ({
  isOpen,
  setSidebarIsOpen = () => { },
  type,
  date,
  activeState,
  selectedAccount,
  setType,
  data,
  setData,
  multiselectOption,
  setDate
}) {
  let tabs
  if (type === "Service Requests" || type === "Incidents") {
    tabs = ["Open", "Resolved", "My Tickets"]
  } else if (type === "Missed") {
    tabs = ["Response", "Resolution"]
  } else {
    tabs = [type]
  }

  useLayoutEffect(() => {
    if (activeState) {
      setActive(tabs.indexOf(activeState))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeState, type, multiselectOption])

  const [active, setActive] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)

  const [maxLimit, setMaxLimit] = useState(5)
  const [minLimit, setMinLimit] = useState(0)

  const onCloseHandler = () => {
    setSidebarIsOpen(false)
    setActive(0)
    setCurrentPage(1)
    setMaxLimit(5)
    setMinLimit(0)
    if (setDate) {
      setDate({})
    }
  }

  function onTabChange (index) {
    setActive(index)
    setCurrentPage(1)
    setMaxLimit(5)
    setMinLimit(0)
  }
  const drawerClass = classNames({ "w-0": !isOpen }, "c-sidebar-light")
  return (
    <>
      <CSidebar minimize={!isOpen} size={"xl"} aside className={drawerClass}>
        <CSidebarHeader className="p-0 m-3 bg-primary">
        </CSidebarHeader>
        <CSidebarNav>
          <div className="drawer-tab">
            <div className="d-flex cursor-pointer">
              {tabs.map((item, index) =>
                (<div className={active === index ? "drawer-tab-btn green-bottom-border" : "drawer-tab-btn"} onClick={() => onTabChange(index)} key={index}>{item}</div>))
              }
            </div>
            {(type === "Incidents" || type === "Service Requests") && (<div className="dd-container">
              <OptionDropdown
                items={options}
                value={type}
                onChange={setType}
                className="w-28"
                buttonClassName="w-100"
                showDownIcon={true}
              />
            </div>)}
            <div className="close-btn cursor-pointer"><img src={Close} alt="Close" onClick={onCloseHandler} className="close-icon" /></div>
          </div>
          <React.Suspense fallback={<LoadingSpinnerCard />}>
            <DrawerTable stateList={tabs} state={tabs[active]} currentPage={currentPage} setCurrentPage={setCurrentPage}
              maxLimit={maxLimit} setMaxLimit={setMaxLimit} minLimit={minLimit} setMinLimit={setMinLimit}
              date={date} selectedAccount={selectedAccount} type={type} data={data} setData={setData}
              multiselectOption={multiselectOption} />
          </React.Suspense>
        </CSidebarNav>
      </CSidebar>
    </>
  )
})
