import { combineReducers } from "redux"

import * as currency from "./currency"
import * as settings from "./settings"
import * as user from "./user"

const rootReducer = combineReducers({
  currency: currency.currencyReducer,
  settings: settings.settingsReducer,
  user: user.userReducer
})

export * from "./user"
export * from "./currency"
export * from "./settings"

export default rootReducer
