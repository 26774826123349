import { createFetcher } from "api/helpers"
const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, query, limit = "", titleFilter = "") => {
    return `${host}/users/lookup?search_term=${query}${
      limit ? "&limit=" + limit : ""
    }${titleFilter.length > 0 ? `&title=${titleFilter.join("&title=")}` : ""}`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
