import { CButton, CDropdown, CDropdownMenu } from "@coreui/react"
import downArrow from "assets/icons/downArrow.svg"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import { accountSelectTooltip } from "constants/StringConstants"
import PropTypes from "prop-types"
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from "react"

import { Tooltip } from "../tooltip"
import KeyboardDropdown from "./KeyboardDropdown"

function AccountsSelectDropdown({ items, selectedKeys, onChange }) {
  const enqueueToast = useContext(ToasterProvider)

  const dropdownRef = useRef(null)
  const dropdownItemRef = useRef(null)
  const [selectedAccount, setSelectedAccount] = useState(selectedKeys)
  const [isDropdownOpen, setIsDropDownOpen] = useState(false)
  const [cursor, setCursor] = useState(0)

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false)
        setCursor(0)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [dropdownRef, setIsDropDownOpen])

  const handleSelect = useCallback(
    (itemVal, e) => {
      e.preventDefault()
      if (itemVal === "All accounts") {
        setSelectedAccount((selectedAccounts) => {
          if (selectedAccounts.length === items.length) {
            return []
          }
          return items.map((item) => item.value)
        })
      } else {
        if (selectedAccount.length === 1 && selectedAccount.includes(itemVal)) {
          enqueueToast("failure", null, "You can't unselect all accounts")
          return
        }
        setSelectedAccount(
          selectedAccount.includes(itemVal)
            ? selectedAccount.filter((item) => item !== itemVal)
            : [...selectedAccount, itemVal]
        )
      }
    },
    [items, enqueueToast, selectedAccount]
  )
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault()
        setCursor((c) => (c < items.length ? c + 1 : c))
      } else if (e.key === "ArrowUp") {
        e.preventDefault()
        setCursor((c) => (c > 0 ? c - 1 : 0))
      } else if (e.key === "Enter") {
        e.preventDefault()
        if (cursor === 0) {
          handleSelect("All accounts", e)
        } else {
          handleSelect(items[cursor - 1].value, e)
        }
      }
    },
    [cursor, handleSelect, items, setCursor]
  )
  const handleChange = () => {
    if (selectedAccount.length === 0) {
      enqueueToast("failure", null, "You can't unselect all accounts")
      return
    }
    onChange(selectedAccount)
  }

  const handleCancel = () => {
    setIsDropDownOpen(false)
    setSelectedAccount(selectedKeys)
  }

  const dropdownItem = (option) => (
    <p
      className="acc-checkbox w-100"
      onClick={(e) => handleSelect(option.value, e)}
    >
      <input
        type="checkbox"
        id={option.label}
        name="account"
        checked={selectedAccount.includes(option.value)}
        disabled={
          (selectedAccount.length === 1 &&
            selectedAccount.includes(option.value)) ||
          (selectedAccount.length >= 4 &&
            !selectedAccount.includes(option.value))
        }
        readOnly
      />
      <label htmlFor={option.text} className="w-100">
        <span className="font-weight-bold account-name">{option.label}</span>
        <span className="account-id">{option.value}</span>
      </label>
    </p>
  )

  const actionButtons = () => (
    <div className="d-flex justify-content-end">
      <CButton className="acc-cancel" onClick={handleCancel}>
        Cancel
      </CButton>
      <CButton className="acc-apply" onClick={handleChange}>
        Apply
      </CButton>
    </div>
  )

  return (
    <>
      <CDropdown
        className="account-dropdown header-dropdown-width"
        innerRef={dropdownRef}
      >
        <KeyboardDropdown
          items={items}
          handleSelect={handleKeyDown}
          cursor={cursor}
          setCursor={setCursor}
          dropdownItemRef={dropdownItemRef}
        >
          <CButton
            className="padding-box header-btn-style project-dropdown-text w-100 d-flex align-items-center"
            onClick={() => {
              // dropdownItemRef.current.focus()
              setIsDropDownOpen(true)
            }}
            // onKeyDown={(e) => handleKeyDown(e)}
            data-testid="acc-select-btn2"
          >
            <span className="text-ellipsis">
              {!selectedKeys.length
                ? "No Accounts Selected"
                : selectedKeys.length === items.length
                ? "All Accounts"
                : `${selectedKeys.length} Account(s) Selected`}
            </span>
            <img
              src={downArrow}
              alt="down arrow"
              className="iconDropdownStyle"
            />
          </CButton>

          <CDropdownMenu
            className={classNames("w-100 py-0 mobile-view-width-account", {
              show: isDropdownOpen
            })}
          >
            <div className="acc-dd-height overflow-auto dropdown-scrollbar">
              <div
                className="width-max-content min-width-100 "
                ref={dropdownItemRef}
              >
                <div
                  className={`dropdown-item py-0 pl-0 ${
                    cursor === 0 ? "focus-outline" : ""
                  }`}
                  tabIndex={-1}
                >
                  <p
                    className="acc-checkbox"
                    onClick={(e) => handleSelect("All accounts", e)}
                  >
                    <input
                      type="checkbox"
                      id="all_acc"
                      name="account"
                      checked={
                        selectedAccount.length === items.length &&
                        selectedAccount.length !== 0 &&
                        selectedKeys.length !== 0
                      }
                      readOnly
                    />
                    <label>
                      <b>Select all Accounts</b>
                    </label>
                  </p>
                </div>
                {items.map((accountObj, index) => (
                  <div
                    className={`dropdown-item py-0 pl-0 ${
                      cursor === index + 1 ? "focus-outline" : ""
                    }`}
                    key={index}
                  >
                    {dropdownItem(accountObj)}
                  </div>
                ))}
              </div>
            </div>

            {actionButtons()}
          </CDropdownMenu>
        </KeyboardDropdown>
      </CDropdown>
      <Tooltip
        className="mx-2 hide-element"
        heading="Select Account"
        text={accountSelectTooltip}
      />
    </>
  )
}

AccountsSelectDropdown.defaultProps = {
  items: [],
  selectedKeys: [],
  onChange: () => {}
}

AccountsSelectDropdown.propTypes = {
  items: PropTypes.array,
  selectedKeys: PropTypes.array,
  onChange: PropTypes.func
}

export default AccountsSelectDropdown
