import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, params) => {
    return `${host}/itsm/get_assignment_groups?${params}`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
