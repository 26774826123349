//  https://stackoverflow.com/questions/22707475/how-to-make-a-promise-from-settimeout
export default function (delay, value = []) {
  let timer = 0
  let _reject = null
  const promise = new Promise((resolve, reject) => {
    _reject = reject
    timer = setTimeout(resolve, delay, value)
  })
  return {
    get promise () {
      return promise
    },
    cancel () {
      if (timer) {
        clearTimeout(timer)
        timer = 0
        _reject()
        _reject = null
      }
    }
  }
}
