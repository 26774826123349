import PropTypes from "prop-types"
import React from "react"

const { createContext, useState } = React
const CurrencyContext = createContext(null)

function CurrencyContextProvider(props) {
  const [currencies, setCurrencies] = useState(["USD"])
  const [selectedCurrency, setSelectedCurrency] = useState("USD")
  const [currentCurrency, setCurrentCurrency] = useState("USD")
  const [selectedCurrencyRate, setSelectedCurrencyRate] = useState({
    loading: false,
    value: 1
  })

  return (
    <CurrencyContext.Provider
      value={{
        currencies,
        setCurrencies,
        selectedCurrency,
        setSelectedCurrency,
        selectedCurrencyRate,
        setSelectedCurrencyRate,
        currentCurrency,
        setCurrentCurrency
      }}
    >
      {props.children}
    </CurrencyContext.Provider>
  )
}
CurrencyContextProvider.propTypes = {
  children: PropTypes.node
}
export default CurrencyContext
export { CurrencyContextProvider }
