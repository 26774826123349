import { getAccountList } from "api/collections"
import AccountProvider from "components/common/Context/AccountContext"
import getProjectBonding from "extensions/usoc/api/itsm/fetchProjectBonding"
import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom";
import { settingsActions, userActions } from "store"
import { useExtension, useUserAuth } from "utils"

export default function ProjectAccountsMonitor () {
  const dispatch = useDispatch()
  const auth = useUserAuth()
  const extension = useExtension()
  const history = useHistory()

  const { setSelectedAccounts,  
    setProjectBonding,
    setProjectBondLoading } = useContext(AccountProvider)

  const projects = useSelector((state) => {
    return state.user.projects
  })

  const selectedProjectId = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const queryParams = new URLSearchParams(useLocation().search);
  const projectIdParam = queryParams.get("project_id");

  useEffect(() => {
    const projectExists = projects.find((eachProject) => eachProject.id === projectIdParam)
    if(projectExists && projectExists?.id !== selectedProjectId) {
      dispatch(settingsActions.setProject(projectExists))
    }
    if(projectIdParam) {
      queryParams.delete("project_id")
      history.replace({
        search: queryParams.toString(),
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectIdParam, projects, selectedProjectId])

  useEffect(() => {
    (async () => {
      const host = extension?.config?.env?.clientApiGateway?.URL
      if (selectedProjectId) {
        setProjectBondLoading(true)
        const accList = (
          await getAccountList({ auth, host }, selectedProjectId)
        ).data
        dispatch(userActions.setAccounts(accList))
        setSelectedAccounts(
          accList ? accList.account_attribute: []
        )
        dispatch(userActions.userAccountsFetching(false))
        const projBondingData = (
          await getProjectBonding({ auth, host }, selectedProjectId)
        ).data
        if (projBondingData) {
          setProjectBonding(projBondingData)
        }
        setProjectBondLoading(false)
      }
    })()
  }, [selectedProjectId])

  return null
}
