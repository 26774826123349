export default function isEmptyChartData (data) {
  /** returns true if the provided chart data is empty
   * @param data - the data that is passed to the chart
   */
  // return true;
  let isEmpty = true
  Array.isArray(data.datasets) &&
    data.datasets.forEach((dataset) => {
      if (isEmpty && Array.isArray(dataset.data) && dataset.data.length > 0) {
        isEmpty = !dataset.data.some((data) => data && !isNaN(data))
      }
    })
  return isEmpty
}
