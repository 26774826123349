import { CCol, CInput, CRow, CTextarea } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import { OptionDropdown } from "components/common/dropdown"
import { FileAttachment } from "components/common/forms"
import { LoadingSpinnerOverlay } from "components/common/loading"
import { ConfirmModal } from "components/common/modal"
import { postReportAnIncident } from "extensions/usoc/api/service"
import React, { useContext, useEffect, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { checkIfFileSupported, useExtension } from "utils"
import { useUserAuth } from "utils/hooks"

import FormActions from "./FormActions"

const urgencyOptions = [
  { text: "High", value: "1", key: "1" },
  { text: "Medium", value: "2", key: "2" },
  { text: "Low", value: "3", key: "3" }
]

export default function ReportAnIncidentForm() {
  const auth = useUserAuth()
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL

  const userName = useSelector((state) => {
    return state.user.email
  })
  const fileInputRef = useRef(null)

  const [requestor, setRequestor] = useState(userName)
  const [projectName, setProjectName] = useState("")
  const [account, setAccount] = useState("")
  const [shortDescription, setShortDescription] = useState("")
  const [longDescription, setLongDescription] = useState("")
  const [urgency, setUrgency] = useState("3")
  const [showModal, setShowModal] = useState(false)
  const [createdIncidentNumber, setCreatedIncidentNumber] = useState("")

  const history = useHistory()

  const [isShortDescriptionTouched, setIsShortDescriptionTouched] =
    useState(false)
  const [isAccountTouched, setIsAccountTouched] = useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const [uploadFile, setUploadFile] = useState([])
  const [isUploadFileTouched, setIsUploadFileTouched] = useState(false)
  const enqueueToast = useContext(ToasterProvider)
  /* end of for attachment */
  const accounts = useSelector((state) => {
    return state.user.accounts?.account_attribute || []
  })

  useEffect(() => {
    if (accounts && accounts[0]?.project_name && !projectName) {
      setProjectName(accounts[0].project_name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const postUploadFile = async (fileDataURL) => {
    const promises = []
    for (const data of fileDataURL.presigned_urls) {
      const options = {
        method: "PUT",
        body: Object.values(uploadFile).find(
          (item) => item.name === data.filename
        ),
        mode: "cors",
        headers: {
          "Content-Type": ""
        }
      }
      promises.push(
        fetch(data.url, options).catch((err) => {
          throw err
        })
      )
    }
    const results = await Promise.all(promises)
    return results
  }

  const handleSubmit = async () => {
    setIsShortDescriptionTouched(true)
    setIsAccountTouched(true)
    setIsUploadFileTouched(true)
    if (
      !shortDescription ||
      !account ||
      !checkIfFileSupported(uploadFile)
    )
      return
    setIsLoading(true)
    const reportAnIncidentBody = {
      short_description: shortDescription,
      incident_description: longDescription,
      urgency,
      account_id: account,
      attachments: uploadFile ? uploadFile?.map((data) => data?.name) : []
    }
    const response = await postReportAnIncident(
      { auth, host, body: JSON.stringify(reportAnIncidentBody) },
      projectID
    )
    if (!response.error && response.data) {
      // let successMsg
      try {
        await postUploadFile(response.data)
        const incidentNumber = response?.data?.incident_number
        setShowModal(true)
        setCreatedIncidentNumber(incidentNumber)
      } catch (err) {
        setIsLoading(false)
        enqueueToast(
          "failure",
          "failed",
          "Failed to upload the File. Please try again later."
        )
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
      enqueueToast("failure", null, "Could not report this incident")
    }

    setShortDescription("")
    setLongDescription("")
    setAccount("")
    setUrgency("3")
    setUploadFile([])
    setIsShortDescriptionTouched(false)
    setIsAccountTouched(false)
    setIsUploadFileTouched(false)
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  const onOk = () => {
    history.push({
      pathname: "/service",
      search: `?incident_number=${createdIncidentNumber}`
    })
  }

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-2">
          <CCol md="12">
            <p className="heading py-2">
              General Infrastructure Report an Incident
            </p>
            <p className="sub-heading">
              Create an incident record to report and request assistance with an
              issue you are having
            </p>
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label font-weight-bold">Requested By *</p>
            <CInput
              type="text"
              name="requestedBy"
              placeholder="Requested By"
              disabled
              value={requestor}
              onChange={(e) => setRequestor(e.target.value)}
            />
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label font-weight-bold">Project Name *</p>
            <CInput
              type="text"
              name="projectName"
              placeholder="Name of Project"
              disabled
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label font-weight-bold">Urgency *</p>
            <OptionDropdown
              items={urgencyOptions}
              value={urgency}
              onChange={setUrgency}
              className="w-100"
              buttonClassName="w-100"
            />
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label font-weight-bold">
              Cloud Service Account *
            </p>
            <OptionDropdown
              items={
                accounts
                  ? accounts.map(
                      ({
                        account_number: accountNumber,
                        account_environment: accountEnvironment,
                        account_name: accountName
                      }) => ({
                        text: [accountEnvironment, accountName, accountNumber]
                          .filter(Boolean)
                          .join(" | "),
                        key: accountNumber
                      })
                    )
                  : []
              }
              value={account}
              onChange={(acc) => {
                setIsAccountTouched(true)
                setAccount(acc)
              }}
              className="w-100"
              buttonClassName={classNames(
                {
                  "is-invalid": isAccountTouched && !account
                },
                "w-100 form-control"
              )}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label font-weight-bold">Short Description *</p>
            <CInput
              type="text"
              name="issue"
              placeholder="Please Describe your Issue"
              className={classNames({
                "is-invalid": isShortDescriptionTouched && !shortDescription
              })}
              value={shortDescription}
              onChange={(e) => {
                setIsShortDescriptionTouched(true)
                setShortDescription(e.target.value)
              }}
            />
            <div className="invalid-feedback">This Field is Required</div>
            <p className="field-label font-weight-bold pt-4">
              Full Description
            </p>
            <CTextarea
              type="text"
              name="issue"
              placeholder="Please Describe your Issue"
              value={longDescription}
              onChange={(e) => setLongDescription(e.target.value)}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <FileAttachment
            uploadFile={uploadFile}
            isUploadFileTouched={isUploadFileTouched}
            setUploadFile={setUploadFile}
            setIsUploadFileTouched={setIsUploadFileTouched}
            fileInputRef={fileInputRef}
          />
        </CRow>
        <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>

      {ReactDOM.createPortal(
        <ConfirmModal
          show={showModal}
          setShow={setShowModal}
          message={`Incident reported Successfully${
            createdIncidentNumber ? ` with number ${createdIncidentNumber}` : ""
          }`}
          onCancel={handleCancel}
          onOkay={onOk}
          okayBtnText="View Incident"
        />,
        document.getElementById("status-modal")
      )}
    </div>
  )
}
