import { CCol, CInput, CRow, CTextarea } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import useNotifications from "components/common/customHooks/useNotifications"
import { DatePickerInput, OptionDropdown } from "components/common/dropdown"
import { LoadingSpinnerOverlay } from "components/common/loading"
import { postChangeRequest } from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import { useExtension } from "utils"
import getDropdownItems from "utils/array/getDropdownItems"
import toStandardDateTimeString from "utils/date/toStandardDateTimeString"
import { useUserAuth } from "utils/hooks"

import { booleanOptions, changeRequestTypes } from "./constants"
import FormActions from "./FormActions"

export default function ChangeRequest({ defaultChangeRequestType }) {
  const auth = useUserAuth()
  const accounts = useSelector((state) => {
    return state.user.accounts?.account_attribute
  })

  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const history = useHistory()

  const userName = useSelector((state) => {
    return state.user.email
  })

  const [accessFor, setAccessFor] = useState(userName)
  const [projectName, setProjectName] = useState(
    accounts ? accounts[0].project_name : "No Projects available"
  )
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState("")
  const [changeRequestType, setChangeRequestType] = useState(
    defaultChangeRequestType || changeRequestTypes[0]
  )
  const [summary, setSummary] = useState("")
  const [businessJustification, setBusinessJustification] = useState("")
  const [isEnvWideRange, setIsEnvWideRange] = useState("No")
  const [description, setDescription] = useState("")

  const [isStartDateTouched, setIsStartDateTouched] = useState()
  const [isSummaryTouched, setIsSummaryTouched] = useState()
  const [isBusinessJustificationTouched, setIsBusinessJustificationTouched] =
    useState()
  const [isDescriptionTouched, setIsDescriptionTouched] = useState()
  const [notifications, setNotifications] = useNotifications()

  const [isLoading, setIsLoading] = useState(false)

  const enqueueToast = useContext(ToasterProvider)

  const handleSubmit = async () => {
    setIsStartDateTouched(true)
    setIsSummaryTouched(true)
    setIsBusinessJustificationTouched(true)
    setIsDescriptionTouched(true)
    if (!startDate || !summary || !businessJustification || !description) {
      return
    }
    setIsLoading(true)
    const changeRequestBody = {
      requested_by: accessFor,
      requested_start_date: toStandardDateTimeString(startDate),
      project: projectName,
      dco_var_change_category: changeRequestType,
      justification: businessJustification,
      description,
      Summary_of_Request: summary,
      is_environmental_change: isEnvWideRange
    }
    if (endDate) {
      changeRequestBody.requested_end_date = toStandardDateTimeString(endDate)
    }
    const response = await postChangeRequest(
      { auth, host, body: JSON.stringify(changeRequestBody) },
      projectID
    )
    if (
      !response.error &&
      response.data &&
      response.data.startsWith("Submitted")
    ) {
      try {
        let responseData = response.data.split("{")[1].split("}")[0]
        responseData = JSON.parse(`{${responseData}}`)
        enqueueToast(
          "success",
          null,
          `Changes requested successfully${
            responseData.number ? ` with number ${responseData.number}` : ""
          }`
        )
        const currentDate = new Date()
        const notification = {
          id: notifications.length + 1,
          from: "Created Successfully!!",
          active: true,
          approved: true,
          ticket_number: responseData.number,
          time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
          description: "Changes requested successfully with number ",
          requestNumber: ["ChangeRequest", responseData.number]
        }
        setNotifications([notification, ...notifications])
      } catch {
        enqueueToast("success", null, "Changes requested successfully")
      }
      setStartDate(new Date())
      setEndDate(new Date())
      setChangeRequestType(defaultChangeRequestType || changeRequestTypes[0])
      setSummary("")
      setBusinessJustification("")
      setIsEnvWideRange("No")
      setDescription("")
      setIsBusinessJustificationTouched(false)
      setIsDescriptionTouched(false)
      setIsSummaryTouched(false)
      history.goBack()
    } else {
      enqueueToast("failure", null, "Could not request this change")
      // enqueueToast("success", null, "Changes requested successfully")
      history.goBack()
    }
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="mx-2 my-4">
          <CCol md="12">
            <p className="heading py-2">Raise Change Request</p>
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label">Requested By *</p>
            <CInput
              type="text"
              name="requestedBy"
              placeholder="Requested By"
              disabled
              value={accessFor}
              onChange={(e) => setAccessFor(e.target.value)}
            />
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label">Requested Start Date *</p>
            <DatePickerInput
              className="w-100"
              buttonClassName={classNames(
                { "is-invalid": isStartDateTouched && !startDate },
                "w-100 form-control"
              )}
              value={startDate}
              onChange={(val) => {
                setIsStartDateTouched(true)
                setStartDate(val)
              }}
              range={false}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label">Name of Project *</p>
            <CInput
              type="text"
              name="projectName"
              placeholder="Name of Project"
              disabled
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
            />
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label">Requested End Date</p>
            <DatePickerInput
              className="w-100"
              buttonClassName="w-100"
              value={endDate}
              onChange={setEndDate}
              range={false}
              minDate={startDate}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label">Change Category</p>
            <OptionDropdown
              items={getDropdownItems(changeRequestTypes)}
              value={changeRequestType}
              onChange={setChangeRequestType}
              className="w-100"
              buttonClassName="w-100"
              showDownIcon
            />
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label">Summary of Request *</p>
            <CInput
              type="text"
              name="summary"
              placeholder="Summary of Request"
              value={summary}
              onChange={(e) => {
                setIsSummaryTouched(true)
                setSummary(e.target.value)
              }}
              className={classNames({
                "is-invalid": isSummaryTouched && !summary
              })}
            />
            <div className="invalid-feedback">This Field is Required</div>
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label">Business Justification for Request *</p>
            <CInput
              type="text"
              name="businessJustification"
              placeholder="Business Justification for Request"
              value={businessJustification}
              onChange={(e) => {
                setIsBusinessJustificationTouched(true)
                setBusinessJustification(e.target.value)
              }}
              className={classNames({
                "is-invalid":
                  isBusinessJustificationTouched && !businessJustification
              })}
            />
            <div className="invalid-feedback">This Field is Required</div>
          </CCol>
          <CCol xs="12" md="6">
            <p className="field-label">Is this Environmental Wide Change?</p>
            <h6 style={{ backgroundColor: "#fcfcf5" }}>
              Only select “Yes” if Environment wide change affects whole CMS
              OpenCloud Platform.
              <br />
              Select “No”, if change affects only the corresponding project.
            </h6>
            <OptionDropdown
              items={booleanOptions}
              value={isEnvWideRange}
              onChange={setIsEnvWideRange}
              className="w-100"
              buttonClassName="w-100"
              showDownIcon={true}
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol xs="12" md="6">
            <p className="field-label">Detailed Description of Request *</p>
            <CTextarea
              lazy="600"
              value={description}
              placeholder="Please Describe your Request"
              onChange={(e) => {
                setIsDescriptionTouched(true)
                setDescription(e.target.value)
              }}
              className={classNames({
                "is-invalid": isDescriptionTouched && !description
              })}
            />
            <div className="invalid-feedback">This Field is Required</div>
          </CCol>
        </CRow>
        <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>
    </div>
  )
}
ChangeRequest.propTypes = {
  defaultChangeRequestType: PropTypes.string
}
