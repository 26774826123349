import React from "react"
import { useSelector } from "react-redux"

export default function () {
  const email = useSelector(
    (state) => (state && state.user && state.user.email) || ""
  )
  const token = useSelector(
    (state) => (state && state.user && state.user.bearerToken) || ""
  )
  const auth = React.useMemo(() => {
    return { email, token }
  }, [token, email])
  return auth
}
