import { addDays, addMonths, startOfMonth } from "date-fns"

export const dateFilters = [
  {
    key: 3,
    label: "3 Days",
    startDate: (todaysDate) => addDays(todaysDate, -3),
    endDate: (todaysDate) => todaysDate
  },
  {
    key: 7,
    label: "7 Days",
    startDate: (todaysDate) => addDays(todaysDate, -7),
    endDate: (todaysDate) => todaysDate
  },
  {
    key: 30,
    label: "Month",
    startDate: (todaysDate) => startOfMonth(addMonths(todaysDate, -1)),
    endDate: (todaysDate) => todaysDate
  },
  {
    key: 90,
    label: "3 Months",
    startDate: (todaysDate) => startOfMonth(addMonths(todaysDate, -3)),
    endDate: (todaysDate) => todaysDate
  },
  {
    key: 180,
    label: "6 Months",
    startDate: (todaysDate) => startOfMonth(addMonths(todaysDate, -6)),
    endDate: (todaysDate) => todaysDate
  }
]

export const incidentOpenStates =
  "New,Assessment,More Info Required,In Progress,With 3rd Party,Paused with Change,Paused with Problem"
export const incidentResolvedStates = "RESOLVED,CLOSED"
export const incidentPriorityKeys = ["P1", "P2", "P3","P4"]

export const PAGE_NO_LIMIT = 5
export const NO_CARDS_PER_PAGE = 10

export const portalThemes = [
  { key: "theme-deloitte", text: "Default" },
  { key: "theme-protanopia", text: "Protanopia" },
  { key: "theme-deuteranopia", text: "Deuteranopia" },
  { key: "theme-tritanopia", text: "Tritanopia" },
  { key: "theme-achromatopsia", text: "Achromatopsia" }
]

// Service Request

export const scopeOfExe = {
  all_vul: "All Vulnerabilities",
  this_vul: "This Vulnerability",
  vul_sub: "This Vulnerability sub type"
}

export const scopeTextMap = (data) => {
  if (data === scopeOfExe.all_vul) return "all_vulnerabilities"
  else if (data === scopeOfExe.this_vul) return "vulnerability"
  else if (data === scopeOfExe.vul_sub) return "vulnerability_sub_type"
}
export const levelOfExe = {
  project: "Project",
  account: "Account",
  instance: "Instance"
}

export const levelTextMap = (data) => {
  if (data === levelOfExe.project) return "project"
  else if (data === levelOfExe.account) return "account"
  else if (data === levelOfExe.instance) return "instance"
  else return ""
}

export const vulnSubTypes = [
  {
    key: "OS Hardening",
    text: "OS Hardening"
  },
  {
    key: "Patches & Updates",
    text: "Patches & Updates"
  },
  {
    key: "Enable Registry Key",
    text: "Enable Registry Key"
  },
  {
    key: "OS Upgrade",
    text: "OS Upgrade"
  },
  {
    key: "Reboot Required",
    text: "Reboot Required"
  },
  {
    key: "Enable TM & Disable Win Defender",
    text: "Enable TM & Disable Win Defender"
  },
  {
    key: "Patches & Updates",
    text: "Patches & Updates"
  },
  {
    key: "App Upgrade",
    text: "App Upgrade"
  },
  {
    key: "Config & Other Changes",
    text: "Config & Other Changes"
  }
]
export const camelCaseOfStatusTypes = (status, forClassName = false) => {
  if (status === "COMPLIANT") return "Compliant"
  if (status === "NON-COMPLIANT") return "Non-Compliant"
  if (status === "EXCEPTED") return "Excepted"
  if (status === "STOPPED") return "Stopped"
  if (status === "SCAN NEVER PERFORMED")
    return forClassName ? "Scan-never-performed" : "Scan Never Performed"
  if (status === "STOPPED AND EXCEPTED")
    return forClassName ? "Stopped-Excepted" : "Stopped & Excepted"
  return status
}
export const statusTypes = [
  "COMPLIANT",
  "NON-COMPLIANT",
  "EXCEPTED",
  "STOPPED",
  "STOPPED AND EXCEPTED",
  "SCAN NEVER PERFORMED"
]
export const toSearchStatusValue = (status) => {
  if (status === "COMPLIANT") return "compliant"
  if (status === "NON-COMPLIANT") return "non-compliant"
  if (status === "EXCEPTED") return "excepted"
  if (status === "STOPPED") return "stopped"
  if (status === "SCAN NEVER PERFORMED") return "Scan never performed"
  if (status === "STOPPED AND EXCEPTED") return "stopped & excepted"
}
export const AdminUsersHeaders = [
  { key: "username", text: "Username" },
  { key: "email_address", text: "Email" },
  { key: "role", text: "Role" },
  { key: "primary_project_id", text: "Primary Project" },
  {
    key: "associated_projects",
    text: "Associated Projects",
    tooltipRequired: true
  },
  { key: "api_key", text: "API Key" }
]
export const AdminRoleHeaders = [
  { key: "name", text: "Role" },
  { key: "iam_policy", text: "IAM Policy" },
  { key: "isAdmin", text: "/admin" },
  { key: "isUser", text: "/admin/users" },
  { key: "isRole", text: "/admin/roles" },
  { key: "isMaintenance", text: "/admin/maintenance" },
  { key: "isAnalytic", text: "/analytics" },
  { key: "isCloudFinancial", text: "/cloud-financials" },
  { key: "isTrendReport", text: "/cloud-financials/trend-report" },
  { key: "isComputeSummary", text: "/cloud-financials/compute-summary" },
  { key: "isStorageSummary", text: "/cloud-financials/storage-summary" },
  { key: "isServerlessSummary", text: "/cloud-financials/serverless-summary" },
  { key: "isCloudSpend", text: "/cloud-financials/cloud-spend" },
  { key: "isMasterProject", text: "/cloud-financials/master-project" },
  { key: "isServices", text: "/services" },
  { key: "isMonitoring", text: "/inframonitoring" },
  { key: "isSecurity", text: "/security" },
  { key: "isUFSTAccountSecurity", text: "/security/ufst-account-security" },
  { key: "isKeyContact", text: "/keycontact" },
  { key: "isCMSUser", text: "/cms-users" },
  { key: "isPatchCompliance", text: "/cms-users/patch-compliance" },
  { key: "isVulnerabilityTrend", text: "/cms-users/vulnerabilities" },
  { key: "isVMOverview", text: "/cms-users/vm-overview" },
  { key: "isNotificationHub", text: "/cms-users/notification-hub" },
  { key: "isCostInsight", text: "/cms-users/costinsights" },
  { key: "isReport", text: "/report" },
  { key: "isDocumentation", text: "/documentation" },
  { key: "isQuicklink", text: "/quicklink" },
  { key: "isNotificationHubUK", text: "/cms-users/notification-hubuk" },
  { key: "isVmAgentManagement", text: "/cms-users/vm-agent-management" },
  { key: "isCloudAccountManagement", text: "/cms-users/cloud-account-management" },
  { key: "isGcssReport", text: "/cms-users/gcss-report" },
  { key: "isUserAccessReport", text: "/cms-users/user-access-report" },
  { key: "isAwsCISReport", text: "/cms-users/aws-cis-report" },
  { key: "isCloudInventory", text: "/cms-users/cloud-inventory" },
  
]
export const severityTypes = ["Critical", "High", "Medium", "Low"]
