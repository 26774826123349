import {
  CButton,
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CInput,
  CInputGroup,
  CInputGroupText} from "@coreui/react"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FaSearch, FaTimes } from "react-icons/fa"

function TagSearch({
  keyValuePairs,
  selectedTags,
  setSelectedTags,
  setTagSearchText = () => {},
  keyToDisplayTextMap = {},
  isDropdownOpen: externalIsDropdownOpen,
  setIsDropdownOpen: setExternalIsDropdownOpen,
  selectedKey: externalSelectedKey,
  setSelectedKey: setExternalSelectedKey
}) {
  const dropdownRef = useRef(null);

  const [internalIsDropdownOpen, setInternalIsDropDownOpen] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [internalSelectedKey, setInternalSelectedKey] = useState()

  const isDropdownOpen = useMemo(
    () => externalIsDropdownOpen ?? internalIsDropdownOpen,
    [externalIsDropdownOpen, internalIsDropdownOpen]
  )

  const setIsDropDownOpen = useCallback(
    (vals) => setExternalIsDropdownOpen ? setExternalIsDropdownOpen(vals) : setInternalIsDropDownOpen(vals),
    [setExternalIsDropdownOpen]
  )

  const selectedKeyForSearch = useMemo(
    () => externalSelectedKey ?? internalSelectedKey,
    [externalSelectedKey, internalSelectedKey]
  )

  const setSelectedKeyForSearch = useCallback(
    (key) => setExternalSelectedKey ? setExternalSelectedKey(key) : setInternalSelectedKey(key),
    [setExternalSelectedKey]
  )

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropDownOpen(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
        document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef, setIsDropDownOpen]);

  const keys = useMemo(() => {
    return ([...new Set(keyValuePairs.map((eachTag) => eachTag.key))].filter((eachKey) => 
      (keyToDisplayTextMap[eachKey] || eachKey).toLowerCase().includes(searchText.toLowerCase())
    ))
  }, [keyToDisplayTextMap, keyValuePairs, searchText])

  const valuesOfSelectedKey = useMemo(() => {
    return (selectedKeyForSearch
      ? keyValuePairs.filter((eachTag) =>
        eachTag.key === selectedKeyForSearch
      ).filter((eachTag) => 
        (eachTag.count !== 0)
        && (`${eachTag.value}`.toLowerCase().includes(searchText.toLowerCase()))
      ).sort((aTag, bTag) => {
        if (aTag.count && bTag.count) {
          if (aTag.count > bTag.count) return -1;
        	if (aTag.count < bTag.count) return 1;
        }
        if (aTag.value > bTag.value) return 1;
        if (aTag.value < bTag.value) return -1;
        return 0;
      })
      : []
    )
  }, [keyValuePairs, searchText, selectedKeyForSearch])

  const handleSearchKeyDown = (event) => {
    if (selectedKeyForSearch && event.target.value === "" && event.keyCode === 8) {
      setSelectedKeyForSearch(null)
    }
  }

  const handleTagSelect = (key, value) => {
    const selectedTagsCopy = { ...selectedTags }
    selectedTagsCopy[key] = [...(selectedTagsCopy[key] || []), value]
    setSelectedTags(selectedTagsCopy)
    setSelectedKeyForSearch(null)
    setSearchText("")
    setTagSearchText("")
    setIsDropDownOpen(false)
  }

  const handleRemoveTag = (key, value) => {
    const selectedTagsCopy = { ...selectedTags }
    selectedTagsCopy[key] = selectedTagsCopy[key].filter((eachValue) => eachValue !== value)
    if(selectedTagsCopy[key].length === 0) delete selectedTagsCopy[key]
    setSelectedTags(selectedTagsCopy)
  }

  return (
    <>
      <CDropdown innerRef={dropdownRef}>
        <CInputGroup>
          <CInputGroupText className="bg-white border-right-0 rounded-right-0">
            <FaSearch size="20px" />
          </CInputGroupText>
          {selectedKeyForSearch && (
            <CInputGroupText
              className="pr-0 bg-white border-left-0 border-right-0 rounded-0 font-weight-bold"
            >
              {`${keyToDisplayTextMap[selectedKeyForSearch] || selectedKeyForSearch}: `}
            </CInputGroupText>
          )}
          <CInput
            className="border border-left-0 border-right-0 height-auto"
            value={searchText}
            onChange={(e) => {
              if(selectedKeyForSearch){
               setTagSearchText("")
              }
              else setTagSearchText(e.target.value)
              setSearchText(e.target.value)
            }}
            onClick={() => setIsDropDownOpen(true)}
            onKeyDown={handleSearchKeyDown}
          />
          <CButton
            onClick={() => {
              setSearchText("")
              setTagSearchText("")
              setSelectedKeyForSearch(null)
            }}
            className="bg-white border border-left-0 rounded-left-0 text-dark"
          >
            <FaTimes size="20px" />
          </CButton>
        </CInputGroup>
        <CDropdownMenu className={classNames("w-100", { "show": isDropdownOpen })}>
          <CDropdownHeader className="font-weight-bold">
            {selectedKeyForSearch ? (keyToDisplayTextMap[selectedKeyForSearch] || selectedKeyForSearch) : "Filters"}
          </CDropdownHeader>
          {selectedKeyForSearch
            ? valuesOfSelectedKey.map((eachValueOfSelectedKey) => (
              <CDropdownItem
                key={`tag-val-${eachValueOfSelectedKey.value}`}
                onClick={() => handleTagSelect(selectedKeyForSearch, eachValueOfSelectedKey.value)}
                disabled={selectedTags[selectedKeyForSearch]?.find((eachValue) => eachValue === eachValueOfSelectedKey.value)}
              >
                <span className="font-weight-bold">
                  {`${keyToDisplayTextMap[selectedKeyForSearch] || selectedKeyForSearch}:`}
                </span>
                &nbsp;
                {`${eachValueOfSelectedKey.value}${eachValueOfSelectedKey.count !== undefined ? ` (${eachValueOfSelectedKey.count})` : ""}`}

              </CDropdownItem>
            ))
            : keys.map((eachKey) => (
              <CDropdownItem
                key={`tag-key-${eachKey}`}
                onClick={() => {
                  setSelectedKeyForSearch(eachKey)
                  setSearchText("")
                  setTagSearchText("")
                }}
              >
                {keyToDisplayTextMap[eachKey] || eachKey}
              </CDropdownItem>
            ))
          }
        </CDropdownMenu>
      </CDropdown>
      <div className="chip-container my-2">
        {Object.entries(selectedTags).map(([eachKey, values]) => 
          values.map((eachValue) => (
            <div key={`${eachKey}: ${eachValue}`} className="chip-style d-flex align-items-center">
              <p><span className="font-weight-bold">{`${keyToDisplayTextMap[eachKey] || eachKey}: `}</span>{eachValue}</p>
              <FaTimes size="15px" className="ml-1 cursor-pointer" onClick={() => handleRemoveTag(eachKey, eachValue)} />
            </div>
          )
        ))}
        {Object.keys(selectedTags).length > 0 && (
          <div
            className="chip-style d-flex align-items-center cursor-pointer"
            onClick={() => setSelectedTags({})}
          >
            <FaTimes size="15px" className="mr-1" />
            <p>Clear</p>
          </div>
        )}
      </div>
    </>
  )
}

TagSearch.defaultProps = {
  keyValuePairs: [],
  selectedTags: {},
  setSelectedTags: () => { },
  keyToDisplayTextMap: {}
}

TagSearch.propTypes = {
  keyValuePairs: PropTypes.array.isRequired,
  selectedTags: PropTypes.object.isRequired,
  setSelectedTags: PropTypes.func.isRequired,
  setTagSearchText:PropTypes.func,
  keyToDisplayTextMap: PropTypes.object,
  isDropdownOpen: PropTypes.bool,
  setIsDropdownOpen: PropTypes.func,
  selectedKey: PropTypes.string,
  setSelectedKey: PropTypes.func
}

export default TagSearch
