import { CCard, CCardBody } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"
import "./ServiceCard.scss"

function ServiceCard({
  value,
  setState,
  title,
  imageIcon,
  active
}) {
  return (
    <CCard
      aria-disabled={!active}
      disabled={!active}
      onClick={() => {
        if (active) {
          setState(value)
        }
      }}
      className="mx-5 my-4 px-5 d-flex flex-wrap cursor-pointer service-card"
    >
      <CCardBody className="d-flex align-items-center justify-content-space-between">
        <div className="flex-container">
          <div className="card-icon">
            {imageIcon}
          </div>
          <div className="card-text">
            <span>{title}</span>
          </div>
        </div>

      </CCardBody>
    </CCard>
  )
}

ServiceCard.propTypes = {
  value: PropTypes.string,
  setState: PropTypes.func,
  title: PropTypes.string,
  imageIcon: PropTypes.any,
  active: PropTypes.bool
}

export default ServiceCard
