import "react-calendar/dist/Calendar.css"
import "./dateRangePicker.scss"

import { isAfter, isBefore } from "date-fns"
import PropTypes from "prop-types"
import React from "react"
import Calendar from "react-calendar"

export default function DateRangePicker({
  value,
  onChange,
  dateRange = true,
  minDate,
  maxDate
}) {
  return (
    <Calendar
      onChange={onChange}
      value={value}
      selectRange={dateRange}
      showDoubleView={dateRange}
      className="date-range-filter"
      tileDisabled={({ date }) =>
        (minDate && isBefore(date, minDate.setHours(0, 0, 0, 0))) ||
        (maxDate && isAfter(date, maxDate.setHours(0, 0, 0, 0)))
      }
    />
  )
}
DateRangePicker.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  dateRange: PropTypes.bool,
  minDate: PropTypes.string,
  maxDate: PropTypes.string
}
