import { createFetcher } from "api/helpers"
const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, project, params) => {
    return `${host}/itsm/${project}/get_service_request_details?${params}`
  },
  checkRequiredArguments: (params) => {
    if (params === undefined) throw new Error("No ticket data supplied.")
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
