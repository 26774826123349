const WBS_REGEX_PATTERNS = [
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}$/,
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}-[0-9]{2}$/,
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{2}$/,
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
    /^[A-Z]{4}[0-9]{8}$/,
    /^[0-9]{9}$/,
    /^[A-Z]{3}[0-9]{5}-[0-9]{2}$/,
    /^[A-Z]{3}[0-9]{5}-[0-9]{2}-[0-9]{2}$/,
    /^[A-Z]{3}[0-9]{5}-[0-9]{2}-[0-9]{2}-[0-9]{2}$/,
    /^[A-Z]{3}[0-9]{5}-[0-9]{2}-[0-9]{2}-[0-9]{2}-[0-9]{4}$/,
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}-[0-9]{2}-[0-9]{1}$/,
    /^[A-Z]{4}[0-9]{4}-[0-9]{2}-[0-9A-Z]{2}-[0-9]{2}-[0-9]{4}$/
]

const handleWbsCodePatternCheck = (value) => {
    return WBS_REGEX_PATTERNS.some((rx) => rx.test(value))
}

const wbsCodeValidation = (value, helpers) => {
    if (!value) {
        throw new Error("any.required")
    }
    // validate value adheres to a pattern
    const isValid = handleWbsCodePatternCheck(value)

    if (!isValid) {
        return helpers.error("string.pattern")
    }

    // verify the value is an existing and valid wbs code

    // TODO: Logic verifying code with swift API

    return value
}

export default wbsCodeValidation;