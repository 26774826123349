import {
  CDropdown,
  CDropdownHeader,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CInput,
  CInputGroup,
  CInputGroupText
} from "@coreui/react"
import downArrow from "assets/icons/downArrow.svg"
import searchIcon from "assets/icons/search.svg"
import { debounce } from "lodash"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"

import { Tooltip } from "../tooltip"
import KeyboardDropdown from "./KeyboardDropdown"

function ProjectSelect({ items, value, onChange, className, buttonClassName }) {
  const [searchText, setSearchText] = useState("")
  const [displayItems, setDisplayItems] = useState(items)
  const dropdownItemRef = useRef(null)
  const [cursor, setCursor] = useState(0)
  
  useEffect(() => {
    const debouncedFilterItems = debounce((projectItems, text) => {
      const filteredProjects = projectItems.filter(
        (projectItem) =>
          projectItem.text?.toLowerCase().includes(text.toLowerCase()) ||
          projectItem.key?.toLowerCase().includes(text.toLowerCase())
      );
      setDisplayItems(filteredProjects);
    }, 300);

    debouncedFilterItems(items, searchText)

    return () => {
      debouncedFilterItems.cancel(); // Cancel the debounced call if the component is unmounted
    };
  }, [items, searchText])

  const selectedItem = items.find((item) => item.key === value)
  const handleSelect = useCallback(
    (item, e) => {
      e.preventDefault()
      if (value !== item.key) {
        localStorage.setItem("insight-portal-selected-project", item.key)
        onChange(item.key)
      }
    },
    [onChange, value]
  )
  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "ArrowDown") {
        e.preventDefault()
        setCursor((c) => (c < displayItems.length - 1 ? c + 1 : c))
      } else if (e.key === "ArrowUp") {
        e.preventDefault()
        setCursor((c) => (c > 0 ? c - 1 : 0))
      } else if (e.key === "Enter") {
        e.preventDefault()
        handleSelect(displayItems[cursor], e)
      }
    },
    [cursor, handleSelect, displayItems, setCursor]
  )

  return (
    <CDropdown className={className}>
      <KeyboardDropdown
        items={displayItems}
        handleSelect={handleKeyDown}
        cursor={cursor}
        setCursor={setCursor}
        dropdownItemRef={dropdownItemRef}
      >
        <>
          <Tooltip
            text={
              selectedItem
                ? `${selectedItem.text} - ${selectedItem.key}`
                : "No Project Selected"
            }
            className="w-100"
            containerClass="bg-white mw-100"
            trigger="hover"
            zIndex={1000}
          >
            <CDropdownToggle
              className={`bg-white text-dark d-flex align-items-center justify-content-between textBox-style ${buttonClassName} ${
                displayItems.length <= 1 ? "cursor-not-allowed" : ""
              }`}
              caret
              onClick={() => setCursor(0)}
              disabled={displayItems.length <= 1}
            >
              <span className="text-ellipsis">
                {selectedItem ? selectedItem.text : "No Project Selected"}
              </span>
              {displayItems.length > 1 && (
                <img
                  src={downArrow}
                  alt="down arrow"
                  className="iconDropdownStyle"
                />
              )}
            </CDropdownToggle>
          </Tooltip>
          <CDropdownMenu className="pt-0 mobile-view-width-project dropdown-scrollbar">
            <CDropdownHeader className="bg-white">
              <CInputGroup>
                <CInput
                  type="text"
                  name="project-search"
                  className="border-bottom border-top-0 border-left-0 border-right-0 rounded-0 px-0"
                  value={searchText}
                  onChange={(e) => setSearchText(e.target.value)}
                  data-testid="project-search-input"
                />
                <CInputGroupText className="border-bottom border-top-0 border-left-0 border-right-0 rounded-0 p-0 bg-white">
                  <img src={searchIcon} alt="search icon" />
                </CInputGroupText>
              </CInputGroup>
            </CDropdownHeader>

            <div
              className="overflow-auto custom-scrollbar"
              style={{ maxHeight: "16rem" }}
            >
              <div ref={dropdownItemRef}>
                {displayItems.length ? (
                  displayItems.map((item, index) => (
                    <CDropdownItem
                      key={`${item.key}-${index}`}
                      onClick={(e) => handleSelect(item, e)}
                      className={`${cursor === index ? "focus-outline" : ""}`}
                    >
                      {item.text}
                    </CDropdownItem>
                  ))
                ) : (
                  <CDropdownItem disabled>No Projects Found</CDropdownItem>
                )}
              </div>
            </div>
          </CDropdownMenu>
        </>
      </KeyboardDropdown>
    </CDropdown>
  )
}

ProjectSelect.defaultProps = {
  items: [],
  onChange: () => {},
  className: "",
  buttonClassName: ""
}

ProjectSelect.propTypes = {
  items: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string
}

export default ProjectSelect
