import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react"
import downArrow from "assets/icons/downArrow.svg"
import PropTypes from "prop-types"
import React from "react"

function OptionDropdown({
  items,
  value,
  onChange,
  defaultText,
  className,
  buttonClassName,
  emptyText,
  showDownIcon = true,
  iconClass,
  showToggleText = true,
  disabled = false
}) {
  const selectedItem = items.filter((item) => item.key === value)[0]
  return (
    <CDropdown className={className}>
      <CDropdownToggle
        className={`bg-white text-dark d-flex align-items-center justify-content-between textBox-style ${buttonClassName}`}
        caret
        disabled={disabled}
      >
        <p className="text-ellipsis">
          {showToggleText &&
            (defaultText || (selectedItem ? selectedItem.text : emptyText))}
        </p>
        {showDownIcon && (
          <img
            src={downArrow}
            alt="down arrow"
            className={iconClass || "iconDropdownStyle"}
          />
        )}
      </CDropdownToggle>
      <div className="invalid-feedback">This field is required</div>
      <CDropdownMenu>
        {items.map((item, index) => (
          <CDropdownItem
            key={`${item.key}-${index}`}
            data-testid={`${item.key}-${index}`}
            disabled={item.disabled}
            onClick={() => onChange(item.key)}
          >
            {item.text}
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
}

OptionDropdown.defaultProps = {
  items: [],
  onChange: () => {},
  className: "",
  buttonClassName: "",
  emptyText: "No Items Selected"
}

OptionDropdown.propTypes = {
  items: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  emptyText: PropTypes.string,
  defaultText: PropTypes.string,
  showDownIcon: PropTypes.bool,
  iconClass: PropTypes.string,
  showToggleText: PropTypes.bool,
  disabled: PropTypes.bool
}

export default OptionDropdown
