export default function (
  n,
  hue = 0,
  saturation = 100,
  startLightness = 0,
  endLightness = 100
) {
  const colors = []

  for (let i = 0; i < n; i++) {
    colors.push(
      `hsl(${hue},${saturation}%,${startLightness + (i * endLightness) / n}%)`
    )
  }
  return colors
}
