import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import { DatePickerInput, TimePickerInput } from "."

function DateTimePickerInput({ className, onChange, value }) {
  return (
    <div className={`${className} d-flex height-auto form-control p-0`}>
      <DatePickerInput
        value={value}
        onChange={(date) => {
          onChange(new Date(`${date.toDateString()} ${value.toTimeString()}`))
        }}
        range={false}
        buttonClassName="border-0"
      />
      <TimePickerInput
        value={format(value, "HH:mm")}
        onChange={(time) => {
          onChange(new Date(`${value.toDateString()} ${time}`))
        }}
        buttonClassName="border-0"
      />
    </div>
  )
}

DateTimePickerInput.defaultProps = {
  className: "",
  buttonClassName: "",
  onChange: () => {},
  emptyText: "No date Selected"
}

DateTimePickerInput.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  emptyText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default DateTimePickerInput
