import { Provider } from "react-redux"
import store from "store"

import createWrapper from "./createWrapper"

export default createWrapper(Provider, { store })

export function createWithStore (_store = store) {
  return createWrapper(Provider, { store: _store })
}
