import React from "react"

import { ErrorBoundary } from "."

export default function (WrappedComponent, fallback = <></>) {
  return function WrapperComponent(props) {
    return (
      <ErrorBoundary fallback={fallback}>
        <WrappedComponent {...props} />
      </ErrorBoundary>
    )
  }
}
