import { CButton, CRow, CSpinner } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"
import { switchMessageClass } from "utils"

function SubmitButtonWithStatus({ formMessage, submitForm, isSubmitting }) {
  const formMessageType = switchMessageClass(formMessage.type)
  const formMessageClasses = formMessage.classes || []
  formMessageClasses.push(formMessageType)
  return (
    <CRow className="w-100 d-flex flex-column justify-content-center align-items-center p-3">
      <p className={formMessageClasses.join(" ")}>{formMessage.message}</p>
      {isSubmitting ? (
        <CSpinner className="m-3" />
      ) : (
        <CButton color="secondary" onClick={submitForm}>
          Submit
        </CButton>
      )}
    </CRow>
  )
}

export default SubmitButtonWithStatus
SubmitButtonWithStatus.propTypes = {
  formMessage: PropTypes.string,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool
}
