import {
  CCol,
  CInput,
  CInputCheckbox,
  CInputRadio,
  CModal,
  CModalBody,
  CRow
} from "@coreui/react"
import Close from "assets/icons/modalClose.svg"
import ToasterProvider from "components/common/Context/ToasterContext"
import { DatePickerInput, OptionDropdown } from "components/common/dropdown"
import { ErrorBoundary, ErrorCard } from "components/common/error"
import { FileAttachment } from "components/common/forms"
import { LoadingSpinnerOverlay } from "components/common/loading"
import { ConfirmModal } from "components/common/modal"
import {
  NoDataAndWentWrongHandler,
  WentWrongCard
} from "components/common/noData"
import {
  levelOfExe,
  levelTextMap,
  scopeOfExe,
  scopeTextMap,
  vulnSubTypes
} from "constants/Constants"
import { FormActions } from "core/modules/Service/components/ServiceRequest"
import { postVulnerabilityExemption } from "extensions/usoc/api/itsm"
import { getVulnerabilityDetails } from "extensions/usoc/api/security"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import ReactDOM from "react-dom"
import { createPortal } from 'react-dom';
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useSWR from "swr"
import { checkIfFileSupported, toStandardDateString, useExtension } from "utils"
import { useUserAuth } from "utils/hooks"


async function fetchVulnerabilityDetails(auth, extension, projectID, param) {
  const host = extension?.config?.env?.clientApiGateway?.URL
  if (host === undefined) throw new Error("No api hostname specified.")
  const response = await getVulnerabilityDetails(
    { auth, host },
    projectID,
    param
  )
  return response
}
export default function VulnerabilityExemptionRequest({
  show = false,
  setShow = () => {},
  selectedVulnerability = null,
  selectedInstance = null,
  selectedAccount = null
}) {
  const history = useHistory()

  const [vulnerability, setVulnerability] = useState(selectedVulnerability)
  const [instance, setInstance] = useState(selectedInstance)
  const [account, setAccount] = useState(selectedAccount)
  const [vulnerabilitySubType, setVulnerabilitySubType] = useState("")
  const [exemptionReason, setExemptionReason] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [instanceItems, setInstanceItems] = useState([])
  const [vulnerabilityItems, setVulnerabilityItems] = useState([])
  const [isVulnerabilityTouched, setIsVulnerabilityTouched] = useState(false)
  const [isVulnerabilitySubTypeTouched, setIsVulnerabilitySubTypeTouched] =
    useState(false)
  const [isInstanceTouched, setIsInstanceTouched] = useState(false)
  const [isAccountTouched, setIsAccountTouched] = useState(false)
  const [uploadFile, setUploadFile] = useState([])
  const [isExemptionReasonTouched, setIsExemptionReasonTouched] =
    useState(false)
  const [isUploadFileTouched, setIsUploadFileTouched] = useState(false)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  const [isCheckboxTouched, setIsCheckboxTouched] = useState(false)

  const [exceptionType, setExceptionType] = useState("Permanent Exception")
  const [isStartDateTouched, setIsStartDateTouched] = useState(false)
  const [isEndDateTouched, setIsEndDateTouched] = useState(false)
  const [scopeOfExemption, setScopeOfExemption] = useState(scopeOfExe.this_vul)
  const [levelOfExemption, setLevelOfExemption] = useState(levelOfExe.instance)
  const [showRedirectionModal, setShowRedirectionModal] = useState(false)
  const [createdReqNumber, setCreatedReqNumber] = useState(false)
  // const fileInputRef = useRef(null)

  const [isLoading, setIsLoading] = useState(false)
  const fileInputRef = useRef(null)
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })
  const auth = useUserAuth()
  const enqueueToast = useContext(ToasterProvider)
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL

  useEffect(() => {
    setVulnerability(selectedVulnerability)
  }, [selectedVulnerability])

  useEffect(() => {
    setInstance(selectedInstance)
  }, [selectedInstance])

  useEffect(() => {
    setAccount(selectedAccount)
  }, [selectedAccount])

  const statusParams = ""
  const { data: vulnerabilityDetailsData } = useSWR(
    () =>
      projectID
        ? `/projects/${projectID}/get_vulnerabilities?${statusParams}`
        : null,
    () => fetchVulnerabilityDetails(auth, extension, projectID, statusParams)
  )

  let accountItems = vulnerabilityDetailsData?.data?.map(
    (vulnerabilityDetail) => {
      const accountText =
        vulnerabilityDetail.account_id + " - " + vulnerabilityDetail.environment
      return { key: vulnerabilityDetail.account_id, text: accountText }
    }
  )
  accountItems = [
    ...new Map(
      accountItems?.map((item) => {
        return [item.text, item]
      })
    ).values()
  ]
  const isAccountVisible = useMemo(() => {
    return (
      levelOfExemption === levelOfExe.account ||
      levelOfExemption === levelOfExe.instance
    )
  }, [levelOfExemption])
  const isInstanceVisible = useMemo(() => {
    return levelOfExemption === levelOfExe.instance
  }, [levelOfExemption])

  const isVulVisible = useMemo(() => {
    return scopeOfExemption === scopeOfExe.this_vul
  }, [scopeOfExemption])
  const isVulSubVisible = useMemo(() => {
    return scopeOfExemption === scopeOfExe.vul_sub
  }, [scopeOfExemption])

  const postUploadFile = async (fileDataURL) => {
    const promises = []
    for (const data of fileDataURL.presigned_urls) {
      const options = {
        method: "PUT",
        body: uploadFile.find((item) => item.name === data.filename),
        mode: "cors",
        headers: {
          "Content-Type": ""
        }
      }
      promises.push(
        fetch(data.url, options).catch((err) => {
          throw err
        })
      )
    }
    const results = await Promise.all(promises)
    return results
  }

  const postVulnerabilityAndFile = async () => {
    let allAccounts = []
    if (!isAccountVisible && !isInstanceVisible) {
      allAccounts =
        [
          ...new Set(
            vulnerabilityDetailsData?.data?.map(
              (vulnerability) => vulnerability.account_id
            )
          )
        ] || []
    }
    const vulnerabilityBody = {
      vulnerability: isVulVisible ? vulnerability : null,
      vulnerability_sub_type: isVulSubVisible ? vulnerabilitySubType : null,
      account_id: isAccountVisible ? account : allAccounts.join(",") || null,
      instance_id: isInstanceVisible ? instance : null,
      exemption_scope: scopeTextMap(scopeOfExemption),
      exemption_level: levelTextMap(levelOfExemption),
      exemption_reason: exemptionReason,
      permanent_exemption: exceptionType === "Permanent Exception",
      temporary_exemption: exceptionType === "Temporary Exception",
      start_date:
        exceptionType === "Temporary Exception"
          ? toStandardDateString(startDate)
          : null,
      end_date:
        exceptionType === "Temporary Exception"
          ? toStandardDateString(endDate)
          : null,
      attachments: uploadFile ? uploadFile?.map((data) => data?.name) : [],
      user_confirmation_check: isCheckboxChecked
    }
    const response = await postVulnerabilityExemption(
      { auth, host, body: JSON.stringify(vulnerabilityBody) },
      projectID
    )
    if (response.error || !response.data) {
      setIsLoading(false)
      enqueueToast(
        "failure",
        null,
        "Could not submit the Vulnerability Exception Request"
      )
      return
    }
    setCreatedReqNumber(response.data.request_number)
    try {
      await postUploadFile(response.data)
    } catch (err) {
      setIsLoading(false)
    }
    setIsLoading(false)
    setShow(false)
    setShowRedirectionModal(true)
    reset()
  }
  const handleSubmit = () => {
    // submit logic goes here, yet to be written
    setIsVulnerabilityTouched(true)
    setIsVulnerabilitySubTypeTouched(true)
    setIsStartDateTouched(true)
    setIsEndDateTouched(true)
    setIsInstanceTouched(true)
    setIsAccountTouched(true)
    setIsUploadFileTouched(true)
    setIsCheckboxTouched(true)
    setIsExemptionReasonTouched(true)
    if (
      (isAccountVisible ? !account : false) ||
      (isInstanceVisible ? !instance : false) ||
      (isVulVisible ? !vulnerability : false) ||
      (isVulSubVisible ? !vulnerabilitySubType : false) ||
      !exemptionReason ||
      (exceptionType === "Temporary Exception"
        ? !startDate || !endDate
        : false) ||
      uploadFile.length === 0 ||
      !checkIfFileSupported(uploadFile) ||
      !isCheckboxChecked
    )
      return
    setIsLoading(true)
    postVulnerabilityAndFile()
  }

  const reset = (resetLevelOfExcAndScope = true) => {
    setIsVulnerabilityTouched(false)
    setIsStartDateTouched(false)
    setIsEndDateTouched(false)
    setIsVulnerabilitySubTypeTouched(false)
    setVulnerabilityItems([])
    setVulnerability(null)
    setIsInstanceTouched(false)
    setInstanceItems([])
    setInstance(null)
    setIsAccountTouched(false)
    setAccount(null)
    setIsExemptionReasonTouched(false)
    if (resetLevelOfExcAndScope) {
      setScopeOfExemption(scopeOfExe.this_vul)
      setLevelOfExemption(levelOfExe.instance)
    }
    setExceptionType("Permanent Exception")
    setExemptionReason("")
    setStartDate("")
    setEndDate("")
    resetUploadFile()
    setIsUploadFileTouched(false)
    setIsCheckboxTouched(false)
    setIsCheckboxChecked(false)
  }
  const resetUploadFile = () => {
    setUploadFile([])
  }

  useEffect(() => {
    const filteredInstances = vulnerabilityDetailsData?.data?.filter(
      (vulnerabilityDetail) => {
        return vulnerabilityDetail.account_id === account
      }
    )
    const instItems = filteredInstances?.map((vulnerabilityDetail) => {
      return {
        key: vulnerabilityDetail.resource_id,
        text: vulnerabilityDetail.resource_name
      }
    })

    const nonRedundantInstItems = [
      ...new Map(
        instItems?.map((item) => {
          return [item.text, item]
        })
      ).values()
    ]
    setInstanceItems(nonRedundantInstItems)
  }, [account, vulnerabilityDetailsData])

  useEffect(() => {
    const filteredVulnerabilities = vulnerabilityDetailsData?.data?.filter(
      (vulnerabilityDetail) => {
        return (
          (vulnerabilityDetail.resource_id === instance ||
            !isInstanceVisible) &&
          (vulnerabilityDetail.account_id === account || !isAccountVisible)
        )
      }
    )

    const instItems = filteredVulnerabilities?.map((vulnerabilityDetail) => {
      const vulnerabityPlugin =
        vulnerabilityDetail.plugin_id + " - " + vulnerabilityDetail.plugin_name
      return { key: vulnerabilityDetail.plugin_id, text: vulnerabityPlugin }
    })

    const nonRedundantInstItems = [
      ...new Map(
        instItems?.map((item) => {
          return [item.text, item]
        })
      ).values()
    ]
    setVulnerabilityItems(nonRedundantInstItems)
  }, [
    instance,
    vulnerabilityDetailsData,
    isAccountVisible,
    isInstanceVisible,
    account
  ])

  const onOk = () => {
    history.push({
      pathname: "/service",
      search: `?service_number=${createdReqNumber}`
    })
  }

  const handleCancel = () => {
    setShowRedirectionModal(false)
  }
  return createPortal(
    <ErrorBoundary fallback={<WentWrongCard />}>
      <React.Suspense fallback={<ErrorCard />}>
      
        <CModal
          show={show}
          onClose={() => {
            reset()
            setShow(false)
          }}
          size="lg"
          centered
          className="incident-modal"
        >
          <CModalBody className="pb-2 bg-body-color">
            <img
              src={Close}
              alt="close-icon"
              className="cursor-pointer close-icon"
              onClick={() => {
                setShow(false)
                reset()
              }}
            />
            <NoDataAndWentWrongHandler
              wentWrongCondition={Boolean(vulnerabilityDetailsData?.error)}
            >
              <>
                <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
                  <p className="header-text mb-2 font-weight-bold">
                    Request an exception to a Vulnerability
                  </p>
                  <div className="dotted-border-bottom"></div>
                  {/* Scope Of Exception */}
                  <CRow>
                    <CCol
                      className={`${
                        selectedAccount ? "cursor-not-allowed" : ""
                      }`}
                    >
                      <CRow className="mx-3 mt-3">
                        <CCol md="12">
                          <p className="font-weight-bold">Scope of exception</p>
                        </CCol>
                      </CRow>
                      {Object.keys(scopeOfExe).map((item, index) => {
                        return (
                          <CRow className="mx-3 mt-1" key={index}>
                            <CCol md="12" className="d-flex align-items-center">
                              <CInputRadio
                                className="mr-2 ml-1 d-flex align-items-center"
                                checked={scopeOfExemption === scopeOfExe[item]}
                                onChange={() => {
                                  reset(false)
                                  setScopeOfExemption(scopeOfExe[item])
                                }}
                                disabled={selectedAccount}
                              />
                              <p
                                className={`cursor-pointer field-label ml-4 pl-2 mt-1 ${
                                  selectedAccount ? "cursor-not-allowed" : ""
                                } ${
                                  scopeOfExemption === scopeOfExe[item]
                                    ? "font-weight-bold"
                                    : ""
                                }`}
                                onClick={
                                  !selectedAccount &&
                                  (() => {
                                    reset(false)
                                    setScopeOfExemption(scopeOfExe[item])
                                  })
                                }
                                disabled={selectedAccount}
                              >
                                {scopeOfExe[item]}
                              </p>
                            </CCol>
                          </CRow>
                        )
                      })}
                    </CCol>
                    {/* Level Of Exception */}
                    <CCol>
                      <CRow className="mx-3 mt-3">
                        <CCol md="12">
                          <p className="font-weight-bold">Level of exception</p>
                        </CCol>
                      </CRow>
                      {Object.keys(levelOfExe).map((item, index) => {
                        return (
                          <CRow className="mx-3 mt-1" key={index}>
                            <CCol md="12" className="d-flex align-items-center">
                              <CInputRadio
                                className="mr-2 ml-1 d-flex align-items-center"
                                checked={levelOfExemption === levelOfExe[item]}
                                onChange={() => {
                                  reset(false)
                                  setLevelOfExemption(levelOfExe[item])
                                }}
                              />
                              <p
                                className={`cursor-pointer field-label ml-4 pl-2 mt-1 ${
                                  levelOfExemption === levelOfExe[item]
                                    ? "font-weight-bold"
                                    : ""
                                }`}
                                onClick={() => {
                                  reset(false)
                                  setLevelOfExemption(levelOfExe[item])
                                }}
                              >
                                {levelOfExe[item]}
                              </p>
                            </CCol>
                          </CRow>
                        )
                      })}
                    </CCol>
                  </CRow>
                  {/* Account Visible */}
                  {isAccountVisible && (
                    <CRow className="mx-3 mt-3">
                      <CCol md="12">
                        <p className="font-weight-bold mb-1">Account *</p>
                        <OptionDropdown
                          value={account}
                          items={accountItems}
                          onChange={setAccount}
                          buttonClassName={`py-1 border-dark w-100 ${
                            selectedAccount ? "cursor-not-allowed" : ""
                          }${isAccountTouched && !account ? "is-invalid" : ""}`}
                          className=" w-100"
                          disabled={selectedAccount}
                        />
                        <div className="invalid-feedback">
                          This Field is Required
                        </div>
                      </CCol>
                    </CRow>
                  )}
                  {/* Instance Visible */}
                  {isInstanceVisible && (
                    <CRow className="mx-3 mt-3">
                      <CCol md="12">
                        <p className="font-weight-bold mb-1">Instance *</p>
                        <OptionDropdown
                          value={instance}
                          items={instanceItems}
                          onChange={setInstance}
                          className=" w-100"
                          buttonClassName={`py-1 border-dark w-100 ${
                            selectedAccount ? "cursor-not-allowed" : ""
                          }
                  ${isInstanceTouched && !instance ? "is-invalid" : ""}`}
                          disabled={selectedAccount}
                        />
                        <div className="invalid-feedback">
                          This Field is Required
                        </div>
                      </CCol>
                    </CRow>
                  )}
                  {/* Vulnerability Visible */}
                  {isVulVisible && (
                    <CRow className="mx-3 mt-3">
                      <CCol className="w-100">
                        <p className="font-weight-bold mb-1">Vulnerability *</p>
                        <OptionDropdown
                          value={vulnerability}
                          items={vulnerabilityItems}
                          onChange={setVulnerability}
                          buttonClassName={`py-1 border-dark w-100 ${
                            selectedAccount ? "cursor-not-allowed" : ""
                          }
                  ${
                    isVulnerabilityTouched && !vulnerability ? "is-invalid" : ""
                  }
                  `}
                          disabled={selectedAccount}
                        />
                        <div className="invalid-feedback">
                          This Field is Required
                        </div>
                      </CCol>
                    </CRow>
                  )}

                  {isVulSubVisible && (
                    <CRow className="mx-3 mt-3">
                      <CCol className="w-100">
                        <p className="font-weight-bold mb-1">
                          Vulnerability Sub Type
                        </p>
                        <OptionDropdown
                          value={vulnerabilitySubType}
                          items={vulnSubTypes}
                          onChange={setVulnerabilitySubType}
                          className="w-100"
                          buttonClassName={`py-1 border-dark w-100 ${
                            selectedAccount ? "cursor-not-allowed" : ""
                          }
                  ${
                    isVulnerabilitySubTypeTouched && !vulnerabilitySubType
                      ? "is-invalid"
                      : ""
                  }
                  `}
                          disabled={selectedAccount}
                        />
                        <div className="invalid-feedback">
                          This Field is Required
                        </div>
                      </CCol>
                    </CRow>
                  )}

                  <CRow className="mx-3 mt-3">
                    <CCol md="12">
                      <p className="font-weight-bold mb-1">
                        Exception Reason *
                      </p>
                      <CInput
                        type="text"
                        name="improvements"
                        value={exemptionReason}
                        className={`${
                          isExemptionReasonTouched && !exemptionReason
                            ? "is-invalid"
                            : ""
                        }`}
                        onChange={(e) => {
                          setExemptionReason(e.target.value)
                          setIsExemptionReasonTouched(true)
                        }}
                      />
                      <div className="invalid-feedback">
                        This Field is Required
                      </div>
                    </CCol>
                  </CRow>
                  {/* Permanent Exception */}
                  <CRow className="mx-3 mt-1">
                    <CCol md="12" className="d-flex align-items-center">
                      <CInputRadio
                        className="mr-2 ml-1 d-flex align-items-center"
                        checked={exceptionType === "Permanent Exception"}
                        onChange={() => setExceptionType("Permanent Exception")}
                      />
                      <p
                        className={`cursor-pointer field-label ml-4 pl-2 mt-1 ${
                          exceptionType === "Permanent Exception"
                            ? "font-weight-bold"
                            : ""
                        }`}
                        onClick={() => setExceptionType("Permanent Exception")}
                      >
                        Permanent Exception
                      </p>
                    </CCol>
                  </CRow>
                  {/* Temporary */}
                  <CRow className="mx-3 mt-1">
                    <CCol md="12" className="d-flex align-items-center">
                      <CInputRadio
                        className="mr-2 ml-1 d-flex align-items-center"
                        checked={exceptionType === "Temporary Exception"}
                        onChange={() => setExceptionType("Temporary Exception")}
                      />
                      <p
                        className={`cursor-pointer field-label ml-4 pl-2 mt-1 ${
                          exceptionType === "Temporary Exception"
                            ? "font-weight-bold"
                            : ""
                        }`}
                        onClick={() => setExceptionType("Temporary Exception")}
                      >
                        Temporary Exception
                      </p>
                    </CCol>
                  </CRow>
                  {exceptionType === "Temporary Exception" && (
                    <CRow className="m-3 pr-3">
                      <CCol md="6">
                        <p className="field-label mb-2 mt-1">
                          Exception Start Date
                        </p>
                        <DatePickerInput
                          className={`w-100 ${
                            isStartDateTouched && !startDate ? "is-invalid" : ""
                          }`}
                          value={startDate}
                          range={false}
                          onChange={(val) => {
                            setStartDate(val)
                          }}
                        />
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      </CCol>
                      <CCol md="6">
                        <p className="field-label mb-2 mt-1">
                          Exception End Date
                        </p>
                        <DatePickerInput
                          className={`w-100 ${
                            isEndDateTouched && !endDate ? "is-invalid" : ""
                          }`}
                          value={endDate}
                          range={false}
                          onChange={(val) => {
                            setEndDate(val)
                          }}
                          minDate={startDate}
                        />
                        <div className="invalid-feedback">
                          This field is required
                        </div>
                      </CCol>
                    </CRow>
                  )}
                  <CRow className="mx-3 mt-3">
                    <FileAttachment
                      uploadFile={uploadFile}
                      isUploadFileTouched={isUploadFileTouched}
                      setUploadFile={setUploadFile}
                      setIsUploadFileTouched={setIsUploadFileTouched}
                      fileInputRef={fileInputRef}
                      required
                    />
                  </CRow>
                  <CRow className="mx-3 mt-4">
                    <CCol
                      md="12"
                      className="d-flex flex-column align-items-center"
                    >
                      <div className="ml-3 pl-1">
                        <CInputCheckbox
                          className={`mr-2 cursor-pointer ${
                            isCheckboxTouched && !isCheckboxChecked
                              ? "is-invalid"
                              : ""
                          }`}
                          checked={isCheckboxChecked}
                          onChange={() => {
                            setIsCheckboxChecked(
                              (isCheckboxChecked) => !isCheckboxChecked
                            )
                            setIsCheckboxTouched(true)
                          }}
                        />
                        <p>
                          I confirm that all the necessary approvals have been
                          uploaded, and they are in sync with the info provided
                          in this request.
                        </p>
                        <div className="invalid-feedback ml-checkbox">
                          This field is required
                        </div>
                      </div>
                    </CCol>
                  </CRow>

                  <FormActions
                    onSubmit={handleSubmit}
                    onCancel={() => {
                      reset()
                      setShow(false)
                    }}
                    // isSubmitDisabled={false}
                    cancelBtnStyling="mr-4"
                  />
                </LoadingSpinnerOverlay>
              </>
            </NoDataAndWentWrongHandler>
          </CModalBody>
        </CModal>
      </React.Suspense>
      {ReactDOM.createPortal(
        <ConfirmModal
          show={showRedirectionModal}
          setShow={setShowRedirectionModal}
          message={`Vulnerability exception requested successfully${
            createdReqNumber ? ` with number ${createdReqNumber}` : ""
          }`}
          onCancel={handleCancel}
          onOkay={onOk}
          okayBtnText="View Service Request"
        />,
        document.getElementById("status-modal")
      )}
    </ErrorBoundary>
    , document.getElementById("status-modal")
  )
}
VulnerabilityExemptionRequest.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  selectedAccount: PropTypes.func,
  selectedVulnerability: PropTypes.func,
  selectedInstance: PropTypes.func
}
