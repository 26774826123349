import { spring } from "react-router-transition"
// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles (styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
    width: "100%"
  }
}

// wrap the `spring` helper to use a bouncy config
function makeBounce (stiffness, damping) {
  return (val) =>
    spring(val, {
      stiffness: stiffness,
      damping: damping
    })
}

// child matches will...
function makeFadeInOutBounce (stiffness, damping) {
  const bounce = makeBounce(stiffness, damping)
  return {
    // start in a transparent, upscaled state
    atEnter: {
      opacity: 0,
      scale: 1.2
    },
    // leave in a transparent, downscaled state
    atLeave: {
      opacity: bounce(0),
      scale: bounce(0.95)
    },
    // and rest at an opaque, normally-scaled state
    atActive: {
      opacity: bounce(1),
      scale: bounce(1)
    }
  }
}

export { mapStyles as fadeInOutBounceMapStyles }
export { makeFadeInOutBounce }
