import { createFetcher } from "api/helpers"
const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, project, params) => {
    return `${host}/itsm/${project}/get_incident_count${params ? `?${params}` : ""}`
  },
  checkRequiredArguments: (params) => {
    if (params === undefined) console.log("No params data supplied.")
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
