import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, project) => {
    return `${host}/itsm/${project}/list_accounts`
  },
  checkRequiredArguments: () => {
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
