import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, project) => {
    return `${host}/itsm/${project}/report_service_request`
  },
  checkRequiredArguments: (changeRequestData) => {
    if (changeRequestData === undefined) throw new Error("No change request data supplied.")
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
