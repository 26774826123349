import "./tooltip.scss"

import PropTypes from "prop-types"
import React from "react"

export default function SidebarTooltipWrapper({
  children,
  tooltipText = "",
  showTooltip = false,
  tableClass,
  position = "right"
}) {
  if (!showTooltip) return children
  if (tableClass) {
    return (
      <div className="sidebar-tooltip-wrapper">
        {children}
        <div className={`tip-container ${position}`}>
          <div className={`tabletip-container ${position}`}>{tooltipText}</div>
        </div>
      </div>
    )
  }

  return (
    <div className="sidebar-tooltip-wrapper">
      {children}
      <div className={"tooltip-container"}>
        <div className={"tabletip-container"}>{tooltipText}</div>
      </div>
    </div>
  )
}

SidebarTooltipWrapper.propTypes = {
  children: PropTypes.node,
  tooltipText: PropTypes.node,
  showTooltip: PropTypes.bool,
  tableClass: PropTypes.bool,
  position: PropTypes.string
}
