/**
 * Returns the week number for this date.  dowOffset is the day of week the week
 * "starts" on for your locale - it can be from 0 to 6. If dowOffset is 1 (Monday),
 * the week returned is the ISO 8601 week number.
 * @param: dowOffset (number)
 * @return: (number)
 */

const fullMonths = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
]

function getWeek (dateObj, dowOffset = 0) {
  const newYear = new Date(dateObj.getFullYear(), 0, 1)
  let day = newYear.getDay() - dowOffset // the day of week the year begins on
  day = day >= 0 ? day : day + 7
  const daynum =
    Math.floor(
      (dateObj.getTime() -
        newYear.getTime() -
        (dateObj.getTimezoneOffset() - newYear.getTimezoneOffset()) * 60000) /
        86400000
    ) + 1
  let weeknum
  // if the year starts before the middle of a week
  if (day < 4) {
    weeknum = Math.floor((daynum + day - 1) / 7) + 1
    if (weeknum > 52) {
      const nYear = new Date(dateObj.getFullYear() + 1, 0, 1)
      let nday = nYear.getDay() - dowOffset
      nday = nday >= 0 ? nday : nday + 7
      /* if the next year starts before the middle of
              the week, it is week #1 of that year */
      weeknum = nday < 4 ? 1 : 53
    }
  } else {
    weeknum = Math.floor((daynum + day - 1) / 7)
  }
  return weeknum
}
function getWeekStart (dateObj) {
  const start = new Date()
  start.setDate(dateObj.getDate() - dateObj.getDay())
  start.setHours(0)
  start.setMinutes(0)
  start.setSeconds(0)
  start.setMilliseconds(0)
  return start
}

function getCurrentWeekStart () {
  const now = new Date()
  return getWeekStart(now)
}
function getCurrentFullMonth () {
  const now = new Date()
  return fullMonths[now.getMonth()]
}

function getCurrentFullYear () {
  const now = new Date()
  return now.getFullYear()
}

function getFullMonth (index) {
  if (index < 0 || index > 11) {
    return null
  }
  return fullMonths[index]
}

function getFirstDayCurrentMonth () {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth(), 1)
}
function getLastDayCurrentMonth () {
  const now = new Date()
  return new Date(now.getFullYear(), now.getMonth() + 1, 0)
}
export { getWeek }
export { getWeekStart }
export { getCurrentFullMonth }
export { getCurrentFullYear }
export { getFullMonth }
export { getFirstDayCurrentMonth }
export { getLastDayCurrentMonth }
export { getCurrentWeekStart }
export { fullMonths }
