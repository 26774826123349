import { MultipleExport } from "components/common/export"
import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"

import Pagination from "../../../../../components/common/pagination/Pagination"
import ChangeRequestTable from "./ChangeRequestTable"
import IncidentsTable from "./IncidentsTable"

export default function DrawerTable({
  stateList,
  state,
  currentPage,
  setCurrentPage,
  maxLimit,
  setMaxLimit,
  minLimit,
  setMinLimit,
  date,
  selectedAccount,
  type,
  multiselectOption
}) {
  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [noOfPages, setNoOfPages] = useState(1)

  const [pageNumberLimit] = useState(5)

  const [missedData, setMissedData] = useState({
    Response: [],
    Resolution: []
  })

  const [incData, setIncData] = useState({
    Open: [],
    Resolved: [],
    "My Tickets": []
  })

  const [crData, setCrData] = useState({
    Open: [],
    Resolved: [],
    "My Tickets": []
  })

  const [awaitingIncData, setAwaitingIncData] = useState([])
  const [awaitingCrData, setAwaitingCrData] = useState([])
  const [incBreakdown, setIncBreakdown] = useState([])
  const [crBreakdown, setCrBreakdown] = useState([])
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage

  useEffect(() => {
    let pages
    if (type === "Missed") {
      pages = Math.ceil(missedData[state].length / itemsPerPage)
    } else if (type === "Incidents") {
      pages = Math.ceil(incData[state].length / itemsPerPage)
    } else if (type === "Awaiting Service Requests") {
      pages = Math.ceil(awaitingCrData.length / itemsPerPage)
    } else if (type === "Awaiting Incidents") {
      pages = Math.ceil(awaitingIncData.length / itemsPerPage)
    } else if (type === "Incident Breakdown") {
      pages = Math.ceil(incBreakdown.length / itemsPerPage)
    } else if (type === "Service Request Breakdown") {
      pages = Math.ceil(crBreakdown.length / itemsPerPage)
    } else {
      pages = Math.ceil(crData[state].length / itemsPerPage)
    }
    setNoOfPages(pages)
  }, [
    missedData,
    incData,
    crData,
    itemsPerPage,
    type,
    state,
    awaitingCrData,
    awaitingIncData,
    incBreakdown,
    crBreakdown
  ])

  function changeData(newData, type) {
    const copy = { ...crData }
    copy[state] = newData
    setCrData(copy)
    if (type === "cr") {
      setCrData(copy)
    } else {
      setIncData(copy)
    }
  }

  const missedExcelDataset = {
    name: state,
    columns: [
      "Ticket Id",
      "Requestor",
      "Incident Type",
      "Priority",
      "Subject",
      "Status"
    ],
    data: missedData[state]?.map((data) => [
      { value: data.incident_number },
      { value: data.assignment_group_name },
      { value: "Incident" },
      { value: data.priority },
      { value: data.short_description },
      { value: data.incident_state }
    ])
  }

  const incidentExcelDataset = stateList?.map((state) => ({
    name: state,
    columns: [
      "Ticket Id",
      "Requestor",
      "Incident Type",
      "Priority",
      "Subject",
      "Status"
    ],
    data: incData[state]?.map((data) => [
      { value: data.incident_number },
      { value: data.assignment_group_name },
      { value: "Incident" },
      { value: data.priority },
      { value: data.short_description },
      { value: data.incident_state }
    ])
  }))

  const awaitingIncExcelDataset = {
    name: state,
    columns: [
      "Ticket Id",
      "Requestor",
      "Incident Type",
      "Priority",
      "Subject",
      "Status"
    ],
    data: awaitingIncData?.map((data) => [
      { value: data.incident_number },
      { value: data.assignment_group_name },
      { value: "Incident" },
      { value: data.priority },
      { value: data.short_description },
      { value: data.incident_state }
    ])
  }

  const incBreakdownExcelDataset = {
    name: state,
    columns: [
      "Ticket Id",
      "Requestor",
      "Incident Type",
      "Priority",
      "Subject",
      "Status"
    ],
    data: incBreakdown?.map((data) => [
      { value: data.incident_number },
      { value: data.assignment_group_name },
      { value: "Incident" },
      { value: data.priority },
      { value: data.short_description },
      { value: data.incident_state }
    ])
  }

  const awaitingCrExcelDataset = {
    name: state,
    columns: [
      "Change Id",
      "Requestor",
      "Assigned To",
      "Priority",
      "Stage",
      "Subject"
    ],
    data: awaitingCrData?.map((data) => [
      { value: data.change_number },
      { value: data.assignment_group_name },
      { value: data.assigned_to ? data.assigned_to : "N/A" },
      { value: data.change_state },
      { value: data.priority },
      { value: data.short_description ? data.short_description : "N/A" }
    ])
  }

  const crBreakdownExcelDataset = {
    name: state,
    columns: [
      "Change Id",
      "Requestor",
      "Assigned To",
      "Priority",
      "Stage",
      "Subject"
    ],
    data: crBreakdown?.map((data) => [
      { value: data.change_number },
      { value: data.assignment_group_name },
      { value: data.assigned_to ? data.assigned_to : "N/A" },
      { value: data.change_state },
      { value: data.priority },
      { value: data.short_description ? data.short_description : "N/A" }
    ])
  }

  const changeRequestsExcelDatasets = stateList?.map((state) => ({
    name: state,
    columns: [
      "Change Id",
      "Requestor",
      "Assigned To",
      "Priority",
      "Stage",
      "Subject"
    ],
    data: crData[state]?.map((data) => [
      { value: data.change_number },
      { value: data.assignment_group_name },
      { value: data.assigned_to ? data.assigned_to : "N/A" },
      { value: data.change_state },
      { value: data.priority },
      { value: data.short_description ? data.short_description : "N/A" }
    ])
  }))

  function drawerData() {
    if (
      type === "Incidents" ||
      type === "Awaiting Incidents" ||
      type === "Incident Breakdown" ||
      type === "Missed"
    ) {
      return (
        <IncidentsTable
          indexOfFirstItem={indexOfFirstItem}
          indexOfLastItem={indexOfLastItem}
          changeData={changeData}
          incData={incData}
          setIncData={setIncData}
          date={date}
          state={state}
          selectedAccount={selectedAccount}
          multiselectOption={multiselectOption}
          awaitingData={awaitingIncData}
          setAwaitingData={setAwaitingIncData}
          incBreakdown={incBreakdown}
          setIncBreakdown={setIncBreakdown}
          missedData={missedData}
          setMissedData={setMissedData}
        />
      )
    } else {
      return (
        <ChangeRequestTable
          indexOfFirstItem={indexOfFirstItem}
          indexOfLastItem={indexOfLastItem}
          changeData={changeData}
          crData={crData}
          setCrData={setCrData}
          state={state}
          date={date}
          selectedAccount={selectedAccount}
          multiselectOption={multiselectOption}
          awaitingData={awaitingCrData}
          setAwaitingData={setAwaitingCrData}
          crBreakdown={crBreakdown}
          setCrBreakdown={setCrBreakdown}
        />
      )
    }
  }

  function excelExport() {
    if (type === "Missed") {
      return (
        <MultipleExport fileName={state} excelDatasets={[missedExcelDataset]} />
      )
    } else if (type === "Incidents") {
      return (
        <MultipleExport fileName={state} excelDatasets={incidentExcelDataset} />
      )
    } else if (type === "Awaiting Incidents") {
      return (
        <MultipleExport
          fileName={state}
          excelDatasets={[awaitingIncExcelDataset]}
        />
      )
    } else if (type === "Awaiting Service Requests") {
      return (
        <MultipleExport
          fileName={state}
          excelDatasets={[awaitingCrExcelDataset]}
        />
      )
    } else if (type === "Incident Breakdown") {
      return (
        <MultipleExport
          fileName={state}
          excelDatasets={[incBreakdownExcelDataset]}
        />
      )
    } else if (type === "Service Request Breakdown") {
      return (
        <MultipleExport
          fileName={state}
          excelDatasets={[crBreakdownExcelDataset]}
        />
      )
    } else {
      return (
        <MultipleExport
          fileName={"Service Requests"}
          excelDatasets={changeRequestsExcelDatasets}
        />
      )
    }
  }

  return (
    <>
      <div className="stat-div">
        <div className="d-flex w-10">{excelExport()}</div>
      </div>
      <div className="drawer-content">{drawerData()}</div>
      <div className="drawer-footer">
        {noOfPages > 0 ? (
          <Pagination
            itemsPerPage={itemsPerPage}
            setItemsPerPage={setItemsPerPage}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            minLimit={minLimit}
            setMinLimit={setMinLimit}
            maxLimit={maxLimit}
            setMaxLimit={setMaxLimit}
            pageNumberLimit={pageNumberLimit}
            noOfPages={noOfPages}
          />
        ) : null}
      </div>
    </>
  )
}
DrawerTable.propTypes = {
  stateList: PropTypes.array,
  state: PropTypes.string,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  maxLimit: PropTypes.number,
  setMaxLimit: PropTypes.func,
  minLimit: PropTypes.number,
  setMinLimit: PropTypes.func,
  date: PropTypes.string,
  selectedAccount: PropTypes.object,
  type: PropTypes.string,
  multiselectOption: PropTypes.array
}
