import PropTypes from "prop-types"
import React from "react"

import NoData from "./NoData"
import WentWrong from "./WentWrong"

function NoDataAndWentWrongHandler ({
  noDataCondition,
  wentWrongCondition,
  noDataComponent,
  wentWrongComponent,
  children
}) {
  if (wentWrongCondition) {
    return wentWrongComponent
  }
  if (noDataCondition) {
    return noDataComponent
  }
  return children
}

NoDataAndWentWrongHandler.defaultProps = {
  noDataCondition: false,
  wentWrongCondition: false,
  noDataComponent: <NoData />,
  wentWrongComponent: <WentWrong />,
  children: null
}

NoDataAndWentWrongHandler.propTypes = {
  noDataCondition: PropTypes.bool,
  wentWrongCondition: PropTypes.bool,
  noDataComponent: PropTypes.element,
  wentWrongComponent: PropTypes.element,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
}

export default NoDataAndWentWrongHandler
