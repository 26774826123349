import { AuthenticatedTemplate } from "@azure/msal-react"
import * as pages from "pages"
import PropTypes from "prop-types"
import React from "react"
import { Route } from "react-router-dom"
import { AnimatedSwitch } from "react-router-transition"
import {
  fadeInOutBounceMapStyles,
  makeFadeInOutBounce,
  useExtension
} from "utils"

const fadeInFadeOutBounce = makeFadeInOutBounce(100, 22)

const RouteWrapper = ({ children }) => {
  return (
    <AnimatedSwitch
      atEnter={fadeInFadeOutBounce.atEnter}
      atActive={fadeInFadeOutBounce.atActive}
      atLeave={fadeInFadeOutBounce.atLeave}
      mapStyles={fadeInOutBounceMapStyles}
      className="route-wrapper"
    >
      {children}
    </AnimatedSwitch>
  )
}

export default function AppRoutes() {
  const { modules: extensionModules } = useExtension()
  const { routes: extensionRoutes } = extensionModules
  const { config } = useExtension()
  const [loginBaseURL] = React.useState(config?.env?.oktaConfig?.url)
  if (loginBaseURL === undefined) throw new Error("Cannot retrieve login url.")

  return (
    <>
      <RouteWrapper>
        <AuthenticatedTemplate>{extensionRoutes}</AuthenticatedTemplate>
        <Route component={pages.Page404} />
      </RouteWrapper>
    </>
  )
}

RouteWrapper.propTypes = {
  children: PropTypes.node
}
