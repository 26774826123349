import "./timeline.scss"

import { CButton, CInput } from "@coreui/react"
import greenDot from "assets/icons/green_dot.svg"
import downArrow from "assets/icons/green_down_arrow.svg"
import classNames from "classnames"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { Chrono } from "react-chrono"

export default function SimpleTimeline({
  timelineItems,
  message,
  changeMsg,
  submitMsg
}) {
  const [messageTouched, setMessageTouched] = useState("")
  return (
    <div className="simple-timeline-styles">
      <img src={downArrow} alt="down" />
      <Chrono
        items={timelineItems}
        mode="VERTICAL_ALTERNATING"
        hideControls
        useReadMore={false}
        allowDynamicUpdate
      >
        <div className="chrono-icons">
          {timelineItems.map((item) => (
            <img
              src={greenDot}
              alt="dot"
              key={item.title}
              className="timeline-item-icon"
            />
          ))}
        </div>
      </Chrono>
      <div className="extra-timeline-strip"></div>
      <div className="d-flex">
        <CInput
          className={classNames("msg-input border", {
            "is-invalid": messageTouched && message.length < 1
          })}
          placeholder="Type your message here"
          value={message}
          onChange={(e) => {
            setMessageTouched(true)
            changeMsg(e)
          }}
        />
        <CButton
          color="primary"
          onClick={() => submitMsg()}
          className={`send-btn ml-2 ${
            !(message.length >= 1) ? "cursor-not-allowed" : ""
          }`}
          disabled={!(message.length >= 1)}
        >
          Send
        </CButton>
      </div>
      <div className="msgErr" hidden={!(messageTouched && message.length < 1)}>
        This field is required
      </div>
    </div>
  )
}
SimpleTimeline.propTypes = {
  timelineItems: PropTypes.array,
  message: PropTypes.string,
  changeMsg: PropTypes.func,
  submitMsg: PropTypes.func
}
