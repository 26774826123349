function conditionalFetchingMiddleware (useSWRNext) {
  return (key, fetcher, config) => {
    const keyNext = key instanceof Function ? key() : key
    const swr = useSWRNext(
      (!config?.suspense || keyNext) ? key : "unused-key",
      (!config?.suspense || keyNext) ? fetcher : () => null,
      config
    )
    return swr
  }
}

export default conditionalFetchingMiddleware
