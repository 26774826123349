import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, project) => {
    return `${host}/itsm/${project}/request_gcp_firewall`
  },
  checkRequiredArguments: (changeRequestData) => {
    if (changeRequestData === undefined) throw new Error("No change request data supplied.")
  },
  makeResponseData: (json) => {
    return json
  },
  returnRawResponse: true
}
export default createFetcher(fetcherConfiguration)
