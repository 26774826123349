import React from "react"

export default class extends React.Component {
  state = { hasError: false, error: null }
  static getDerivedStateFromError(error) {
    return {
      hasError: true,
      error
    }
  }
  render() {
    if (this.state.hasError) {
      return React.cloneElement(this.props.fallback, {
        error: this.state.error
      })
    }
    return this.props.children
  }
}
