import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"

const restoreSettings = createAsyncThunk(
  "settings/restoreSettings",
  async () => {}
)

const createSettingsSlice = (initialState = { currentProject: {} }) =>
  createSlice({
    name: "settings",
    initialState,
    reducers: {
      setProject (state, action) {
        const currentProject = action.payload
        state.currentProject = currentProject
        return state
      }
    },
    extraReducers: {
      [restoreSettings.fulfilled]: () => {},
      [restoreSettings.rejected]: () => {}
    }
  })

const settingsSlice = createSettingsSlice()
const actions = settingsSlice.actions
const reducer = settingsSlice.reducer

export {
  actions as settingsActions,
  reducer as settingsReducer,
  createSettingsSlice
}
