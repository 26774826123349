import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"

function IconDropdown ({ items, icon }) {
  return (
    <>
      <CDropdown>
        <CDropdownToggle className="bg-primary text-dark" caret>
          {icon}
        </CDropdownToggle>
        <CDropdownMenu style={{ right: "auto" }}>{items}</CDropdownMenu>
      </CDropdown>
    </>
  )
}
IconDropdown.defaultProps = {
  items: []
}
IconDropdown.propTypes = {
  items: PropTypes.array,
  icon: PropTypes.object
}

export default IconDropdown
