import PropTypes from "prop-types"
import React from "react"
import RequestSuccess from "./RequestSuccess"

export default function CESOPRequestSuccess({ requestNumber }) {

  const successMessage = `Thank you for submitting your request for the CESOP Customer Account. The creation process will begin soon.`

  return (
    <RequestSuccess
        requestNumber={requestNumber}  
    >{successMessage}</RequestSuccess>
  )
}

CESOPRequestSuccess.propTypes = {
  requestNumber: PropTypes.string
}
