import "./styles/style.scss"

import { datadogRum } from "@datadog/browser-rum"
import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { far } from "@fortawesome/free-regular-svg-icons"
import { fas } from "@fortawesome/free-solid-svg-icons"
import { DefaultLayout } from "layouts"
import {
  AuthenticatedMsalSessionMonitor,
} from "msalAuth/AuthSessionMonitor"
import MsalRoutes from "MsalRoutes"
import React from "react"
import { useSelector } from "react-redux"
import { useExtension } from "utils"

import Routes from "./routes"

library.add(fas, far, fab)

export default function App(props) {
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated);
  const userRole = useSelector((state) => state.user.role);
  const extension = useExtension();

  const datadogConfig = extension?.config?.env?.datadogConfig;
  datadogRum.init(datadogConfig);

  return (
    <>
      <AuthenticatedMsalSessionMonitor>
        <DefaultLayout
          isAuthenticated={isAuthenticated}
          {...props}
        >
          {isAuthenticated && userRole !== "Portal-DeloitteDSEUser" && (
            <Routes />
          )}
          {isAuthenticated && userRole === "Portal-DeloitteDSEUser" && (
            <MsalRoutes />
          )}
        </DefaultLayout>
      </AuthenticatedMsalSessionMonitor>

      <div id="drawerHolder"></div>
      <div id="status-modal"></div>
      <div id="confirm-modal"></div>
      <div id="maintenance-modal"></div>
      <div id="terms-and-conditions-modal"></div>
    </>
  )
}
