import { CCol, CRow } from "@coreui/react"
import ToasterProvider from "components/common/Context/ToasterContext"
import useNotifications from "components/common/customHooks/useNotifications"
import { LoadingSpinnerOverlay } from "components/common/loading"
import {
  getAssignmentGroups,
  postRequestAzureVirtualMachine
} from "extensions/usoc/api/service"
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import useSWR from "swr"
import { toStandardDateString, useExtension } from "utils"
import { useUserAuth } from "utils/hooks"

import { AzureVM } from "./AzureVM"
import {
  booleanOptions,
  locationOptions,
  scheduleProfileValues,
  scheduleTimeZoneOptions
} from "./constants"
import FormActions from "./FormActions"
import { ProvisionZone } from "./ProvisionZone"

const initialAVMState = {
  cloudAccount: "Azure",
  costCenter: "",
  location: locationOptions[0],
  businessService: "",
  stackName: "",
  existingWorkspace: "",
  userGroup: "",
  workspace: "",
  scheduleProfile: scheduleProfileValues[0],
  resourceGroup: "",
  leaseEndDate: new Date(),
  resourceGroupName: "",
  scheduleTimeZone: scheduleTimeZoneOptions[0].key,
  createResourceGroup: booleanOptions[0].key,
  application: "",
  subscriptionId: ""
}
const initialProvZoneState = {
  vmTemplateLocation: "eastus",
  vmRg: "OktaDev",
  networkInterfaceName: "snowvmtest162",
  osDiskType: "Premium_LRS",
  subnetName: "OktaSubnet01",
  vmSize: "Standard_DS1_v2",
  virtualNetworkId:
    "/subscriptions/0bdd15e0-d6fd-43a1-9855-43cc79d73e34/resourceGroups/OktaDev/providers/Microsoft.Network/virtualNetworks/OktaDev",
  adminUsername: "adminuser",
  vmName: "snowvmtest",
  adminPassword: "OpenCloud@123",
  vmComputerName: "snowvmtest",
  reEnterAdminPassword: "OpenCloud@123"
}

export default function RequestAzureVm() {
  const auth = useUserAuth()
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const history = useHistory()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const userEmail = useSelector((state) => {
    return state.user.email
  })

  const [AVMObject, setAVMObject] = useState(initialAVMState)
  const [provZonebject, setProvZonebject] = useState(initialProvZoneState)
  const [isCloudAccountTouched, setIsCloudAccountTouched] = useState(false)
  const [isLocationTouched, setIsLocationTouched] = useState(false)
  const [isStackNameTouched, setIsStackNameTouched] = useState(false)
  const [isUserGroupTouched, setIsUserGroupTouched] = useState(false)
  const [isScheduleProfileTouched, setIsScheduleProfileTouched] =
    useState(false)
  const [isScheduleTimeZoneTouched, setIsScheduleTimeZoneTouched] =
    useState(false)
  const [isResourceGroupTouched, setIsResourceGroupTouched] = useState(false)
  const [isResourceGroupNameTouched, setIsResourceGroupNameTouched] =
    useState(false)
  const [isCreateResourceGroupTouched, setIsCreateResourceGroupTouched] =
    useState(false)
  const [isSubscriptionIdTouched, setIsSubscriptionIdTouched] = useState(false)

  const [isLoading, setIsLoading] = useState(false)
  const [notifications, setNotifications] = useNotifications()

  const emailParams = `email=${userEmail}`

  const { data: assignmentGroups } = useSWR(
    () => (userEmail ? `/itsm/get_assignment_groups?${emailParams}` : null),
    () => getAssignmentGroups({ auth, host }, emailParams),
    { suspense: true }
  )

  useEffect(() => {
    if (
      assignmentGroups?.data &&
      Array.isArray(assignmentGroups?.data["Assigment Groups"]) &&
      assignmentGroups?.data["Assigment Groups"].length &&
      !AVMObject.userGroup
    ) {
      onAVMChange(
        "userGroup",
        assignmentGroups?.data["Assigment Groups"][0].name
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentGroups])

  const enqueueToast = useContext(ToasterProvider)

  const mandatoryDict = {
    cloudAccount: [setIsCloudAccountTouched, isCloudAccountTouched],
    location: [setIsLocationTouched, isLocationTouched],
    stackName: [setIsStackNameTouched, isStackNameTouched],
    userGroup: [setIsUserGroupTouched, isUserGroupTouched],
    scheduleProfile: [setIsScheduleProfileTouched, isScheduleProfileTouched],
    scheduleTimeZone: [setIsScheduleTimeZoneTouched, isScheduleTimeZoneTouched],
    resourceGroup: [setIsResourceGroupTouched, isResourceGroupTouched],
    resourceGroupName: [
      setIsResourceGroupNameTouched,
      isResourceGroupNameTouched
    ],
    createResourceGroup: [
      setIsCreateResourceGroupTouched,
      isCreateResourceGroupTouched
    ],
    subscriptionId: [setIsSubscriptionIdTouched, isSubscriptionIdTouched]
  }

  function onAVMChange(key, val) {
    if (Object.keys(mandatoryDict).includes(key)) {
      mandatoryDict[key][0](true)
    }
    const copy = { ...AVMObject }
    copy[key] = val
    setAVMObject(copy)
  }

  function onProvZoneChange(key, val) {
    const copy = { ...provZonebject }
    copy[key] = val
    setProvZonebject(copy)
  }

  function setAllTouchedValues(value) {
    setIsScheduleProfileTouched(value)
    setIsCloudAccountTouched(value)
    setIsLocationTouched(value)
    setIsStackNameTouched(value)
    setIsUserGroupTouched(value)
    setIsScheduleTimeZoneTouched(value)
    setIsResourceGroupNameTouched(value)
    setIsResourceGroupTouched(value)
    setIsCreateResourceGroupTouched(value)
    setIsSubscriptionIdTouched(value)
  }

  const handleSubmit = async () => {
    setAllTouchedValues(true)
    if (
      !AVMObject.scheduleProfile ||
      AVMObject.scheduleProfile === "--No Schedule--" ||
      AVMObject.scheduleTimeZone === scheduleTimeZoneOptions[0].key ||
      !AVMObject.location ||
      !AVMObject.stackName ||
      !AVMObject.userGroup ||
      AVMObject.scheduleTimeZone === "--None--" ||
      !AVMObject.resourceGroupName ||
      !AVMObject.resourceGroup ||
      AVMObject.createResourceGroup === "None" ||
      AVMObject.createResourceGroup === booleanOptions[0].key ||
      !AVMObject.subscriptionId
    ) {
      return
    }
    if (provZonebject.adminPassword !== provZonebject.reEnterAdminPassword) {
      enqueueToast("failure", null, "Passwords dosn't match!")
      return
    }
    setIsLoading(true)
    const requestAzureVmBody = {
      CloudAccount: AVMObject.cloudAccount,
      Location: AVMObject.location,
      ScheduleProfile: AVMObject.scheduleProfile,
      ScheduleTimeZone: AVMObject.scheduleTimeZone,
      UserGroup: AVMObject.userGroup,
      ResourceGroup: AVMObject.resourceGroup,
      ResourceGroupName: AVMObject.resourceGroupName,
      SubscriptionId: AVMObject.subscriptionId,
      CreateResourceGroup: AVMObject.createResourceGroup,
      Azure_Virtual_Machine_template_location: provZonebject.vmTemplateLocation,
      Azure_Virtual_Machine_networkInterfaceName:
        provZonebject.networkInterfaceName,
      Azure_Virtual_Machine_subnetName: provZonebject.subnetName,
      Azure_Virtual_Machine_virtualNetworkId: provZonebject.virtualNetworkId,
      Azure_Virtual_Machine_virtualMachineComputerName:
        provZonebject.vmComputerName,
      Azure_Virtual_Machine_virtualMachineRG: provZonebject.vmRg,
      Azure_Virtual_Machine_osDiskType: provZonebject.osDiskType,
      Azure_Virtual_Machine_virtualMachineSize: provZonebject.vmSize,
      Azure_Virtual_Machine_adminUsername: provZonebject.adminUsername,
      Azure_Virtual_Machine_adminPassword: provZonebject.adminPassword,
      StackName: AVMObject.stackName,
      Azure_Virtual_Machine_virtualMachineName: provZonebject.vmName,
      UseExistingWorkspace: AVMObject.existingWorkspace,
      Workspace: AVMObject.workspace,
      Application: AVMObject.application,
      CostCenter: AVMObject.costCenter,
      LeaseEndDate: toStandardDateString(AVMObject.leaseEndDate),
      BusinessService: AVMObject.businessService
    }
    const response = await postRequestAzureVirtualMachine(
      { auth, host, body: JSON.stringify(requestAzureVmBody) },
      projectID
    )
    if (
      !response.error &&
      response.data &&
      response.data.startsWith("Submitted")
    ) {
      try {
        let responseData = response.data.split("{")[1].split("}")[0]
        responseData = JSON.parse(`{${responseData}}`)
        enqueueToast(
          "success",
          null,
          `Azure virtual machine requested successfully${
            responseData.number ? ` with number ${responseData.number}` : ""
          }`
        )
        const currentDate = new Date()
        const notification = {
          id: notifications.length + 1,
          from: "Created Successfully!!",
          active: true,
          approved: true,
          ticket_number: responseData.number,
          time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
          description:
            "Azure virtual machine requested successfully with number ",
          requestNumber: ["ServiceRequest", responseData.number]
        }
        setNotifications([notification, ...notifications])
      } catch {
        enqueueToast(
          "success",
          null,
          "Azure virtual machine requested successfully"
        )
      }
      setAVMObject(initialAVMState)
      setProvZonebject(initialProvZoneState)
      setAllTouchedValues(false)
      history.goBack()
    } else {
      enqueueToast("failure", null, "Could not request Azure virtual machine")
      history.goBack()
    }
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="mx-2 my-4">
          <CCol md="12">
            <p className="heading">Azure Virtual Machine</p>
          </CCol>
        </CRow>
        <AzureVM
          AVMObject={AVMObject}
          onAVMChange={onAVMChange}
          mandatoryDict={mandatoryDict}
          assignmentGroupsArray={
            assignmentGroups?.data
              ? assignmentGroups?.data["Assigment Groups"]?.map(
                  (obj) => obj.name
                ) || []
              : []
          }
        />
        <CRow className="mx-2 mt-3">
          <CCol md="12">
            <p className="heading">Provision Zone</p>
          </CCol>
        </CRow>
        <ProvisionZone
          provZonebject={provZonebject}
          onProvZoneChange={onProvZoneChange}
        />
        <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>
    </div>
  )
}
