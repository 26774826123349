import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
  CInputCheckbox
} from "@coreui/react"
import downArrow from "assets/icons/downArrow.svg"
import PropTypes from "prop-types"
import React from "react"

function MultiSelectDropdown({
  items,
  selectedKeys,
  onChange,
  defaultText,
  emptyText,
  className,
  buttonClassName,
  showArrow = true
}) {
  const labelstyle = {
    width: "100%",
    cursor: "pointer"
  };
  const handleChange = (itemKey,event) => {
    event.stopPropagation()
    event.nativeEvent.stopImmediatePropagation()
    event.preventDefault()
    onChange(
      selectedKeys.includes(itemKey)
        ? selectedKeys.filter((key) => key !== itemKey)
        : [...selectedKeys, itemKey]
    )
  }

  return (
    <CDropdown className={className}>
      <CDropdownToggle
        className={`bg-white text-dark d-flex align-items-center justify-content-between  ${buttonClassName}`}
        caret
      >
        {!selectedKeys.length ? emptyText : defaultText(selectedKeys.length)}
        {showArrow && (
          <img src={downArrow} alt="down arrow" className="iconDropdownStyle" />
        )}
      </CDropdownToggle>
      <CDropdownMenu className="dropdown-menu">
        {items.map((item) => (
          <CDropdownItem
            key={item.key}
            disabled={item.disabled}
            className="pl-4 d-flex align-items-baseline"
          >
            <CInputCheckbox
              checked={selectedKeys.includes(item.key)}
              disabled={item.disabled}
              readOnly
              onClick={(e)=>e.stopPropagation()}
              onChange={(e) => {handleChange(item.key,e)}
               }
            />
           <label style={labelstyle} onClick={(e) => { handleChange(item.key,e)}}>{item.text}</label> 
          </CDropdownItem>
        ))}
      </CDropdownMenu>
    </CDropdown>
  )
}

MultiSelectDropdown.defaultProps = {
  items: [],
  selectedkeys: [],
  onChange: () => {},
  emptyText: "",
  defaultText: (count) => `${count} item(s) selected`,
  className: "",
  buttonClassName: ""
}

MultiSelectDropdown.propTypes = {
  items: PropTypes.array,
  selectedKeys: PropTypes.array,
  emptyText: PropTypes.string,
  defaultText: PropTypes.func,
  onChange: PropTypes.func,
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  showArrow: PropTypes.bool
}

export default MultiSelectDropdown
