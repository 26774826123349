export const msalConfig = {
    demo: {
        auth: {
          clientId: "833ff998-8f19-45a8-893d-adc42ea7d9f2",
          authority: "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
          redirectUri: `${window.location.pathname}login/callback`
        },
        cache: {
          cacheLocation: "sessionStorage", // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
          claimsBasedCachingEnabled: true
        }
      },
    staging: {
        auth: {
            clientId: "833ff998-8f19-45a8-893d-adc42ea7d9f2",
            authority: "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
            redirectUri: `${window.location.pathname}login/callback`
          },
          cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            claimsBasedCachingEnabled: true
          }
    },
    prod: {
        auth: {
            clientId: "ec883f08-a615-4dc4-a369-f9457b9abed7",
            authority: "https://login.microsoftonline.com/36da45f1-dd2c-4d1f-af13-5abe46b99921",
            redirectUri: `${window.location.pathname}login/callback`
          },
          cache: {
            cacheLocation: "sessionStorage", // This configures where your cache will be stored
            storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
            claimsBasedCachingEnabled: true
          }
    }
};