import classNames from "classnames"
import PropTypes from "prop-types"
import React from "react"

export default function Message({ message = "Loading...", blink = false }) {
  const messageClasses = classNames(
    ["p-0", "m-0", "h4", "text-center", "animated", "fadeIn"],
    { "blink-slow": blink }
  )
  const containerClasses = classNames([
    "w-100",
    "h-100",
    "d-flex",
    "justify-content-center",
    "align-items-center"
  ])
  return (
    <div className={containerClasses}>
      <p className={messageClasses}>{message}</p>
    </div>
  )
}
Message.propTypes = {
  message: PropTypes.string,
  blink: PropTypes.bool
}
