/** @jsx jsx */
import { CCard, CCardHeader, CRow, CSpinner } from "@coreui/react"
import { css, jsx } from "@emotion/react"
import classNames from "classnames"
import PropTypes from "prop-types"

const useStyles = (args) => {
  return {
    card: css`
      min-height: ${args.minHeight};
    `
  }
}

export default function SpinnerCard({
  title,
  noHeader = false,
  minHeight = 0
}) {
  const styleProps = { minHeight }
  const classes = useStyles(styleProps)
  const cardClasses = classNames("h-100", "w-100")
  return (
    <CCard className={cardClasses} css={classes.card}>
      {noHeader || !title ? (
        ""
      ) : (
        <CCardHeader>
          <h2 className="h6 m-0 p-0 font-weight-bold">{title}</h2>
        </CCardHeader>
      )}
      <CRow className="d-flex justify-content-center align-items-center h-100">
        <CSpinner
          className="m-4"
          style={{ width: "4rem", height: "4rem" }}
          color="secondary"
        />
      </CRow>
    </CCard>
  )
}
SpinnerCard.propTypes = {
  title: PropTypes.string,
  noHeader: PropTypes.bool,
  minHeight: PropTypes.number
}
