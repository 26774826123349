import { CCol, CInput, CRow } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import useNotifications from "components/common/customHooks/useNotifications"
import { DatePickerInput, OptionDropdown } from "components/common/dropdown"
import { LoadingSpinnerOverlay } from "components/common/loading"
import {
  getAssignmentGroups,
  postRequestGcpVirtualMachine
} from "extensions/usoc/api/service"
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router"
import useSWR from "swr"
import { toStandardDateString, useExtension } from "utils"
import getDropdownItems from "utils/array/getDropdownItems"
import { useUserAuth } from "utils/hooks"

import {
  booleanOptions,
  locationOptions,
  scheduleProfileValues,
  scheduleTimeZoneOptions
} from "./constants"
import FormActions from "./FormActions"

export default function RequestGCPVm() {
  const auth = useUserAuth()
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const history = useHistory()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const userEmail = useSelector((state) => {
    return state.user.email
  })

  const [cloudAccount, setCloudAccount] = useState("GCP Virtual Machine")
  const [location, setLocation] = useState(locationOptions[0])
  const [stackName, setStackName] = useState("")
  const [userGroup, setUserGroup] = useState("")
  const [resourceCustomVm, setResourceCustomVm] = useState("custom-vm")
  const [region, setRegion] = useState("us-east1")
  const [zone, setZone] = useState("us-east1-b")
  const [vmType, setVmType] = useState("f1-micro")
  const [scheduleProfile, setScheduleProfile] = useState(
    scheduleProfileValues[0]
  )
  const [scheduleTimeZone, setScheduleTimeZone] = useState(
    scheduleTimeZoneOptions[0].key
  )
  const [existingWorkspace, setExistingWorkspace] = useState("No")
  const [infuseManagementKey, setInfuseManagementKey] = useState("No")
  const [application, setApplication] = useState("")
  const [costCenter, setCostCenter] = useState("")
  const [businessService, setBusinessService] = useState("")
  const [workspace, setWorkspace] = useState("")
  const [leaseEndDate, setLeaseEndDate] = useState(new Date())
  const [notifications, setNotifications] = useNotifications()

  const [isCloudAccountTouched, setIsCloudAccountTouched] = useState(false)
  const [isLocationTouched, setIsLocationTouched] = useState(false)
  const [isStackNameTouched, setIsStackNameTouched] = useState(false)
  const [isUserGroupTouched, setIsUserGroupTouched] = useState(false)
  const [isScheduleProfileTouched, setIsScheduleProfileTouched] =
    useState(false)
  const [isScheduleTimeZoneTouched, setIsScheduleTimeZoneTouched] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const emailParams = `email=${userEmail}`

  const { data: assignmentGroups } = useSWR(
    () => (userEmail ? `/itsm/get_assignment_groups?${emailParams}` : null),
    () => getAssignmentGroups({ auth, host }, emailParams),
    { suspense: true }
  )

  useEffect(() => {
    if (
      assignmentGroups?.data &&
      Array.isArray(assignmentGroups?.data["Assigment Groups"]) &&
      assignmentGroups?.data["Assigment Groups"].length &&
      !userGroup
    ) {
      setUserGroup(assignmentGroups?.data["Assigment Groups"][0].name)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentGroups])

  const enqueueToast = useContext(ToasterProvider)

  function setAllTouchedValues(value) {
    setIsScheduleProfileTouched(value)
    setIsCloudAccountTouched(value)
    setIsLocationTouched(value)
    setIsStackNameTouched(value)
    setIsUserGroupTouched(value)
    setIsScheduleTimeZoneTouched(value)
  }

  function setAllFieldsToInitialValue() {
    setCloudAccount("GCP Virtual Machine")
    setLocation(locationOptions[0])
    setStackName("")
    setUserGroup("")
    setResourceCustomVm("custom-vm")
    setRegion("us-east1")
    setZone("us-east1-b")
    setVmType("f1-micro")
    setScheduleProfile(scheduleTimeZoneOptions[0].key)
    setScheduleTimeZone("No")
    setExistingWorkspace("No")
    setInfuseManagementKey("")
    setApplication("")
    setCostCenter("")
    setBusinessService("")
    setWorkspace("")
    setLeaseEndDate(new Date())
  }

  const handleSubmit = async () => {
    setAllTouchedValues(true)
    if (
      !scheduleProfile ||
      scheduleProfile === "--No Schedule--" ||
      scheduleTimeZone === scheduleTimeZoneOptions[0].key ||
      !location ||
      !stackName ||
      !userGroup ||
      scheduleTimeZone === "--None--"
    ) {
      return
    }
    setIsLoading(true)
    const requestGcpVmBody = {
      CloudAccount: cloudAccount,
      Location: location,
      ScheduleProfile: scheduleProfile,
      ScheduleTimeZone: scheduleTimeZone,
      GCP_Virtual_Machine1_customvm_region: region,
      GCP_Virtual_Machine1_customvm_zone: zone,
      GCP_Virtual_Machine1_customvm_vmType: vmType,
      UserGroup: userGroup,
      StackName: stackName,
      GCP_Virtual_Machine1_Resource_customvm: resourceCustomVm,
      InfuseManagementKey: infuseManagementKey,
      UseExistingWorkspace: existingWorkspace,
      Workspace: workspace,
      Application: application,
      CostCenter: costCenter,
      LeaseEndDate: toStandardDateString(leaseEndDate),
      BusinessService: businessService
    }
    const response = await postRequestGcpVirtualMachine(
      { auth, host, body: JSON.stringify(requestGcpVmBody) },
      projectID
    )
    if (
      !response.error &&
      response.data &&
      response.data.startsWith("Submitted")
    ) {
      try {
        let responseData = response.data.split("{")[1].split("}")[0]
        responseData = JSON.parse(`{${responseData}}`)
        enqueueToast(
          "success",
          null,
          `GCP virtual machine requested successfully${
            responseData.number ? ` with number ${responseData.number}` : ""
          }`
        )
        const currentDate = new Date()
        const notification = {
          id: notifications.length + 1,
          from: "Created Successfully!!",
          active: true,
          approved: true,
          ticket_number: responseData.number,
          time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
          description:
            "GCP virtual machine requested successfully with number ",
          requestNumber: ["ServiceRequest", responseData.number]
        }
        setNotifications([notification, ...notifications])
      } catch {
        enqueueToast(
          "success",
          null,
          "GCP virtual machine requested successfully"
        )
      }
      setAllFieldsToInitialValue()
      setAllTouchedValues(false)
      history.goBack()
    } else {
      enqueueToast("failure", null, "Could not request GCP virtual machine")
      history.goBack()
    }
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  const renderGCPVM = () => (
    <>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Cloud Account *</p>
          <OptionDropdown
            items={[
              { text: "GCP Virtual Machine", key: "GCP Virtual Machine" }
            ]}
            value={cloudAccount}
            onChange={(val) => {
              setIsCloudAccountTouched(true)
              setCloudAccount(val)
            }}
            className="w-100"
            buttonClassName={classNames(
              { "is-invalid": isCloudAccountTouched && !cloudAccount },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Application</p>
          <CInput
            type="text"
            name="application"
            defaultValue={application}
            onBlur={(e) => setApplication(e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Location *</p>
          <OptionDropdown
            items={getDropdownItems(locationOptions)}
            value={location}
            onChange={(val) => {
              setIsLocationTouched(true)
              setLocation(val)
            }}
            className="w-100"
            buttonClassName={classNames(
              { "is-invalid": isLocationTouched && !location },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Cost Center</p>
          <CInput
            type="text"
            name="costCenter"
            defaultValue={costCenter}
            onBlur={(e) => setCostCenter(e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Stack Name *</p>
          <CInput
            type="text"
            name="stackName"
            defaultValue={stackName}
            onBlur={(e) => {
              setIsStackNameTouched(true)
              setStackName(e.target.value)
            }}
            className={classNames({
              "is-invalid": isStackNameTouched && !stackName
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Business Service</p>
          <CInput
            type="text"
            name="projectName"
            defaultValue={businessService}
            onBlur={(e) => setBusinessService(e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">User Group *</p>
          <OptionDropdown
            items={getDropdownItems(
              assignmentGroups?.data
                ? assignmentGroups?.data["Assigment Groups"]?.map(
                    (obj) => obj.name
                  ) || []
                : []
            )}
            value={userGroup}
            onChange={(val) => {
              setIsUserGroupTouched(true)
              setUserGroup(val)
            }}
            className="w-100"
            buttonClassName={classNames(
              { "is-invalid": isUserGroupTouched && !userGroup },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Use Existing Workspace</p>
          <OptionDropdown
            items={booleanOptions}
            value={existingWorkspace}
            onChange={setExistingWorkspace}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Schedule Profile *</p>
          <OptionDropdown
            items={getDropdownItems(scheduleProfileValues)}
            value={scheduleProfile}
            onChange={(val) => {
              setIsScheduleProfileTouched(true)
              setScheduleProfile(val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  isScheduleProfileTouched &&
                  scheduleProfile === scheduleProfileValues[0]
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Workspace</p>
          <CInput
            type="text"
            name="workspace"
            defaultValue={workspace}
            onBlur={(e) => setWorkspace(e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Schedule Time Zone *</p>
          <OptionDropdown
            items={scheduleTimeZoneOptions}
            value={scheduleTimeZone}
            onChange={(val) => {
              setIsScheduleTimeZoneTouched(true)
              setScheduleTimeZone(val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  isScheduleTimeZoneTouched &&
                  scheduleTimeZone === scheduleTimeZoneOptions[0].key
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Lease End Date</p>
          <DatePickerInput
            className="w-100"
            buttonClassName="w-100"
            value={leaseEndDate}
            onChange={setLeaseEndDate}
            range={false}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Infuse Management Key</p>
          <OptionDropdown
            items={booleanOptions}
            value={infuseManagementKey}
            onChange={setInfuseManagementKey}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-3">
        <CCol md="12">
          <p className="heading">Provision Zone</p>
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">GCP Virtual Machine 1 Resource CustomVM</p>
          <CInput
            type="text"
            name="resourceCustomVm"
            defaultValue={resourceCustomVm}
            onBlur={(e) => setResourceCustomVm(e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">GCP Virtual Machine 1 CustomVM Zone</p>
          <CInput
            type="text"
            name="zone"
            defaultValue={zone}
            onBlur={(e) => setZone(e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">GCP Virtual Machine 1 CustomVM Region</p>
          <CInput
            type="text"
            name="region"
            defaultValue={region}
            onBlur={(e) => setRegion(e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">GCP Virtual Machine 1 CustomVM Type</p>
          <CInput
            type="text"
            name="vmType"
            defaultValue={vmType}
            onBlur={(e) => setVmType(e.target.value)}
          />
        </CCol>
      </CRow>
    </>
  )

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="mx-2 my-4">
          <CCol md="12">
            <p className="heading">GCP Virtual Machine</p>
          </CCol>
        </CRow>
        {renderGCPVM()}
        <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>
    </div>
  )
}
