import { CContainer, CCol, CInput, CRow, CTextarea } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import { LoadingSpinnerOverlay } from "components/common/loading"
import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useExtension } from "utils"
import wbsCodeValidation from "utils/validate/validateWBS"
import { useUserAuth } from "utils/hooks"
import Joi from "joi"
import Select from "react-select"
import CESOPRequestSuccess from "./components/CESOPRequestSuccess"
import { Tooltip } from "components/common/tooltip"
import FormActions from "./FormActions"
import { postRequestCESOPAccount } from "extensions/usoc/api/service"


const formInputConfig = {
  clientName: {
    name: "clientName",
    label: "Client Name",
    type: "text",
    placeholder: "Name of the client",
    tooltip: "Please enter the client name"
  },
  dataClassification: {
    name: "dataClassification",
    label: "Data Classification",
    type: "select",
    placeholder: "Select data classification",
    tooltip: "The classification for the data regarding its level of confidentiality.",
    options: [
      { label: "High Risk Confidential", value: "High Risk Confidential", key: "1" },
      { label: "Protected Health Confidential", value: "Protected Health Confidential", key: "2" },
      { label: "Confidential", value: "Confidential", key: "3" }
    ]
  },
  wbsCode: {
    name: "wbsCode",
    label: "WBS Code",
    type: "text",
    placeholder: "WBS code for the client account",
    tooltip: "Please enter the WBS code for the client account."
  },
  dataOwnership: {
    name: "dataOwnership",
    label: "Data Ownership",
    type: "select",
    placeholder: "Select data ownership",
    tooltip: "The responsible owner of the data.",
    options: [
      { label: "Client", value: "Client", key: "1" },
      { label: "Deloitte", value: "Deloitte", key: "2" }
    ]
  },
  primaryRegion: {
    name: "primaryRegion",
    label: "Primary Region",
    type: "select",
    placeholder: "Primary region for the account",
    tooltip: "Where will the data reside?",
    options: [
      { label: "Ireland (eu-west-1)", value: "eu-west-1", key: "1" },
      { label: "London (eu-west-2)", value: "eu-west-2", key: "2" },
      { label: "Frankfurt (eu-central-1)", value: "eu-central-1", key: "3" },
      { label: "Zurich (eu-central-2)", value: "eu-central-2", key: "4" }
    ]
  },
  provisionedUsers: {
    name: "provisionedUsers",
    label: "Provisioned Users",
    type: "select",
    placeholder: "Select provisioned users",
    tooltip: "Which group of users should be granted access to the new project?",
    options: [
      { label: "Project Admins", value: "Project-Admins", key: "1" },
      { label: "Project Users", value: "Project-Users", key: "2" }
    ]
  },
  additionalInformation: {
    name: "additionalInformation",
    label: "Additional Information",
    placeholder: "If you have any specific requirements for the account, please specify them here.",
    tooltip: "Anything special that needs to be known about this project?"
  }
}


const inputSchema = Joi.object({
  clientName: Joi.string()
    .label('Client Name')
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field"
    }),

  dataClassification: Joi.string()
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field",
      "string.pattern": "Not a valid WBS code pattern"
    }),
  wbsCode: Joi.string()
    .label('WBS Code')
    .required()
    .custom(wbsCodeValidation)
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field",
      "string.pattern": "Not a valid WBS code pattern"
    }),
  dataOwnership: Joi.string()
    .label('Data Ownership')
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field"
    }),
  primaryRegion: Joi.string()
    .label('Primary Region')
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field"
    }),
  provisionedUsers: Joi.string()
    .label('Provisioned Users')
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field"
    }),
  additionalInformation: Joi.string()
    .label('Additional Information')
    .required()
    .messages({
      "any.required": "Cannot be an empty field",
      "string.empty": "Cannot be an empty field"
    })

});

const validateField = (field, value) => {
  return inputSchema.extract(field).validate(value, { abortEarly: false });
}


export default function RequestP000441ServiceForm() {

  const auth = useUserAuth();
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })
  const history = useHistory()
  const enqueueToast = useContext(ToasterProvider);

  const [clientName, setClientName] = useState("");
  const [clientNameError, setClientNameError] = useState("");

  const [dataClassification, setDataClassification] = useState("");
  const [dataClassificationError, setDataClassificationError] = useState("");

  const [wbsCode, setWbsCode] = useState("");
  const [wbsCodeError, setWbsCodeError] = useState("");

  const [dataOwnership, setDataOwnership] = useState("");
  const [dataOwnershipError, setDataOwnershipError] = useState("");

  const [primaryRegion, setPrimaryRegion] = useState("");
  const [primaryRegionError, setPrimaryRegionError] = useState("");

  const [provisionedUsers, setProvisionedUsers] = useState("");
  const [provisionedUsersError, setProvisionedUsersError] = useState("");

  const [additionalInformation, setAdditionalInformation] = useState("");
  const [additionalInformationError, setAdditionalInformationError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [requestNumber, setRequestNumber] = useState("")

  const resetFormInput = () => {
    setClientName("");
    setDataClassification("");
    setWbsCode("");
    setDataOwnership("");
    setPrimaryRegion("");
    setProvisionedUsers("");
    setAdditionalInformation("");

  }

  const resetFormError = () => {
    setClientNameError("");
    setDataClassificationError("");
    setWbsCodeError("");
    setDataOwnershipError("");
    setPrimaryRegionError("");
    setProvisionedUsersError("");
    setAdditionalInformationError("");
  }

  const setFormError = (field, error) => {
    switch (field) {
      case "clientName":
        setClientNameError(error);
        break;
      case "dataClassification":
        setDataClassificationError(error);
        break;
      case "wbsCode":
        setWbsCodeError(error);
        break;
      case "dataOwnership":
        setDataOwnershipError(error);
        break;
      case "primaryRegion":
        setPrimaryRegionError(error);
        break;
      case "provisionedUsers":
        setProvisionedUsersError(error);
        break;
      case "additionalInformation":
        setAdditionalInformationError(error);
        break;
      default:
        break;
    }
  }

  const handleClientNameChange = (e) => {
    setClientName(e.target.value);
    const { error } = validateField('clientName', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setClientNameError(eachError.message);
      });
    } else {
      setClientNameError("");
    }
  }

  const handleDataClassificationChange = (item) => {
    setDataClassification(item.value);
    const { error } = validateField('dataClassification', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setDataClassificationError(eachError.message);
      });
    } else {
      setDataClassificationError("");
    }
  }

  const handleWbsCodeChange = (e) => {
    setWbsCode(e.target.value);
    const { error } = validateField('wbsCode', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setWbsCodeError(eachError.message);
      });
    } else {
      setWbsCodeError("");
    }
  }

  const handleDataOwnershipChange = (item) => {
    setDataOwnership(item.value);
    const { error } = validateField('dataOwnership', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setDataOwnershipError(eachError.message);
      });
    } else {
      setDataOwnershipError("");
    }
  }

  const handlePrimaryRegionChange = (item) => {
    setPrimaryRegion(item.value);
    const { error } = validateField('primaryRegion', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setPrimaryRegionError(eachError.message);
      });
    } else {
      setPrimaryRegionError("");
    }
  }

  const handleProvisionedUsersChange = (item) => {
    setProvisionedUsers(item.value);
    const { error } = validateField('provisionedUsers', item.value);
    if (error) {
      error.details.forEach((eachError) => {
        setProvisionedUsersError(eachError.message);
      });
    } else {
      setProvisionedUsersError("");
    }
  }

  const handleAdditionalInformationChange = (e) => {
    setAdditionalInformation(e.target.value);
    const { error } = validateField('additionalInformation', e.target.value);
    if (error) {
      error.details.forEach((eachError) => {
        setAdditionalInformationError(eachError.message);
      });
    } else {
      setAdditionalInformationError("");
    }
  }

  const handleFormSubmit = async () => {
    resetFormError();

    const { error } = await inputSchema.validate({
      clientName: clientName,
      dataClassification: dataClassification,
      wbsCode: wbsCode,
      dataOwnership: dataOwnership,
      primaryRegion: primaryRegion,
      provisionedUsers: provisionedUsers,
      additionalInformation: additionalInformation
    }, { abortEarly: false });

    if (error) {
      error.details.forEach((eachError) => {
        setFormError(eachError.path?.[0], eachError.message);
      })
    } else {
      setIsLoading(true);
      const requestBody = {
        client_name: clientName,
        data_classification: dataClassification,
        wbs_code: wbsCode,
        data_ownership: dataOwnership,
        primary_region: primaryRegion,
        provisioned_users: provisionedUsers,
        additional_information: additionalInformation
      }
      const response = await postRequestCESOPAccount(
          {
            auth,
            host,
            body: JSON.stringify(requestBody)
          },
          projectID
      )

      if (response.error) {
        enqueueToast(
          "failure",
          "New Customer Environment Request Failed",
          "Something went wrong... please try again later"
        )
      } else {
        setRequestNumber(response.data.ticket_number);
        setFormSubmitted(true);
        enqueueToast(
          "success",
          "New Customer Environment Request Successful",
          `Request Id: ${response.data.ticket_number}`
        )
      }
      setIsLoading(false)
    }

  }

  const handleFormCancel = () => {
    resetFormInput();
    resetFormError();
    history.go(-1);
  }

  if (formSubmitted) {
    return (
      <CESOPRequestSuccess requestNumber={requestNumber} />
    )
  }

  return (
    <CContainer className="service-request-container py-2">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="heading py-2 text-center">
              Request new customer environment
            </p>
          </CCol>
        </CRow>
        <CRow className="m-2 justify-content-center">
          <CCol lg={6} md={8} sm={12}>
            <p className="sub-heading">
              Use this catalog item to request a standard customer environment under a new, dedicated Project ID.
            </p>
          </CCol>
        </CRow>

        <CContainer className='m-2'>
          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.clientName.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.clientName.tooltip}
                />
              </p>
              <CInput
                type={formInputConfig.clientName.type}
                name={formInputConfig.clientName.name}
                placeholder={formInputConfig.clientName.placeholder}
                onChange={handleClientNameChange}
                className={classNames({ "is-invalid": (clientNameError) })}
              />
              <div className="text-danger pt-2"><small>{clientNameError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.dataClassification.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.dataClassification.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.dataClassification.placeholder}
                options={formInputConfig.dataClassification.options}
                isSearchable={true}
                name={formInputConfig.dataClassification.name}
                onChange={handleDataClassificationChange}
              />
              <div className="text-danger pt-2"><small>{dataClassificationError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.wbsCode.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.wbsCode.tooltip}
                />
              </p>
              <CInput
                type={formInputConfig.wbsCode.type}
                name={formInputConfig.wbsCode.name}
                placeholder={formInputConfig.wbsCode.placeholder}
                onChange={handleWbsCodeChange}
                className={classNames({ "is-invalid": (wbsCodeError) })}
              />
              <div className="text-danger pt-2"><small>{wbsCodeError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.dataOwnership.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.dataOwnership.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.dataOwnership.placeholder}
                options={formInputConfig.dataOwnership.options}
                isSearchable={true}
                name={formInputConfig.dataOwnership.name}
                onChange={handleDataOwnershipChange}
              />
              <div className="text-danger pt-2"><small>{dataOwnershipError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.primaryRegion.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.primaryRegion.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.primaryRegion.placeholder}
                options={formInputConfig.primaryRegion.options}
                isSearchable={true}
                name={formInputConfig.primaryRegion.name}
                onChange={handlePrimaryRegionChange}
              />
              <div className="text-danger pt-2"><small>{primaryRegionError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.provisionedUsers.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.provisionedUsers.tooltip}
                />
              </p>
              <Select
                placeholder={formInputConfig.provisionedUsers.placeholder}
                options={formInputConfig.provisionedUsers.options}
                isSearchable={true}
                name={formInputConfig.provisionedUsers.name}
                onChange={handleProvisionedUsersChange}
              />
              <div className="text-danger pt-2"><small>{provisionedUsersError}</small></div>
            </CCol>
          </CRow>

          <CRow className='py-2 justify-content-center'>
            <CCol lg={6} md={8} sm={12}>
              <p className="field-label font-weight-bold">
                {formInputConfig.additionalInformation.label}
                {" "}
                <Tooltip
                  className="form-tooltip-icon"
                  text={formInputConfig.additionalInformation.tooltip}
                />
              </p>
              <CTextarea
                style={{ height: "auto" }}
                rows={6}
                type={formInputConfig.additionalInformation.type}
                name={formInputConfig.additionalInformation.name}
                placeholder={formInputConfig.additionalInformation.placeholder}
                onChange={handleAdditionalInformationChange}
                className={classNames({ "is-invalid": (additionalInformationError) })}
              />

              <CRow className='pr-4 pt-2'>
                <CCol md={11}>
                  <div className="text-danger"><small>{additionalInformationError}</small></div>
                </CCol>
              </CRow>

            </CCol>
          </CRow>

        </CContainer>

        <CRow className="justify-content-center m-4">
          <CCol md={{ span: 6, offset: 2 }} className="mr-n5">
            <FormActions onSubmit={handleFormSubmit} onCancel={handleFormCancel} />
          </CCol>
        </CRow>
      </LoadingSpinnerOverlay>
    </CContainer>
  )
}
