import "./tooltip.scss"

import { CButton } from "@coreui/react"
import Info from "assets/icons/info.svg"
import PropTypes from "prop-types"
import React from "react"

export default function Tooltip({
  className = "",
  text,
  heading,
  containerClass = "",
  disabled,
  trigger = "focus",
  children,
  zIndex
}) {
  const disable = disabled ? "disabledTooltip" : ""
  if (trigger === "hover") {
    return (
      <div className={`${className} custom-tooltip custom-tooltip-hover`}>
        {children}
        <div
          className={`${containerClass} ${disable} tooltip-container p-3`}
          style={{ zIndex: zIndex || "" }}
        >
          {heading && (
            <p className="font-weight-bold text-left mb-1">{heading}</p>
          )}
          <div className="content-body">{text}</div>
        </div>
      </div>
    )
  }
  return (
    <CButton className={`${className} custom-tooltip p-0 m-0 hide-element`}>
      <img src={Info} alt="info" className="tooltip-info-icon" />
      <div className={`${containerClass} tooltip-container bg-white p-3`}>
        {heading && (
          <p className="font-weight-bold text-left mb-1">{heading}</p>
        )}
        <div className="content-body">{text}</div>
      </div>
    </CButton>
  )
}
Tooltip.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node
  ]),
  heading: PropTypes.string,
  containerClass: PropTypes.string,
  disabled: PropTypes.bool,
  trigger: PropTypes.string,
  children: PropTypes.node,
  zIndex: PropTypes.number
}
