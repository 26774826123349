import { CCol, CInputFile } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"
import { FaTimes } from "react-icons/fa"
import { checkIfFileSupported } from "utils"

const FileAttachment = ({
  uploadFile,
  isUploadFileTouched,
  setUploadFile,
  setIsUploadFileTouched,
  heading,
  fileInputRef,
  required
}) => {
  const removeFiles = (idx) => {
    const filteredData = uploadFile.filter(
      (data) =>
        data.name !== idx.name ||
        data.size !== idx.size ||
        data.type !== idx.type
    )
    setUploadFile(filteredData)
  }
  return (
    <>
      <CCol md="12">
        <p className="field-label font-weight-bold mb-1">
          {heading || "Attachments *"}
        </p>
        <p className="field-label">
          Supported Format: png, eml, pdf, msg, jpeg or jpg.
        </p>
        <CInputFile
          innerRef={fileInputRef}
          accept=".jpeg,.png,.pdf,.eml,.msg,.jpg"
          onInput={(event) => {
            const uploadFileCopy = [...uploadFile]
            setUploadFile(
              Object.values(event.target.files).reduce((acc, file) => {
                if (!acc.find((accFile) => accFile.name === file.name)) {
                  return acc.concat(file)
                }
                return acc
              }, uploadFileCopy)
            )
            setIsUploadFileTouched(true)
          }}
          multiple
          style={{ display: "none" }}
          className={`${
            ((uploadFile && !checkIfFileSupported(uploadFile)) ||
            (isUploadFileTouched &&required&& uploadFile.length === 0))
              ? "is-invalid"
              : ""
          } `}
        />
        {uploadFile && !checkIfFileSupported(uploadFile) && (
          <div className="invalid-feedback">
            This Field only supports png, eml, pdf, msg, jpeg or jpg
          </div>
        )}
        {isUploadFileTouched &&required&& uploadFile.length === 0 && (
          <div className="invalid-feedback">This field is required</div>
        )}
      </CCol>
      <button
        type="button"
        className="btn-removeFiles mx-3 mt-3"
        onClick={() => {
          fileInputRef.current.click()
        }}
      >
        Choose Files
      </button>
      <CCol md="12" className="chip-container my-2">
        {uploadFile.length > 0 && (
          <>
            {uploadFile.map((data, index) => (
              <div
                key={index}
                className="chip-style d-flex align-items-center position-relative m-2"
              >
                <p>{data.name}</p>
                <FaTimes
                  size="15px"
                  className="ml-1 cursor-pointer"
                  onClick={() => removeFiles(data)}
                />
              </div>
            ))}
          </>
        )}
      </CCol>
    </>
  )
}
export default FileAttachment
FileAttachment.propTypes = {
  uploadFile: PropTypes.array,
  isUploadFileTouched: PropTypes.bool,
  setUploadFile: PropTypes.func,
  setIsUploadFileTouched: PropTypes.func,
  heading: PropTypes.string,
  fileInputRef: PropTypes.object,
  required:PropTypes.bool
}
