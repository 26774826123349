import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, project , incidentId) => {
    return `${host}/itsm/${project}/${incidentId}/comment`
  },
  checkRequiredArguments: (commentData) => {
    if (commentData === undefined) throw new Error("No comment data supplied.")
  },
  makeResponseData: (json) => {
    return json
  },
  returnRawResponse: true
}
export default createFetcher(fetcherConfiguration)
