import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

function getSavedValue (key, value) {
  const savedValue = JSON.parse(localStorage.getItem(key))
  if (savedValue) return savedValue

  if (value instanceof Function) return value()
  return value
}

export default function useNotifications () {
  const initialValue = []

  const userEmail = useSelector((state) => {
    return state.user.email
  })

  const [notifications, setNotifications] = useState(() => {
    return getSavedValue(userEmail, initialValue)
  })

  useEffect(() => {
    if (userEmail) { localStorage.setItem(userEmail, JSON.stringify(notifications)) }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications])

  useEffect(() => {
    if (userEmail) {
      const notification = getSavedValue(userEmail, initialValue)
      setNotifications(notification)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userEmail])

  return [notifications, setNotifications]
}
