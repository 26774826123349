import WentWrongIcon from "assets/icons/went_wrong.svg"
import PropTypes from "prop-types"
import React from "react"

function WentWrong ({ header, text, className, imageClassName }) {
  return (
    <div
      className={`${className} d-flex flex-column align-items-center justify-content-center flex-fill`}
    >
      <img
        src={WentWrongIcon}
        alt="went-wrong-icon"
        className={`${imageClassName} mb-1 went-wrong-img`}
      />
      <p className="mb-1 font-weight-bold medium-text went-wrong-color text-center">
        {header}
      </p>
      <p className="went-wrong-color text-center">{text}</p>
    </div>
  )
}

WentWrong.defaultProps = {
  header: "No data available.",
  text: "Currently there is no data to display for this component.",
  className: "",
  imageClassName: ""
}

WentWrong.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string
}

export default WentWrong
