/* eslint-disable */
import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";

import React, { useEffect } from "react"
import ReactDOM from "react-dom"
import { BrowserRouter } from "react-router-dom"
import fetchExtension, { ExtensionContext } from "extensions"
import withStore from "./utils/hocs/withStore"
import { MediaContextProvider } from "components/common/Context/MediaContext"
import { PublicClientApplication, EventType } from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import ChartDataLabels from "chartjs-plugin-datalabels"
import Chart from "chart.js"
import { AccountContextProvider } from "components/common/Context/AccountContext"
import { ToasterContextProvider } from "components/common/Context/ToasterContext"
import { CurrencyContextProvider } from "components/common/Context/CurrencyContext"
import { ThemeContextProvider } from "components/common/Context/ThemeContext"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter5Adapter } from "use-query-params/adapters/react-router-5"
import { msalConfig } from "./authConfig.js";

const appStage = process.env.REACT_APP_STAGE ?? "demo";
const msalInstance = new PublicClientApplication(msalConfig[appStage]);

function Root() {

  const [extension, setExtension] = React.useState(null);

  // Load extensions
  useEffect(() => {
    const fetchLoadedExtension = async () => {
      const _extension = await fetchExtension;
      setExtension(_extension);
    };
    fetchLoadedExtension();
  }, []);

  // Unregister Chart plugins
  useEffect(() => {
    Chart.plugins.unregister(ChartDataLabels);
  }, []);


  if (!extension) {
    return null
  }

  return (
    <MsalProvider instance={msalInstance}>
      <QueryParamProvider adapter={ReactRouter5Adapter}>
        <ThemeContextProvider>
          <MediaContextProvider>
            <ExtensionContext.Provider value={extension}>
              <ToasterContextProvider>
                <AccountContextProvider>
                  <CurrencyContextProvider>
                    <App />
                  </CurrencyContextProvider>
                </AccountContextProvider>
              </ToasterContextProvider>
            </ExtensionContext.Provider>
          </MediaContextProvider>
        </ThemeContextProvider>
      </QueryParamProvider>
    </MsalProvider>
  )
}

msalInstance.initialize().then(() => {
  // Default to using the first account if no account is active on page load
  if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
  }

  // Optional - This will update account state if a user signs in from another tab or window
  msalInstance.enableAccountStorageEvents();

  msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const account = event.payload.account;
      msalInstance.setActiveAccount(account);
    }
  });


  ReactDOM.render(
    React.createElement(
      withStore(
        <BrowserRouter>
          <Root />
        </BrowserRouter>
      )
    ),
    document.getElementById("root")
  );

  // If you want your app to work offline and load faster, you can change
  // unregister() to register() below. Note this comes with some pitfalls.
  // Learn more about service workers: http://bit.ly/CRA-PWA
  serviceWorker.unregister();

});