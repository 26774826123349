/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import classNames from "classnames"
import PropTypes from "prop-types"
// import { createUseStyles } from "react-jss"

const useStyles = ({ zIndex, backgroundColor }) => {
  return {
    overlay: css`
      opacity: 0.95;
      position: fixed;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      z-index: ${zIndex};
      background-color: ${backgroundColor};
    `
  }
}

export default function App({
  zIndex = 1000,
  messageTitle = "",
  messageBody = "",
  danger = false,
  warning = false,
  blink = false,
  overlayColor = "#EFEFEF"
}) {
  const classes = useStyles({ zIndex, backgroundColor: overlayColor })
  // const overlayClasses = classNames(
  //   classes.overlay,
  //   "d-flex",
  //   "flex-column",
  //   "justify-content-center",
  //   "align-items-center"
  // )
  const messageTitleClasses = classNames(classes.message, "h2", {
    "text-danger": danger,
    "text-warning": warning,
    "blink-slow": blink
  })
  const messageBodyClasses = classNames(classes.message, "h4", {
    "text-danger": danger,
    "text-warning": warning,
    "blink-slow": blink
  })
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      css={classes.overlay}
    >
      <h1 className={messageTitleClasses}>{messageTitle}</h1>
      <p className={messageBodyClasses}>{messageBody}</p>
    </div>
  )
}
App.propTypes = {
  zIndex: PropTypes.number,
  messageTitle: PropTypes.string,
  messageBody: PropTypes.string,
  danger: PropTypes.bool,
  warning: PropTypes.bool,
  blink: PropTypes.bool,
  overlayColor: PropTypes.string
}
