import { CButton, CCol, CModal, CModalBody, CRow } from "@coreui/react"
import Close from "assets/icons/modalClose.svg"
import { useTheme } from "components/common/Context/ThemeContext"
import ToasterProvider from "components/common/Context/ToasterContext"
import { ErrorBoundary } from "components/common/error"
import { FileAttachment } from "components/common/forms"
import {
  LoadingSpinnerCard,
  LoadingSpinnerOverlay
} from "components/common/loading"
import { WentWrongCard } from "components/common/noData"
import {
  getIncidentDetails,
  postFileAttachments
} from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useContext, useMemo, useRef, useState } from "react"
import { useSelector } from "react-redux"
import useSWR from "swr"
import {
  checkIfFileSupported,
  getTimeElapsed,
  useExtension,
  useUserAuth
} from "utils"

import Attachments from "./Attachments"
import IncidentTimeline from "./IncidentTimeline"

async function fetchIncidentDetails(auth, extension, projectId, param) {
  const host = extension?.config?.env?.clientApiGateway?.URL
  const results = await getIncidentDetails({ auth, host }, projectId, param)
  return results
}

export default function IncidentModal({
  show = false,
  setShow = () => {},
  incidentNumber
}) {
  const auth = useUserAuth()
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })
  const fileInputRef = useRef(null)
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const enqueueToast = useContext(ToasterProvider)
  const theme = useTheme()

  const [uploadFile, setUploadFile] = useState([])
  const [isUploadFileTouched, setIsUploadFileTouched] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const maskSubmitStyle = {
    backgroundColor: theme.serviceRequestButton,
    borderColor: theme.serviceRequestButton,
    color: "white"
  }

  const param = `&ticket_id=${incidentNumber}`

  const {
    data: incResult,
    isValidating,
    mutate
  } = useSWR(
    () =>
      incidentNumber && projectID
        ? `${incidentNumber}/projects/${projectID}/get_incident_details?${param}`
        : null,
    () => fetchIncidentDetails(auth, extension, projectID, param)
  )

  const incidentData = useMemo(() => {
    return incResult?.data[0]
  }, [incResult])

  const postUploadFile = async (fileDataURL) => {
    const promises = []
    for (const data of fileDataURL.presigned_urls) {
      const options = {
        method: "PUT",
        body: Object.values(uploadFile).find(
          (item) => item.name === data.filename
        ),
        mode: "cors",
        headers: {
          "Content-Type": ""
        }
      }
      promises.push(
        fetch(data.url, options).catch((err) => {
          throw err
        })
      )
    }
    const results = await Promise.all(promises)
    return results
  }
  const handleSubmit = async () => {
    setIsUploadFileTouched(true)
    if (uploadFile.length === 0 || !checkIfFileSupported(uploadFile)) return
    setIsLoading(true)
    const reportAnAttachmentBody = {
      attachments: uploadFile ? uploadFile?.map((data) => data?.name) : []
    }

    const response = await postFileAttachments(
      { auth, host, body: JSON.stringify(reportAnAttachmentBody) },
      projectID,
      incidentData.incident_number
    )
    if (!response.error && response.data) {
      try {
        await postUploadFile(response.data)
        const ticketNumber = response?.data?.ticket_id
        mutate()

        enqueueToast(
          "success",
          null,
          `Additional Attachments submitted Successfully${
            ticketNumber ? ` to the ticket number ${ticketNumber}` : ""
          }`
        )
      } catch (err) {
        setIsLoading(false)
        enqueueToast(
          "failure",
          "failed",
          "Failed to upload the File. Please try again later."
        )
      }
      setIsLoading(false)
    } else {
      setIsLoading(false)
      enqueueToast("failure", null, "Could not upload file attachments")
    }
    setUploadFile([])
    setIsUploadFileTouched(false)
    setIsLoading(false)
    fileInputRef.current.value = null
  }

  return (
    <CModal
      show={show}
      onClose={() => setShow(false)}
      size="xl"
      centered
      className="incident-modal"
    >
      <LoadingSpinnerOverlay
        isLoading={isLoading || isValidating}
        spinnerSize="8rem"
      >
        <CModalBody className="pb-5">
          <img
            src={Close}
            alt="close-icon"
            className="cursor-pointer close-icon"
            onClick={() => setShow(false)}
          />
          <p className="header-text mb-2 font-weight-bold">Incident</p>
          <div className="dotted-border-bottom"></div>
          <CRow className="mx-3 mt-3">
            <CCol md="4">
              <p className="font-weight-bold">Incident No</p>
              <p>{incidentData?.incident_number}</p>
            </CCol>
            <CCol md="4">
              <p className="font-weight-bold">Status</p>
              <p>{incidentData?.incident_state}</p>
            </CCol>
            <CCol md="4">
              <p className="font-weight-bold">Cloud account</p>
              <p className="text-break">{incidentData?.account_name}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3 mx-3">
            <CCol md="4">
              <p className="font-weight-bold">Priority</p>
              <p>{incidentData?.priority}</p>
            </CCol>
            <CCol md="4">
              <p className="font-weight-bold">Case Created</p>
              <p>
                {getTimeElapsed(
                  new Date(incidentData?.created_date.replace(/-/g, "/"))
                )}
              </p>
            </CCol>
            <CCol md="4">
              <p className="font-weight-bold">Created by</p>
              <p>{incidentData?.caller}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3 mx-3">
            <CCol md="12">
              <p className="font-weight-bold">Description</p>
              <p  className="text-break">{incidentData?.short_description}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3 mx-3">
            <CCol md="12">
              <p className="font-weight-bold">Full Description</p>
              <p className="text-break">{incidentData?.full_description}</p>
            </CCol>
          </CRow>
          <CRow className="mt-3 mx-3">
            <CCol md="12">
              <p className="font-weight-bold">Attachments</p>
              {incidentData?.attachments.length === 0 ? (
                <p>None</p>
              ) : (
                incidentData?.attachments?.map((data) => (
                  <Attachments
                    key={`${incidentData?.incident_number}-${data}`}
                    attachmentName={data}
                    ticketNumber={incidentData?.incident_number}
                    projectId={projectID}
                    className="mb-2"
                  />
                ))
              )}
            </CCol>
          </CRow>
          <div className="dotted-border-bottom mt-3"></div>
          <CRow className="mt-3 mx-3">
            <FileAttachment
              uploadFile={uploadFile}
              isUploadFileTouched={isUploadFileTouched}
              setUploadFile={setUploadFile}
              setIsUploadFileTouched={setIsUploadFileTouched}
              heading="Add More Attachments"
              fileInputRef={fileInputRef}
            />
          </CRow>
          {uploadFile.length > 0 && (
            <CRow className="mt-3 mx-3">
              <CButton
                style={maskSubmitStyle}
                onClick={handleSubmit}
                className="ml-3"
              >
                Submit Attachments
              </CButton>
            </CRow>
          )}
          <div className="dotted-border-bottom mt-3"></div>
          <p className="header-text my-2 font-weight-bold text-center">
            Incident Timeline
          </p>
          <ErrorBoundary fallback={<WentWrongCard />}>
            <React.Suspense fallback={<LoadingSpinnerCard />}>
              <IncidentTimeline incidentId={incidentData?.incident_number} />
            </React.Suspense>
          </ErrorBoundary>
        </CModalBody>
      </LoadingSpinnerOverlay>
    </CModal>
  )
}
IncidentModal.propTypes = {
  show: PropTypes.bool,
  setShow: PropTypes.func,
  incidentNumber: PropTypes.string
}
