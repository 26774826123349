import PropTypes from "prop-types"
import React from "react"

const { createContext, useState } = React
const MediaContext = createContext(null)

function MediaContextProvider(props) {
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  return (
    <MediaContext.Provider
      value={{
        isSmallScreen,
        setIsSmallScreen
      }}
    >
      {props.children}
    </MediaContext.Provider>
  )
}

MediaContextProvider.propTypes = {
  children: PropTypes.node
}

export default MediaContext
export { MediaContextProvider }
