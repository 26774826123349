import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host, toCurrency, date, fromCurrency = "USD") => {
    return `${host}/currencies/exchange/${fromCurrency}/${toCurrency}/${date}`
  },
  checkRequiredArguments: (toCurrency, date) => {
    if (toCurrency === "undefined") throw Error("No to currency defined.")
    if (date === "undefined") throw Error("No date specified.")
  },
  makeResponseData: (data) => {
    return data?.fx_rate
  }
}
export default createFetcher(fetcherConfiguration)
