import { CCol } from "@coreui/react"
import classNames from "classnames"
import { get } from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"

export default function IncidentCommonComponent(props) {
  const {
    item,
    tabularValue,
    serviceRequest,
    isDashboardWidget,
    setIsDrawerOpen,
    setDrawerState,
    setSelectedPriorities,
    setSelectedStatus
  } = props

  const history = useHistory()

  const onCLickHandler = () => {
    if (get(tabularValue, item.key, 0) === 0) {
      return
    }
    if (isDashboardWidget) {
      if (serviceRequest) {
        history.push("/service", {
          defaultServiceRequest: true,
          defaultDrawerState: "Open",
          defaultSelectedStatus: [item.text],
          defaultIsDrawerOpen: true
        })
      } else {
        history.push("/service", {
          defaultDrawerState: "Open",
          defaultSelectedPriorities: [item.key],
          defaultIsDrawerOpen: true
        })
      }
    } else {
      if (serviceRequest) {
        setSelectedStatus([item.text])
      } else {
        setSelectedPriorities([item.key])
      }
      setDrawerState("Open")
      setIsDrawerOpen(true)
    }
  }

  const itemContainer = classNames(
    "mt-3 d-flex justify-content-left align-items-center",
    {
      "p-0": !isDashboardWidget,
      "cursor-pointer": get(tabularValue, item.key, 0) !== 0,
      "cursor-not-allowed": get(tabularValue, item.key, 0) === 0
    }
  )

  return (
    <>
      <CCol
        xs={isDashboardWidget && !serviceRequest ? "6" : "4"}
        className={itemContainer}
        onClick={onCLickHandler}
      >
        <div className="mr-2">
          <p
            className="medium-text paragraph-lineheight"
            data-testid={item.text}
          >
            {get(tabularValue, item.key, 0)}
          </p>
          <p className="small-Text mt-1 small-text-height">{item.text}</p>
        </div>
      </CCol>
    </>
  )
}
IncidentCommonComponent.propTypes = {
  item: PropTypes.object,
  tabularValue: PropTypes.object,
  serviceRequest: PropTypes.bool,
  isDashboardWidget: PropTypes.bool,
  setIsDrawerOpen: PropTypes.func,
  setDrawerState: PropTypes.func,
  setSelectedPriorities: PropTypes.func,
  setSelectedStatus: PropTypes.func,
  drawerState: PropTypes.string
}
