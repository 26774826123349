import { InteractionType } from "@azure/msal-browser"
import { useMsal } from "@azure/msal-react"
import { useIsAuthenticated, useMsalAuthentication } from "@azure/msal-react"
import { AppOverlay } from "components/common/overlays"
import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { userLogin } from "store/slices/user"
import { useExtension } from "utils"

export const AuthenticatedMsalSessionMonitor = ({children}) => {
  const { login } = useMsalAuthentication(InteractionType.Redirect)
  const dispatch = useDispatch()
  const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
  const isLoggingIn = useSelector((state) => state.user.isLoggingIn)
  const isMsalAuthenticated = useIsAuthenticated()
  const extension = useExtension()

  const { accounts, instance } = useMsal();

  useEffect(() => {
    const logoutAfterTime = () => {
      sessionStorage.clear()
      login(InteractionType.Redirect, { prompt: "select_account" })
    }

    const logoutTimeout = setTimeout(logoutAfterTime, 1000 * 60 * 60 * 4 )

    return () => {
      clearTimeout(logoutTimeout)
    }
  }, [login])

  useEffect(() => {
    if (!isAuthenticated && isMsalAuthenticated) {
      ;(async () =>
        dispatch(
          userLogin({
            msalInstance: instance,
            msalAccount: accounts[0],
            extension
          })
        ))()
    }
  }, [accounts, dispatch, extension, instance, isAuthenticated, isMsalAuthenticated])


  if (!isMsalAuthenticated) {
    return <AppOverlay messageTitle="Authenticating" messageBody="" blink />
  }

  if (isLoggingIn || !isAuthenticated) {
    return (
      <AppOverlay messageTitle="Fetching Permissions" messageBody="" blink />
    )
  }

  if (isAuthenticated && isMsalAuthenticated) {
    return children
  }

  return null
}

// export const AuthenticatedPortalSessionMontior = ({ children }) => {
//   const { instance, accounts } = useMsal()
//   const dispatch = useDispatch()
//   const isAuthenticated = useSelector((state) => state.user.isAuthenticated)
//   const isLoggingIn = useSelector((state) => state.user.isLoggingIn)

//   const extension = useExtension()

//   useEffect(() => {
//     console.log("fetching permissions")
//     if (!isAuthenticated) {
//       ;(async () =>
//         dispatch(
//           userLogin({
//             msalInstance: instance,
//             msalAccount: accounts[0],
//             extension
//           })
//         ))()
//     }
//   })

//   if (isLoggingIn) {
//     return (
//       <AppOverlay messageTitle="Fetching Permissions.." messageBody="" blink />
//     )
//   }

//   if (isAuthenticated) {
//     return children
//   }

//   return null
// }
