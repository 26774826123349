import { CButton, CCol, CRow } from "@coreui/react"
import { Success } from "components/common/icons"
import PropTypes from "prop-types"
import React from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"

const CenteredText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
`

export default function RequestSuccess({ 
    requestNumber, 
    children
  }) {

  const history = useHistory()

  

  return (
    <CRow className="justify-content-center">
      <CCol md={{ size: 7, offset: 0 }}>
        <Success />
        <CenteredText>
          {children}
        </CenteredText>
        <CenteredText style={{ marginTop: "0.5rem" }}>
          <strong>{`The reference number for this request is: ${requestNumber}`}</strong>
        </CenteredText>
        <CButton
          color="secondary"
          className="mt-3"
          style={{ display: "block", margin: "auto" }}
          onClick={() => {
            history.go("/")
          }}
        >
          Done
        </CButton>
      </CCol>
    </CRow>
  )
}

RequestSuccess.propTypes = {
  requestNumber: PropTypes.string,
  message: PropTypes.string
}
