export default function (amount, symbol = "$", fxRate = 1, delimeter = ",", showDecimal = true, decimal = ".") {
  const _amount = parseFloat(amount * parseFloat(fxRate).toFixed(3))
  if (isNaN(_amount)) return symbol + "0.00"

  const amountRounded2DP = Math.round((_amount + Number.EPSILON) * 100) / 100
  const parts = []
  let decimalPart = Math.round(
    amountRounded2DP * 100 - Math.trunc(amountRounded2DP) * 100
  )
  if (decimalPart < 10) {
    decimalPart = `0${decimalPart}`
  }
  const integerPart = Math.trunc(amountRounded2DP)
  let i = 0
  while (Math.floor(integerPart / Math.pow(10, i)) !== 0) {
    parts.unshift(
      `${Math.trunc(integerPart / Math.pow(10, i)) % 1000}`.padStart(3, "0")
    )
    i += 3
  }
  if (parts.length === 0) parts.unshift(0)
  return (
    symbol + " " +
    parts.join(delimeter).replace(/^0+([1-9]+)|\|+$/g, "$1") +
    (
      showDecimal
        ? (decimal + decimalPart)
        : ""
    )
  )
}
