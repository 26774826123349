import {
  CButton,
  CButtonGroup,
  CContainer,
  CFormGroup,
  CFormText,
  CLabel
} from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"

export default function FeedbackForm({ formData, setFormData, submitForm }) {
  return (
    <CContainer className="mx-auto w-75">
      <form id="feedback-form">
        <CButtonGroup className="w-100 p-3">
          <CButton
            color={formData.type === "Comment" ? "secondary" : "primary"}
            onClick={() =>
              setFormData((formData) => {
                return { ...formData, type: "Comment" }
              })
            }
          >
            Comment
          </CButton>
          <CButton
            color={formData.type === "Request" ? "secondary" : "primary"}
            onClick={() =>
              setFormData((formData) => {
                return { ...formData, type: "Request" }
              })
            }
          >
            Request
          </CButton>
          <CButton
            color={formData.type === "Issue" ? "danger" : "primary"}
            onClick={() =>
              setFormData((formData) => {
                return { ...formData, type: "Issue" }
              })
            }
          >
            Issue
          </CButton>
        </CButtonGroup>
        <CFormGroup className="d-flex flex-column">
          <CLabel htmlFor="ticket-description">Description</CLabel>
          <textarea
            id="feedback-description"
            name="feedback-description"
            type="textarea"
            value={formData.description}
            onChange={(e) => {
              const val = e.target.value
              setFormData((formData) => {
                return { ...formData, description: val }
              })
            }}
            className="form-control"
            onKeyPress={(e) => {
              if (e.charCode === 13) submitForm()
            }}
          ></textarea>
          <CFormText>
            Please enter a description of your {formData.type.toLowerCase()}.
          </CFormText>
        </CFormGroup>
      </form>
    </CContainer>
  )
}
FeedbackForm.propTypes = {
  formData: PropTypes.object,
  setFormData: PropTypes.func,
  submitForm: PropTypes.func
}
