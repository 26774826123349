import React from "react"
import { useSelector } from "react-redux"

export default function (WrappedComponent) {
  return function InnerWrapper (props) {
    const isAuthenticated = useSelector((state) => state.user.isAuthenticated)

    return !isAuthenticated ? null : <WrappedComponent {...props} />
  }
}
