import "./UserSelect.scss"

import {
  CButton,
  CCol,
  CInput,
  CInputGroup,
  CInputGroupAppend,
  CListGroupItem,
  CRow
} from "@coreui/react"
import PropTypes from "prop-types"
import React, { useCallback, useEffect, useRef, useState } from "react"
import useSWR from "swr"
import { useExtension } from "utils"
import { useDebounce, useUserAuth } from "utils/hooks"
import { v4 as uuidv4 } from "uuid"

async function fetcher(url, auth) {
  const headers = new Headers()

  headers.append("Authorization", `Bearer ${auth.token}`)
  headers.append("client_id", auth.email)

  const requestOptions = {
    method: "GET",
    headers: headers
  }

  const response = await fetch(url, requestOptions)

  if (!response.ok) {
    const error = new Error("An error has occurred on user lookup")
    error.info = response.json()
    error.status = response.status
    throw error
  }

  return response.json()
}

const UserSelectItem = ({
  id,
  setState,
  handleRemove,
  limit = "",
  titleFilter = "",
  validationError
}) => {
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const auth = useUserAuth()

  const userItemsRef = useRef(null)
  const [query, setQuery] = useState("")
  const [selection, setSelection] = useState("")
  const [isValid, setIsValid] = useState(false)
  const [showUserList, setShowUserList] = useState(true)
  const [active, setActive] = useState(0)

  const debouncedSearchValue = useDebounce(query, 500)

  function isQueryMinimumLength() {
    return debouncedSearchValue.length > 2
  }

  const { data: users, error } = useSWR(
    isQueryMinimumLength()
      ? `/users/lookup?search_term=${debouncedSearchValue}${
          limit ? "&limit=" + limit : ""
        }${
          titleFilter.length > 0 ? `&title=${titleFilter.join("&title=")}` : ""
        }`
      : null,
    () =>
      fetcher(
        `${host}/users/lookup?search_term=${query}${
          limit ? "&limit=" + limit : ""
        }${
          titleFilter.length > 0 ? `&title=${titleFilter.join("&title=")}` : ""
        }`,
        auth
      )
  )

  useEffect(() => {
    if (!selection) {
      setIsValid(false)
    } else {
      setIsValid(true)
    }
  }, [selection])

  const onSearch = useCallback(
    (e) => {
      if (userItemsRef?.current) {
        userItemsRef.current.scrollTop = 0
      }
      setActive(0)
      setQuery(e.target.value)
      if (selection) {
        setSelection("")
      }
    },
    [selection]
  )

  const handleSelect = (email) => {
    setQuery(email)
    setSelection(email)
    setState(id, email, null)
  }

  const handleOnFocus = () => {
    setShowUserList(true)
  }

  const handleOnBlur = () => {
    if (users) {
      if (users?.length === 1) {
        handleSelect(users[0].Email)
      }
    }

    setTimeout(() => {
      setShowUserList(false)
      setQuery("")
      setActive(0)
    }, 200)
  }

  const handleIsValid = () => {
    if (isValid) {
      return "is-valid"
    }

    if (!isValid && !query && validationError) {
      return "is-invalid"
    }

    return ""
  }

  const keyDownHandler = (event) => {
    if (event.keyCode === 38) {
      event.preventDefault()
      if (active > 0) {
        setActive(active - 1)
        const selected = userItemsRef?.current?.querySelector(".active-user")
        userItemsRef.current.scroll({
          top:
            selected.offsetTop -
            userItemsRef.current.offsetTop -
            selected.offsetHeight,
          behavior: "smooth"
        })
      }
    } else if (event.keyCode === 40) {
      event.preventDefault()
      if (active < users?.length - 1) {
        setActive(active + 1)
        const selected = userItemsRef?.current?.querySelector(".active-user")
        userItemsRef.current.scroll({
          top: selected.offsetTop - selected.parentNode.offsetTop,
          behavior: "smooth"
        })
      }
    }
    if (event.keyCode === 13) {
      const selectedUser = users[active]
      setSelection(selectedUser.Email)
    }
  }

  return (
    <>
      <div className="input-loading-container" style={{ marginTop: "1rem" }}>
        <CInputGroup>
          <CInput
            type="text"
            autoComplete="off"
            aria-autocomplete="none"
            name="user"
            placeholder="Enter a first name, last name or email to search for a user"
            onKeyDown={keyDownHandler}
            value={selection || query}
            onChange={onSearch}
            onFocus={() => handleOnFocus()}
            onBlur={handleOnBlur}
            className={` ${
              !users && !error && isQueryMinimumLength() && !selection
                ? "input-is-fetching"
                : ""
            }  ${handleIsValid()}`}
          />
          {!users && !error && isQueryMinimumLength() && !selection && (
            <div className="icon-loading-array-container">
              <i className="icon-loader"></i>
            </div>
          )}
          <CInputGroupAppend>
            <CButton
              type="button"
              color="danger"
              variant="outline"
              onClick={() => handleRemove(id)}
            >
              -
            </CButton>
          </CInputGroupAppend>
          <div className="invalid-feedback">{validationError}</div>
        </CInputGroup>
      </div>
      <div className="user-select-container">
        {!selection && query && showUserList && users?.length > 0 && !error && (
          <ul className="user-select-group" ref={userItemsRef}>
            {users.map((user, i) => (
              <CListGroupItem
                className={`user-select-item ${
                  active === i ? "active-user" : ""
                }`}
                key={user.Email}
                onClick={() => handleSelect(user.Email)}
              >
                {user.Email} - {user.FirstName} {user.LastName} ({user.Title})
              </CListGroupItem>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

const UserSelectArray = ({ state, setState, limit = "", titleFilter }) => {
  const handleAddFields = () => {
    setState([...state, { id: uuidv4(), email: "", error: null }])
  }

  const handleUsersUpdate = (id, value) => {
    const newInputFields = state.map((i) => {
      if (id === i.id) {
        i.email = value
      }
      return i
    })

    setState(newInputFields)
  }

  const handleRemoveField = (id) => {
    const newUsers = [...state]
    const userWithIdIndex = newUsers.findIndex((user) => user.id === id)
    if (userWithIdIndex > -1) {
      newUsers.splice(userWithIdIndex, 1)
    }

    setState(newUsers)
  }

  return (
    <>
      {state &&
        state.map((user) => (
          <UserSelectItem
            key={user.id}
            id={user.id}
            setState={handleUsersUpdate}
            handleRemove={handleRemoveField}
            limit={limit}
            titleFilter={titleFilter}
            validationError={user.error}
          />
        ))}
      <CRow className="my-2">
        <CCol>
          <CButton
            color="deloitte-green"
            variant="outline"
            onClick={() => handleAddFields()}
          >
            +
          </CButton>
        </CCol>
      </CRow>
    </>
  )
}

UserSelectArray.propTypes = {
  state: PropTypes.array,
  setState: PropTypes.func,
  limit: PropTypes.number,
  titleFilter: PropTypes.array
}

UserSelectItem.propTypes = {
  id: PropTypes.string,
  setState: PropTypes.func,
  handleRemove: PropTypes.func,
  limit: PropTypes.string,
  titleFilter: PropTypes.array,
  validationError: PropTypes.string
}

export default UserSelectArray
