import { CCol, CInput, CRow, CTextarea } from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import useNotifications from "components/common/customHooks/useNotifications"
import { DatePickerInput, OptionDropdown } from "components/common/dropdown"
import { LoadingSpinnerOverlay } from "components/common/loading"
import {
  postRequestAzureAccount,
  postRequestGcpAccount
} from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { useExtension, validateEmail } from "utils"
import getDropdownItems from "utils/array/getDropdownItems"
import { toStandardDateString } from "utils/date"
import { useUserAuth } from "utils/hooks"

import {
  azureAccountTypeValues,
  azureDataClassificationValues,
  azurePrimaryLocations,
  azureSecondaryLocations,
  booleanOptions,
  dataOwnershipValues,
  dataQualifierValues,
  environmentValues,
  functionValues,
  gcpDataClassificationValues,
  gcpRegionValues,
  industryValues,
  instancesImpactedValues,
  usersProjectImpactedValues
} from "./constants"
import FormActions from "./FormActions"

const defaultOptions = [{ text: "N/A", key: "N/A" }]

export default function RequestCloudAccount({ defaultCloudProvider }) {
  const auth = useUserAuth()
  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL

  const [cloudServiceProvider, setCloudServiceProvider] = useState(
    defaultCloudProvider || "Microsoft Azure"
  )
  const [sandBox, setSandBox] = useState(azureAccountTypeValues[0].key)
  const [dataClassification, setDataClassification] = useState(
    defaultCloudProvider === "Google Cloud Platform"
      ? gcpDataClassificationValues[0]
      : azureDataClassificationValues[0]
  )
  const [selfManaged, setSelfManaged] = useState("No")
  const [forClient, setForClient] = useState("No")
  const [forNewClient, setForNewClient] = useState("No")
  const [isClientApproved, setIsClientApproved] = useState("No")
  const [projectName, setProjectName] = useState("")
  const [clientName, setClientName] = useState("")
  const [ppmd, setPpmd] = useState("")
  const [financialController, setFinancialController] = useState("")
  const [accAdmin, setAccAdmin] = useState("")
  const [industry, setIndustry] = useState(industryValues[0])
  const [engagementManager, setEngagementManager] = useState("")
  const [leadArch, setLeadArch] = useState("")
  const [wbs, setWbs] = useState("")
  const [primaryLocation, setPrimaryLocation] = useState(
    azurePrimaryLocations[0]
  )
  const [projStartDate, setProjStartDate] = useState(new Date())
  const [projEndDate, setProjEndDate] = useState(new Date())
  const [secondaryLocation, setSecondaryLocation] = useState(
    azureSecondaryLocations[0].key
  )
  const [purpose, setPurpose] = useState("")
  const [additionalData, setAdditionalData] = useState("")

  const [gcpProject, setGcpProject] = useState("")
  const [gcpProjectName, setGcpProjectName] = useState("")
  const [env, setEnv] = useState(environmentValues[0])
  const [cloudFunction, setCloudFunction] = useState(functionValues[0])
  const [zone1, setZone1] = useState("N/A")
  const [dataQualifier, setDataQualifier] = useState(dataQualifierValues[0])
  const [vpnSoln, setVpnSoln] = useState("Open VPN")
  const [clientCidrSpec, setClientCidrSpec] = useState("")
  const [stubCidrSpec, setStubCidrSpec] = useState("")
  const [accountRegion, setAccountRegion] = useState(gcpRegionValues[0])
  const [zone2, setZone2] = useState("N/A")
  const [dataOwnership, setDataOwnerShip] = useState(dataOwnershipValues[0])
  const [clientCidrRange2, setClientCidrRange2] = useState("")
  const [stubCidrSpec2, setStubCidrSpec2] = useState("")
  const [instanceImpacted, setInstanceImpacted] = useState(
    instancesImpactedValues[0]
  )
  const [usersProjectsImpacted, setUsersProjectsImpacted] = useState(
    usersProjectImpactedValues[0]
  )
  const [urgency, setUrgency] = useState("1")
  const [billingCode, setBillingCode] = useState("")

  const [isProjectNameTouched, setIsProjectNameTouched] = useState(false)
  const [isClientNameTouched, setIsClientNameTouched] = useState(false)
  const [isPpmdTouched, setIsPpmdTouched] = useState(false)
  const [isFinancialControllerTouched, setIsFinancialControllerTouched] =
    useState(false)
  const [isEngagementManagerTouched, setIsEngagementManagerTouched] =
    useState(false)
  const [isLeadArchTouched, setIsLeadArchTouched] = useState(false)
  const [isWbsTouched, setIsWbsTouched] = useState(false)
  const [isPurposeTouched, setIsPurposeTouched] = useState(false)

  const [isGcpProjectTouched, setIsGcpProjectTouched] = useState(false)
  const [isGcpProjectNameTouched, setIsGcpProjectNameTouched] = useState(false)
  const [isEnvTouched, setIsEnvTouched] = useState(false)
  const [isClientCidrSpecTouched, setIsClientCidrSpecTouched] = useState(false)
  const [isStubCidrSpecTouched, setIsStubCidrSpecTouched] = useState(false)
  const [isClientCidrRange2Touched, setIsClientCidrRange2Touched] =
    useState(false)
  const [isStubCidrSpec2Touched, setIsStubCidrSpec2Touched] = useState(false)
  const [isInstanceImpactedTouched, setIsInstanceImpactedTouched] =
    useState(false)
  const [isUsersProjectsImpactedTouched, setIsUsersProjectsImpactedTouched] =
    useState(false)
  const [isBillingCodeTouched, setIsBillingCodeTouched] = useState("")
  const [notifications, setNotifications] = useNotifications()

  const [isLoading, setIsLoading] = useState(false)

  const enqueueToast = useContext(ToasterProvider)
  const history = useHistory()

  const setAllTouchedValues = (value) => {
    setIsProjectNameTouched(value)
    setIsClientNameTouched(value)
    setIsPpmdTouched(value)
    setIsFinancialControllerTouched(value)
    setIsEngagementManagerTouched(value)
    setIsLeadArchTouched(value)
    setIsWbsTouched(value)
    setIsPurposeTouched(value)

    setIsGcpProjectTouched(value)
    setIsGcpProjectNameTouched(value)
    setIsEnvTouched(value)
    setIsClientCidrSpecTouched(value)
    setIsStubCidrSpecTouched(value)
    setIsClientCidrRange2Touched(value)
    setIsStubCidrSpec2Touched(value)
    setIsInstanceImpactedTouched(value)
    setIsUsersProjectsImpactedTouched(value)
    setIsBillingCodeTouched(value)
  }

  useEffect(() => {
    setAllTouchedValues(false)
  }, [cloudServiceProvider])

  const handleSubmit = async () => {
    setAllTouchedValues(true)
    if (
      cloudServiceProvider === "Google Cloud Platform" ||
      cloudServiceProvider === "AWS"
    ) {
      if (
        !gcpProject ||
        !gcpProjectName ||
        env === "-- None --" ||
        !clientCidrSpec ||
        !stubCidrSpec ||
        !clientCidrRange2 ||
        !stubCidrSpec2 ||
        instanceImpacted === "N/A" ||
        usersProjectsImpacted === "N/A" ||
        !billingCode
      ) {
        return
      }

      // Temporarily showing success message instead of calling API for AWS
      if (cloudServiceProvider === "AWS") {
        enqueueToast("success", null, "AWS account requested successfully")
        history.goBack()
        return
      }

      setIsLoading(true)
      const requestGCPAccountBody = {
        is_this_for_a_client: forClient,
        is_this_for_a_new_client: forNewClient,
        gcp_project: gcpProject,
        gcp_project_name_2: gcpProjectName,
        environment: env,
        function: cloudFunction,
        zone_1: zone1,
        data_classification_qualifier: dataQualifier,
        vpn: vpnSoln,
        client_cidr_specification: clientCidrSpec,
        stub_cidr_specification: stubCidrSpec,
        gcp_account_region: accountRegion,
        zone_2: zone2,
        data_classification: dataClassification,
        data_ownership: dataOwnership,
        client_cidr_range_2: clientCidrRange2,
        stub_cidr_specification_2: stubCidrSpec2,
        urgency: urgency,
        instance: instanceImpacted,
        users_projects_impacted: usersProjectsImpacted,
        billing_code: billingCode
      }
      const response = await postRequestGcpAccount(
        { auth, host, body: JSON.stringify(requestGCPAccountBody) },
        projectID
      )
      if (
        !response.error &&
        response.data &&
        response.data.startsWith("Submitted")
      ) {
        try {
          let responseData = response.data.split("{")[1].split("}")[0]
          responseData = JSON.parse(`{${responseData}}`)
          enqueueToast(
            "success",
            null,
            `GCP account requested successfully${
              responseData.number ? ` with number ${responseData.number}` : ""
            }`
          )
          const currentDate = new Date()
          const notification = {
            id: notifications.length + 1,
            from: "Created Successfully!!",
            active: true,
            approved: true,
            ticket_number: responseData.number,
            time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
            description: "GCP account requested successfully with number ",
            requestNumber: ["ServiceRequest", responseData.number]
          }
          setNotifications([notification, ...notifications])
        } catch {
          enqueueToast("success", null, "GCP account requested successfully")
        }
        history.goBack()
      } else {
        enqueueToast("failure", null, "Could not request GCP account")
        history.goBack()
      }
      setIsLoading(false)
    }
    if (cloudServiceProvider === "Microsoft Azure") {
      if (
        !projectName ||
        !clientName ||
        !validateEmail(ppmd) ||
        !validateEmail(financialController) ||
        !validateEmail(engagementManager) ||
        !validateEmail(leadArch) ||
        !wbs ||
        !purpose
      ) {
        return
      }
      setIsLoading(true)
      const requestAzureAccountBody = {
        // TODO: some variables are hardcoded
        project_name: projectName,
        client_name: clientName,
        industry: industry,
        billing_code: "GOO00217-01-01-01-1000",
        project_start_date: toStandardDateString(projStartDate),
        project_end_date: toStandardDateString(projEndDate),
        purpose_of_this_request: purpose,
        lead_architect: leadArch,
        engagement_manager: engagementManager,
        ppmd,
        cloud_service_provider: "Microsoft Azure",
        account_type: sandBox,
        data_classification: dataClassification,
        primary_location: primaryLocation,
        secondary_location_temp: secondaryLocation,
        is_self_managed: selfManaged,
        is_this_for_a_client: forClient,
        is_this_for_a_new_client: forNewClient,
        client_acceptance_approved: isClientApproved,
        additional_data_classification_details: additionalData,
        account_administrator: accAdmin,
        financial_controller: financialController
      }
      const response = await postRequestAzureAccount(
        { auth, host, body: JSON.stringify(requestAzureAccountBody) },
        projectID
      )
      if (
        !response.error &&
        response.data &&
        response.data.startsWith("Submitted")
      ) {
        try {
          let responseData = response.data.split("{")[1].split("}")[0]
          responseData = JSON.parse(`{${responseData}}`)
          enqueueToast(
            "success",
            null,
            `Azure account requested successfully${
              responseData.number ? ` with number ${responseData.number}` : ""
            }`
          )
          const currentDate = new Date()
          const notification = {
            id: notifications.length + 1,
            from: "Created Successfully!!",
            active: true,
            approved: true,
            ticket_number: responseData.number,
            time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
            description: "Azure account requested successfully with number ",
            requestNumber: ["ServiceRequest", responseData.number]
          }
          setNotifications([notification, ...notifications])
        } catch {
          enqueueToast("success", null, "Azure account requested successfully")
        }
        history.goBack()
      } else {
        enqueueToast("failure", null, "Could not request Azure account")
        history.goBack()
      }
      setIsLoading(false)
    }
  }

  const handleCancel = () => {
    history.goBack()
  }

  const renderAzureFields = () => (
    <>
      <CRow className="mx-2 mt-3">
        <CCol md="12">
          <p className="heading">Select an Offering</p>
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Cloud Service Provider *</p>
          <OptionDropdown
            items={[{ text: "Microsoft Azure", key: "Microsoft Azure" }]}
            value={cloudServiceProvider}
            onChange={setCloudServiceProvider}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Account Type</p>
          <OptionDropdown
            items={azureAccountTypeValues}
            value={sandBox}
            onChange={setSandBox}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Data Classification</p>
          <OptionDropdown
            items={getDropdownItems(azureDataClassificationValues)}
            value={dataClassification}
            onChange={setDataClassification}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Is Self Managed ?</p>
          <OptionDropdown
            items={booleanOptions}
            value={selfManaged}
            onChange={setSelfManaged}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-4">
        <CCol md="12">
          <p className="heading">Tell us about client</p>
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Is this for a client ?</p>
          <OptionDropdown
            items={booleanOptions}
            value={forClient}
            onChange={setForClient}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Is this for a new client ?</p>
          <OptionDropdown
            items={booleanOptions}
            value={forNewClient}
            onChange={setForNewClient}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Is client approved ?</p>
          <OptionDropdown
            items={booleanOptions}
            value={isClientApproved}
            onChange={setIsClientApproved}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-4">
        <CCol md="12">
          <p className="heading">Basic Detail</p>
        </CCol>
      </CRow>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Project Name *</p>
          <CInput
            type="text"
            name="projectName"
            placeholder="Project Name"
            value={projectName}
            onChange={(e) => {
              setIsProjectNameTouched(true)
              setProjectName(e.target.value)
            }}
            className={classNames({
              "is-invalid": isProjectNameTouched && !projectName
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Client Name *</p>
          <CInput
            type="text"
            name="clientName"
            value={clientName}
            onChange={(e) => {
              setIsClientNameTouched(true)
              setClientName(e.target.value)
            }}
            className={classNames({
              "is-invalid": isClientNameTouched && !clientName
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">PPMD *</p>
          <CInput
            type="email"
            name="ppmd"
            value={ppmd}
            onChange={(e) => {
              setIsPpmdTouched(true)
              setPpmd(e.target.value)
            }}
            className={classNames({
              "is-invalid": isPpmdTouched && !validateEmail(ppmd)
            })}
          />
          <div className="invalid-feedback">Enter a valid e-mail id</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Financial Controller *</p>
          <CInput
            type="email"
            name="financialController"
            value={financialController}
            onChange={(e) => {
              setIsFinancialControllerTouched(true)
              setFinancialController(e.target.value)
            }}
            className={classNames({
              "is-invalid":
                isFinancialControllerTouched &&
                !validateEmail(financialController)
            })}
          />
          <div className="invalid-feedback">Enter a valid e-mail id</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Account Administrator</p>
          <CInput
            type="text"
            name="accAdmin"
            value={accAdmin}
            onChange={(e) => setAccAdmin(e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Industry *</p>
          <OptionDropdown
            items={getDropdownItems(industryValues)}
            value={industry}
            onChange={setIndustry}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Engagement Manager *</p>
          <CInput
            type="email"
            name="engagementManager"
            value={engagementManager}
            onChange={(e) => {
              setIsEngagementManagerTouched(true)
              setEngagementManager(e.target.value)
            }}
            className={classNames({
              "is-invalid":
                isEngagementManagerTouched && !validateEmail(engagementManager)
            })}
          />
          <div className="invalid-feedback">Enter a valid e-mail id</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Lead Architect *</p>
          <CInput
            type="email"
            name="leadArch"
            value={leadArch}
            onChange={(e) => {
              setIsLeadArchTouched(true)
              setLeadArch(e.target.value)
            }}
            className={classNames({
              "is-invalid": isLeadArchTouched && !validateEmail(leadArch)
            })}
          />
          <div className="invalid-feedback">Enter a valid e-mail id</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">WBS *</p>
          <CInput
            type="text"
            name="wbs"
            placeholder="WBS"
            value={wbs}
            onChange={(e) => {
              setIsWbsTouched(true)
              setWbs(e.target.value)
            }}
            className={classNames({
              "is-invalid": isWbsTouched && !wbs
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Primary Location</p>
          <OptionDropdown
            items={getDropdownItems(azurePrimaryLocations)}
            value={primaryLocation}
            onChange={setPrimaryLocation}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Project Start Date *</p>
          <DatePickerInput
            className="w-100"
            buttonClassName="w-100"
            value={projStartDate}
            onChange={setProjStartDate}
            range={false}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Project End Date *</p>
          <DatePickerInput
            className="w-100"
            buttonClassName="w-100"
            value={projEndDate}
            onChange={setProjEndDate}
            range={false}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Secondary Location</p>
          <OptionDropdown
            items={azureSecondaryLocations}
            value={secondaryLocation}
            onChange={setSecondaryLocation}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Purpose of this Request *</p>
          <CInput
            type="text"
            name="purpose"
            placeholder="Purpose"
            value={purpose}
            onChange={(e) => {
              setIsPurposeTouched(true)
              setPurpose(e.target.value)
            }}
            className={classNames({
              "is-invalid": isPurposeTouched && !purpose
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Additional Data Classification Details</p>
          <CTextarea
            lazy="600"
            value={additionalData}
            onChange={(e) => setAdditionalData(e.target.value)}
          />
        </CCol>
      </CRow>
    </>
  )

  const renderGCPFields = () => (
    <>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Is this for a client ? *</p>
          <OptionDropdown
            items={booleanOptions}
            value={forClient}
            onChange={setForClient}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Is this for a new client ? *</p>
          <OptionDropdown
            items={booleanOptions}
            value={forNewClient}
            onChange={setForNewClient}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">{`${
            cloudServiceProvider === "AWS" ? "AWS" : "GCP"
          } Project *`}</p>
          <CInput
            type="text"
            name="gcpProject"
            value={gcpProject}
            onChange={(e) => {
              setIsGcpProjectTouched(true)
              setGcpProject(e.target.value)
            }}
            className={classNames({
              "is-invalid": isGcpProjectTouched && !gcpProject
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">{`${
            cloudServiceProvider === "AWS" ? "AWS" : "GCP"
          } Project Name *`}</p>
          <CInput
            type="text"
            name="gcpProjectName"
            value={gcpProjectName}
            onChange={(e) => {
              setIsGcpProjectNameTouched(true)
              setGcpProjectName(e.target.value)
            }}
            className={classNames({
              "is-invalid": isGcpProjectNameTouched && !gcpProjectName
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Environment *</p>
          <OptionDropdown
            items={getDropdownItems(environmentValues)}
            value={env}
            onChange={(value) => {
              setIsEnvTouched(true)
              setEnv(value)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid": isEnvTouched && env === "-- None --"
              },
              "w-100 form-control"
            )}
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Function *</p>
          <OptionDropdown
            items={getDropdownItems(functionValues)}
            value={cloudFunction}
            onChange={setCloudFunction}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Zone 1 *</p>
          <OptionDropdown
            items={defaultOptions}
            value={zone1}
            onChange={setZone1}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Data Classification *</p>
          <OptionDropdown
            items={getDropdownItems(gcpDataClassificationValues)}
            value={dataClassification}
            onChange={setDataClassification}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">VPN *</p>
          <OptionDropdown
            items={getDropdownItems(["Open VPN"])}
            value={vpnSoln}
            onChange={setVpnSoln}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Client CIDR Specification *</p>
          <CInput
            type="text"
            name="cientCidrSpec"
            value={clientCidrSpec}
            onChange={(e) => {
              setIsClientCidrSpecTouched(true)
              setClientCidrSpec(e.target.value)
            }}
            className={classNames({
              "is-invalid": isClientCidrSpecTouched && !clientCidrSpec
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Stub CIDR Specification *</p>
          <CInput
            type="text"
            name="stubCidrSpec"
            value={stubCidrSpec}
            onChange={(e) => {
              setIsStubCidrSpecTouched(true)
              setStubCidrSpec(e.target.value)
            }}
            className={classNames({
              "is-invalid": isStubCidrSpecTouched && !stubCidrSpec
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">{`${
            cloudServiceProvider === "AWS" ? "AWS" : "GCP"
          } Account Region *`}</p>
          <OptionDropdown
            items={getDropdownItems(gcpRegionValues)}
            value={accountRegion}
            onChange={setAccountRegion}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Zone 2 *</p>
          <OptionDropdown
            items={defaultOptions}
            value={zone2}
            onChange={setZone2}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Data Qualifier *</p>
          <OptionDropdown
            items={getDropdownItems(dataQualifierValues)}
            value={dataQualifier}
            onChange={setDataQualifier}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Data Ownership *</p>
          <OptionDropdown
            items={getDropdownItems(dataOwnershipValues)}
            value={dataOwnership}
            onChange={setDataOwnerShip}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Client CIDR Range 2 *</p>
          <CInput
            type="text"
            name="clientCidrRange2"
            value={clientCidrRange2}
            onChange={(e) => {
              setIsClientCidrRange2Touched(true)
              setClientCidrRange2(e.target.value)
            }}
            className={classNames({
              "is-invalid": isClientCidrRange2Touched && !clientCidrRange2
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Stub CIDR Specification 2 *</p>
          <CInput
            type="text"
            name="stubCidrSpec2"
            value={stubCidrSpec2}
            onChange={(e) => {
              setIsStubCidrSpec2Touched(true)
              setStubCidrSpec2(e.target.value)
            }}
            className={classNames({
              "is-invalid": isStubCidrSpec2Touched && !stubCidrSpec2
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Urgency *</p>
          <OptionDropdown
            items={getDropdownItems(["1", "2", "3", "4", "5"])}
            value={urgency}
            onChange={setUrgency}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Instance Impacted *</p>
          <OptionDropdown
            items={getDropdownItems(instancesImpactedValues)}
            value={instanceImpacted}
            onChange={(value) => {
              setIsInstanceImpactedTouched(true)
              setInstanceImpacted(value)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  isInstanceImpactedTouched && instanceImpacted === "N/A"
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Users/Projects Impacted *</p>
          <OptionDropdown
            items={getDropdownItems(usersProjectImpactedValues)}
            value={usersProjectsImpacted}
            onChange={(value) => {
              setIsUsersProjectsImpactedTouched(true)
              setUsersProjectsImpacted(value)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  isUsersProjectsImpactedTouched &&
                  usersProjectsImpacted === "N/A"
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Billing Code *</p>
          <CInput
            type="text"
            name="billingCode"
            value={billingCode}
            onChange={(e) => {
              setIsBillingCodeTouched(true)
              setBillingCode(e.target.value)
            }}
            className={classNames({
              "is-invalid": isBillingCodeTouched && !billingCode
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
      </CRow>
    </>
  )

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="mx-2 my-4">
          <CCol md="12">
            <p className="heading py-2">
              {`Request a Cloud Account (${
                cloudServiceProvider === "Google Cloud Platform" ? "GCP" : ""
              }${cloudServiceProvider === "Microsoft Azure" ? "Azure" : ""}${
                cloudServiceProvider === "AWS" ? "AWS" : ""
              })`}
            </p>
          </CCol>
        </CRow>
        {cloudServiceProvider === "Microsoft Azure" && renderAzureFields()}
        {cloudServiceProvider === "Google Cloud Platform" && renderGCPFields()}
        {cloudServiceProvider === "AWS" && renderGCPFields()}
        <FormActions onSubmit={handleSubmit} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>
    </div>
  )
}
RequestCloudAccount.propTypes = {
  defaultCloudProvider: PropTypes.string
}
