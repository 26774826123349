import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "post",
  makeRequestUrl: (host, [project, id]) => {
    return `${host}/itsm/${project}/approve_request/${id}`
  },
  checkRequiredArguments: ([project, id, data]) => {
    if (project === undefined) throw new Error("No ticket system id supplied.")
    if (id === undefined) throw new Error("No ticket system id supplied.")
    if (data === undefined) throw new Error("No Body supplied.")
  },
  makeBody: ([_project, _id, data]) => {
    return JSON.stringify(data)
  },
  makeResponseData: (json) => {
    return json
  },
  returnRawResponse: true
}
export default createFetcher(fetcherConfiguration)
