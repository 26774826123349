import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAccountList, getUserProjects, getUserRole } from "api/collections"
import { delayedPromise, exponentialBackingOff } from "utils"
import lock from "utils/lock/lock"

import { settingsActions } from "./settings"

let backingOff = exponentialBackingOff(1000, 1, 2, 16000)
let lastLoginAttempt = null

const refreshLock = lock()

const refreshToken = createAsyncThunk(
  "user/refreshToken",
  async ({ msalInstance, msalAccount, expiresIn }, thunkAPI) => {
    const lockRef = refreshLock.acquire()
    if (!lockRef) throw Error("Cannot acquire lock.")
    const nextDelay = expiresIn * 1000 * 0.95
    await delayedPromise(nextDelay).promise
    refreshLock.release(lockRef)
    try {
      const tokenResponse = await msalInstance.acquireTokenSilent({
        account: msalAccount
      })
      const authState = tokenResponse
      const token = authState.idToken
      const claims = authState.idTokenClaims
      const tokenExpires = claims.exp
      const nextExpiresIn = tokenExpires - new Date() / 1000
      thunkAPI.dispatch(
        refreshToken({ msalInstance, msalAccount, expiresIn: nextExpiresIn })
      )
      return { token, tokenExpires }
    } catch (e) {
      console.error(e)
      console.log("Refresh Error User Logging Out")
      thunkAPI.dispatch(userLogout({ instance: msalInstance }))
    }
  }
)

const userLogout = createAsyncThunk(
  "user/userLogout",
  async ({ instance }, thunkAPI) => {
    thunkAPI.dispatch(actions.isLoggingOut(true))
    instance.logoutRedirect().catch((e) => {
      console.error(e)
    })
    return null
  }
)

const userLogin = createAsyncThunk(
  "user/userLogin",
  async ({ msalInstance, msalAccount, extension }, thunkAPI) => {
    const delay = backingOff.next().value
    const now = new Date()
    if (!lastLoginAttempt) {
      lastLoginAttempt = now
    } else if (now - lastLoginAttempt < delay) {
      await delayedPromise(delay - (now - lastLoginAttempt)).promise
      lastLoginAttempt = new Date()
    }
    thunkAPI.dispatch(actions.isLoggingIn(true))
    const tokenResponse = await msalInstance.acquireTokenSilent({
      account: msalAccount
    })
    const authState = tokenResponse
    const claims = authState.idTokenClaims
    const user = claims.email.split("@")[0]
    const host = extension?.config?.env?.clientApiGateway?.URL
    if (host === undefined) throw new Error("No Client API URL defined.")

    const token = authState.idToken
    const tokenExpires = claims.exp

    const requestAuth = { email: claims.email, token }

    const roleAndPolicy = (await getUserRole({ auth: requestAuth, host }, user))
      .data
    const projects = (await getUserProjects({ auth: requestAuth, host }, user))
      .data

    let accounts = null

    let project = projects[0]
    if (projects.length > 0) {
      try {
        const cachedProjectID = localStorage.getItem(
          "insight-portal-selected-project"
        )

        if (cachedProjectID) {
          const cachedProject = projects.find(
            (p) => p.id === cachedProjectID
          )
          if (cachedProject && cachedProject.id !== project.id) {
            project = cachedProject
            localStorage.setItem("insight-portal-selected-project", project.id)
          } else {
            localStorage.removeItem("insight-portal-selected-project")
          }
        }
      } catch (e) {
        console.error(e.message)
      }

      accounts = (await getAccountList({ auth: requestAuth, host }, project.id))
        .data

      thunkAPI.dispatch(settingsActions.setProject(project))
    }
    const payload = {
      accounts,
      projects,
      name: claims.name,
      email: claims.email,
      token,
      tokenExpires,
      role: roleAndPolicy.role,
      policy: roleAndPolicy.portal_policy
      // policy: roleAndPolicy?.data
      //   ? roleAndPolicy.data.portal_policy
      //   : {
      //       modules: [{ module: "service", paths: ["/requests/sandbox"] }]
      //     }
      // policy: {
      //   modules: [
      //     {
      //        module: "admin",
      //        paths: ["/", "/maintenance", "/application-monitoring","/users"]
      //      },
      //      { module: "documentation", paths: ["/"] },
      //      { module: "service", paths: ["/", "/requests"] },
      //      { module: "security", paths: ["/", "/ufst-account-security", "/user-access-report"] },
      //      { module: "infrastructure", paths: ["/"] },
      //      { module: "", paths: ["/"] },
      //      { module: "cloud-financials", paths: ["/", "/sandbox-controls"] },
      //      { module: "infra-monitoring", paths: ["/"] },
      //      { module: "self-service", paths: ["/"] },
      //      { module: "key-contacts", paths: ["/"] },
      //      { module: "quick-links", paths: ["/"] },
      //      { module: "cms-users", paths: ["/"] },
      //      { module: "reports", paths: ["/"] },
      //      { module: "guardrails", paths: ["/"] },
      //      { module: "inventory", paths: ["/"] },
      //      { module: "api-reference", paths: ["/"] }
      //    ]
      //  }
    }

    const expiresIn = tokenExpires - new Date() / 1000
    thunkAPI.dispatch(refreshToken({ msalInstance, msalAccount, expiresIn }))
    backingOff = exponentialBackingOff(1000, 1, 2, 16000)
    return payload
  }
)

const createUserSlice = (
  initialState = {
    accountsFetching: false,
    projects: null,
    role: null,
    policy: null,
    name: null,
    email: null,
    isAuthenticated: false,
    tokenRefreshing: null,
    bearerToken: null,
    isLoggingIn: null,
    isLoggingOut: null,
    tokenExpires: null
  }
) =>
  createSlice({
    name: "user",
    initialState,
    reducers: {
      isLoggingOut(state, action) {
        const isLoggingOut = action.payload
        state.isLoggingOut = isLoggingOut
      },
      isLoggingIn(state, action) {
        const isLoggingIn = action.payload
        state.isLoggingIn = isLoggingIn
      },
      userEmail(state, action) {
        const email = action.payload
        state.email = email
      },
      userIsAuthenticated(state, action) {
        const isAuthenticated = action.payload
        state.isAuthenticated = isAuthenticated
      },
      userLogout(state) {
        state.name = null
        state.email = null
        state.projects = []
        state.isAuthenticated = false
        state.isLoggingOut = false
      },
      userName(state, action) {
        const name = action.payload
        state.name = name
      },
      userToken(state, action) {
        const { bearerToken, tokenExpires } = action.payload
        state.bearerToken = bearerToken
        state.tokenExpires = tokenExpires
      },
      userPolicy(state, action) {
        const policy = action.payload
        state.policy = policy
      },
      userProjects(state, action) {
        const projects = action.payload
        state.projects = projects
      },
      userRole(state, action) {
        const role = action.payload
        state.role = role
      },
      userAccountsFetching(state, action) {
        const accountsFetching = action.payload
        state.accountsFetching = accountsFetching
      },
      setAccounts(state, action) {
        const accounts = action.payload
        state.accounts = accounts
      }
    },
    extraReducers: {
      [userLogout.fulfilled]: (state, _) => {
        state.isLoggingIn = false
        state.name = null
        state.email = null
        state.projects = null
        state.isAuthenticated = false
        state.tokenExpires = null
        state.bearerToken = null
        state.policy = null
        state.role = null
      },
      [userLogout.rejected]: (_, _2) => {},
      [userLogin.fulfilled]: (state, action) => {
        state.accounts = action.payload.accounts
        state.projects = action.payload.projects
        state.isLoggingIn = false
        state.name = action.payload.name
        state.email = action.payload.email
        state.projects = action.payload.projects
        state.isAuthenticated = true
        state.tokenExpires = action.payload.tokenExpires
        state.bearerToken = action.payload.token
        state.policy = action.payload.policy
        state.role = action.payload.role
      },
      [userLogin.rejected]: (state, action) => {
        state.isLoggingIn = false
        state.isAuthenticated = false

        if (action.error) {
          console.error(action.error)
        }
      },
      [refreshToken.fulfilled]: (state, action) => {
        state.bearerToken = action.payload.token
        state.tokenExpires = action.payload.tokenExpires
      },
      [refreshToken.rejected]: (state, action) => {
        if (action.error) {
          console.error(action.error)
        }
      }
    }
  })

const userSlice = createUserSlice()
const actions = userSlice.actions
const reducer = userSlice.reducer

export {
  actions as userActions,
  reducer as userReducer,
  createUserSlice,
  userLogin,
  userLogout
}
