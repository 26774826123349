/** @jsx jsx */
import { CCallout, CModal, CSpinner } from "@coreui/react"
import { css, jsx } from "@emotion/react"
import deleteIcon from "assets/icons/delete_link.svg"
import download from "assets/icons/get_app.svg"
import ModalClose from "assets/icons/modalClose.svg"
import { useTheme } from "components/common/Context/ThemeContext"
import PropTypes from "prop-types"

const useStyles = (theme) => {
  return {
    message: css`
      font-size: 16px;
    `,
    okayBtn: css`
      background-color: ${theme.serviceRequestButton};
      border-color: ${theme.serviceRequestButton};
      min-width: 100px;
      &:hover {
        background-color: ${theme.serviceRequestButton};
        border-color: ${theme.serviceRequestButton};
      }
    `,
    cancelBtn: css`
      background-color: ${theme.cancelBtnBg};
      border-color: ${theme.serviceRequestButton};
      color: ${theme.serviceRequestButton};
      border: 1px solid;
      min-width: 100px;
      &:hover {
        border-color: ${theme.serviceRequestButton};
        color: ${theme.serviceRequestButton};
      }
    `
  }
}

export default function Action({
  show,
  setShow,
  onDownload,
  onDelete,
  message,
  isDownloading,
  isCanned
}) {
  const theme = useTheme()
  const classes = useStyles(theme)

  return (
    <CModal
      show={show}
      onClose={() => {
        setShow(false)
      }}
      size=""
      centered
      className="random"
    >
      <CCallout color="warning" className="m-0 py-3">
        <div className="d-flex justify-content-between align-items-start">
          <p css={classes.message}>{message}</p>
          <img
            src={ModalClose}
            alt="close-icon"
            className="cursor-pointer close-icon-modal"
            onClick={() => setShow(false)}
          />
        </div>
        <div className="d-flex justify-content-end mt-3">
          <a
            css={classes.okayBtn}
            color="primary"
            onClick={() => onDownload && onDownload()}
            className="text-white mr-3 action-buttons"
          >
            {isDownloading ? (
              <div className="pr-3 d-inline">
                <CSpinner size="sm" />
              </div>
            ) : (
              <img
                src={download}
                className="download-icon pr-1"
                alt="download icon"
                onClick={(e) => {
                  e.stopPropagation()
                  onDownload && onDownload()
                }}
              ></img>
            )}
            Download
          </a>

          {!isCanned && (
            <a
              css={classes.cancelBtn}
              variant="outline"
              color="primary"
              onClick={() => {
                onDelete && onDelete()
                setShow(false)
              }}
              className="action-buttons bg-white"
            >
              <img
                src={deleteIcon}
                className="delete-icon pr-1 mr-2"
                alt="delete icon"
              ></img>
              Delete
            </a>
          )}
        </div>
      </CCallout>
    </CModal>
  )
}

Action.propTypes = {
  show: PropTypes.bool,
  isDownloading: PropTypes.bool,
  isCanned: PropTypes.bool,
  setShow: PropTypes.func,
  onDownload: PropTypes.func,
  onDelete: PropTypes.func,
  message: PropTypes.string
}
