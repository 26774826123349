import { addDays, addMonths, startOfMonth } from "date-fns"

export default function (key) {
  const today = new Date()
  switch (key) {
    case 3:
      return addDays(today, -3)
    case 7:
      return addDays(today, -7)
    case 30:
      return startOfMonth(addMonths(today, -1))
    case 90:
      return startOfMonth(addMonths(today, -3))
    case 180:
      return startOfMonth(addMonths(today, -6))
    case 365:
      return startOfMonth(addMonths(today, -12))
    default:
      return today
  }
}
