import React from "react"

export default function (Wrapper, wrapperProps = {}) {
  return (Wrapped) => {
    return function innerWrapper (props) {
      let children
      if (Array.isArray(Wrapped)) {
        children = Wrapped.map((C) => {
          if (React.isValidElement(C)) {
            return React.cloneElement(C, props)
          }
          return null
        })
      } else {
        children = React.cloneElement(Wrapped, props)
      }
      return <Wrapper {...wrapperProps}>{children}</Wrapper>
    }
  }
}
