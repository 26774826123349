import { CCol, CInput, CRow } from "@coreui/react"
import classNames from "classnames"
import { DatePickerInput, OptionDropdown } from "components/common/dropdown"
import PropTypes from "prop-types"
import React from "react"
import getDropdownItems from "utils/array/getDropdownItems"

import {
  booleanOptions,
  locationOptions,
  scheduleProfileValues,
  scheduleTimeZoneOptions
} from "./constants"

export function AzureVM({
  AVMObject,
  onAVMChange,
  mandatoryDict,
  assignmentGroupsArray
}) {
  return (
    <>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Cloud Account *</p>
          <OptionDropdown
            items={[{ text: "Azure", key: "Azure" }]}
            value={AVMObject.cloudAccount}
            onChange={(val) => {
              onAVMChange("cloudAccount", val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  mandatoryDict.cloudAccount[1] && !AVMObject.cloudAccount
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Cost Center</p>
          <CInput
            type="text"
            name="costCenter"
            defaultValue={AVMObject.costCenter}
            onBlur={(e) => onAVMChange("costCenter", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Location *</p>
          <OptionDropdown
            items={getDropdownItems(locationOptions)}
            value={AVMObject.location}
            onChange={(val) => {
              onAVMChange("location", val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid": mandatoryDict.location[1] && !AVMObject.location
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Business Service</p>
          <CInput
            type="text"
            name="businessService"
            defaultValue={AVMObject.businessService}
            onBlur={(e) => onAVMChange("businessService", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Stack Name *</p>
          <CInput
            type="text"
            name="stackName"
            defaultValue={AVMObject.stackName}
            onBlur={(e) => {
              onAVMChange("stackName", e.target.value)
            }}
            className={classNames({
              "is-invalid": mandatoryDict.stackName[1] && !AVMObject.stackName
            })}
          />
          <div className="invalid-feedback">This field is required</div>
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Use Existing Workspace</p>
          <OptionDropdown
            items={booleanOptions}
            value={AVMObject.existingWorkspace}
            onChange={(val) => onAVMChange("existingWorkspace", val)}
            className="w-100"
            buttonClassName="w-100"
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">User Group *</p>
          <OptionDropdown
            items={getDropdownItems(assignmentGroupsArray)}
            value={AVMObject.userGroup}
            onChange={(val) => onAVMChange("userGroup", val)}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid": mandatoryDict.userGroup[1] && !AVMObject.userGroup
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Workspace</p>
          <CInput
            type="text"
            name="workspace"
            defaultValue={AVMObject.workspace}
            onBlur={(e) => onAVMChange("workspace", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Schedule Profile *</p>
          <OptionDropdown
            items={getDropdownItems(scheduleProfileValues)}
            value={AVMObject.scheduleProfile}
            onChange={(val) => {
              onAVMChange("scheduleProfile", val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  mandatoryDict.scheduleProfile[1] &&
                  (!AVMObject.scheduleProfile ||
                    AVMObject.scheduleProfile === "-- No Schedule --")
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Resource Group *</p>
          <CInput
            type="text"
            name="resourceGroup"
            defaultValue={AVMObject.resourceGroup}
            onBlur={(e) => onAVMChange("resourceGroup", e.target.value)}
            className={classNames({
              "is-invalid":
                mandatoryDict.resourceGroup[1] && !AVMObject.resourceGroupName
            })}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Lease End Date</p>
          <DatePickerInput
            className="w-100"
            buttonClassName="w-100"
            defaultValue={AVMObject.leaseEndDate}
            onBlur={(val) => onAVMChange("leaseEndDate", val)}
            range={false}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Resource Group Name *</p>
          <CInput
            type="text"
            name="resourceGroupName"
            defaultValue={AVMObject.resourceGroupName}
            onBlur={(e) => {
              onAVMChange("resourceGroupName", e.target.value)
            }}
            className={classNames({
              "is-invalid":
                mandatoryDict.resourceGroupName[1] &&
                !AVMObject.resourceGroupName
            })}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Schedule Time Zone *</p>
          <OptionDropdown
            items={scheduleTimeZoneOptions}
            value={AVMObject.scheduleTimeZone}
            onChange={(val) => {
              onAVMChange("scheduleTimeZone", val)
            }}
            className="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  mandatoryDict.scheduleTimeZone[1] &&
                  AVMObject.scheduleTimeZone === scheduleTimeZoneOptions[0].key
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Create Resource Group *</p>
          <OptionDropdown
            items={booleanOptions}
            value={AVMObject.createResourceGroup}
            onChange={(val) => onAVMChange("createResourceGroup", val)}
            className="w-100"
            // buttonClassName="w-100"
            buttonClassName={classNames(
              {
                "is-invalid":
                  mandatoryDict.createResourceGroup[1] &&
                  AVMObject.createResourceGroup === booleanOptions[0].key
              },
              "w-100 form-control"
            )}
            showDownIcon={true}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Application</p>
          <CInput
            type="text"
            name="application"
            defaultValue={AVMObject.application}
            onBlur={(e) => onAVMChange("application", e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Subscription ID *</p>
          <CInput
            type="text"
            name="subscriptionId"
            defaultValue={AVMObject.subscriptionId}
            onBlur={(e) => onAVMChange("subscriptionId", e.target.value)}
            className={classNames({
              "is-invalid":
                mandatoryDict.subscriptionId[1] && !AVMObject.subscriptionId
            })}
          />
        </CCol>
      </CRow>
    </>
  )
}
AzureVM.propTypes = {
  AVMObject: PropTypes.object,
  onAVMChange: PropTypes.func,
  mandatoryDict: PropTypes.object,
  assignmentGroupsArray: PropTypes.array
}
