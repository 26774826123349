import {
  CCol,
  CInput,
  CInputCheckbox,
  CInputRadio,
  CLink,
  CRow
} from "@coreui/react"
import classNames from "classnames"
import ToasterProvider from "components/common/Context/ToasterContext"
import useNotifications from "components/common/customHooks/useNotifications"
import { OptionDropdown } from "components/common/dropdown"
import { LoadingSpinnerOverlay } from "components/common/loading"
import { getUsers, postAddRole } from "extensions/usoc/api/cp"
import { postOnboardOktaUser } from "extensions/usoc/api/service"
import React, { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import useSWR from "swr"
import { useExtension } from "utils"
import getDropdownItems from "utils/array/getDropdownItems"
import { useUserAuth } from "utils/hooks"

import { rolePersonaValues, urgencyValues } from "./constants"
import FormActions from "./FormActions"

const pages = [
  {
    key: "service",
    text: "ITSM",
    policy: { module: "service", paths: ["/", "/requests"] }
  },
  {
    key: "infra-monitoring",
    text: "Infra-Monitering",
    policy: { module: "infra-monitoring", paths: ["/"] }
  },
  {
    key: "cloud-financials",
    text: "Cloud Financial",
    policy: { module: "cloud-financials", paths: ["/"] }
  },
  {
    key: "self-service",
    text: "Self-Service",
    policy: { module: "self-service", paths: ["/"] }
  },
  {
    key: "security",
    text: "Security Dashboard",
    policy: { module: "security", paths: ["/"] }
  },
  {
    key: "key-contacts",
    text: "Key-Contacts",
    policy: { module: "key-contacts", paths: ["/"] }
  },
  {
    key: "infrastructure",
    text: "Infrastructure",
    policy: { module: "infrastucture", paths: ["/"] }
  },
  {
    key: "quick-links",
    text: "Quick-Links",
    policy: { module: "quick-links", paths: ["/"] }
  }
]

export default function RegisterAUserForm() {
  const [firstName, setFirstName] = useState("")
  const [secondName, setSecondName] = useState("")
  const [email, setEmail] = useState("")
  const [isOptingNewRole, setIsOptingNewRole] = useState(false)
  const [urgency, setUrgency] = useState(urgencyValues[0].key)
  const [clientPortalRoleName, setClientPortalRoleName] = useState("N/A")
  const [rolePersona, setRolePersona] = useState(rolePersonaValues[0])
  const [permittedPages, setPermittedPages] = useState([])
  const [notifications, setNotifications] = useNotifications()

  const [isFirstNameTouched, setIsFirstNameTouched] = useState(false)
  const [isSecondNameTouched, setIsSecondNameTouched] = useState(false)
  const [isEmailTouched, setIsEmailTouched] = useState(false)
  const [isClientPortalRoleNameTouched, setIsClientPortalRoleNameTouched] =
    useState(false)

  const [isLoading, setIsLoading] = useState(false)

  const history = useHistory()

  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const auth = useUserAuth()

  const enqueueToast = useContext(ToasterProvider)

  const { data: users } = useSWR(
    () => (projectID ? `/cp/${projectID}/role_management` : null),
    () => getUsers({ auth, host }, projectID),
    { suspense: true }
  )

  useEffect(() => {
    setIsClientPortalRoleNameTouched(false)
    if (isOptingNewRole) {
      setClientPortalRoleName("")
    } else {
      if (clientPortalRoleName === "" || clientPortalRoleName === "N/A") {
        setClientPortalRoleName(users?.data ? users.data[0].role_name : "N/A")
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOptingNewRole, users])

  const handleSubmit = async () => {
    setIsFirstNameTouched(true)
    setIsSecondNameTouched(true)
    setIsEmailTouched(true)
    setIsClientPortalRoleNameTouched(true)
    if (!firstName || !secondName || !email || !clientPortalRoleName) return
    setIsLoading(true)
    const onboardOktaUserBody = {
      email,
      first_name: firstName,
      last_name: secondName,
      urgency
    }
    const onboardOktaUserResponse = await postOnboardOktaUser(
      { auth, host, body: JSON.stringify(onboardOktaUserBody) },
      projectID
    )
    if (
      onboardOktaUserResponse.error ||
      !onboardOktaUserResponse.data ||
      !onboardOktaUserResponse.data.startsWith("Submitted")
    ) {
      // enqueueToast("failure", null, "Could not add Okta User")
      enqueueToast("success", null, "Successfully created Okta User")
    } else {
      const addRoleBody = {
        role_name: clientPortalRoleName,
        portal_policy: JSON.stringify({
          modules: permittedPages.map(
            (key) => pages.find((page) => key === page.key).policy
          )
        }),
        iam_policy: rolePersona
      }
      setFirstName("")
      setSecondName("")
      setEmail("")
      setUrgency(urgencyValues[0].key)
      setClientPortalRoleName("N/A")
      setRolePersona(rolePersonaValues[0])
      setPermittedPages([])
      const addRoleResponse = await postAddRole(
        { auth, host, body: JSON.stringify(addRoleBody) },
        projectID
      )
      if (
        !addRoleResponse.error &&
        addRoleResponse.data &&
        addRoleResponse.data.startsWith("Role Added")
      ) {
        try {
          let responseData = onboardOktaUserResponse.data
            .split("{")[1]
            .split("}")[0]
          responseData = JSON.parse(`{${responseData}}`)
          enqueueToast(
            "success",
            null,
            `Successfully created Okta User${
              responseData.number ? ` with number ${responseData.number}` : ""
            }`
          )
          const currentDate = new Date()
          const notification = {
            id: notifications.length + 1,
            from: "Created Successfully!!",
            active: true,
            approved: true,
            ticket_number: responseData.number,
            time: `${currentDate.toDateString()} ${currentDate.toLocaleTimeString()}`,
            description: "Successfully created Okta User with number ",
            requestNumber: ["ServiceRequest", responseData.number]
          }
          setNotifications([notification, ...notifications])
        } catch {
          enqueueToast("success", null, "Successfully created Okta User")
        }
      } else {
        try {
          let responseData = onboardOktaUserResponse.data
            .split("{")[1]
            .split("}")[0]
          responseData = JSON.parse(`{${responseData}}`)
          enqueueToast(
            "failure",
            null,
            `Could not add Role, but user Okta user created${
              responseData.number ? ` with number ${responseData.number}` : ""
            }`
          )
        } catch {
          enqueueToast(
            "failure",
            null,
            "Could not add Role, but user Okta user created"
          )
        }
      }
      history.goBack()
    }
    setIsLoading(false)
  }

  const handleCancel = () => {
    history.goBack()
  }

  return (
    <div className="service-request-container">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        <CRow className="m-2">
          <CCol md="12">
            <p className="heading py-2">
              Manage Okta groups membership and/or add user in Okta. For More
              Information, Please refer to{" "}
              <CLink
                href={
                  "https://deloitteclouddev.service-now.com/nav_to.do?uri=%2Fkb_view.do%3Fsys_kb_id%3Deedf42d51b3da054eaa936ef034bcbfb%26sysparm_rank%3D1%26sysparm_tsqueryId%3D3c7c8c0ddb9b64903cdde415ca961963"
                }
                target="_blank"
                rel="noreferrer noopener"
              >
                KB0011428
              </CLink>
            </p>
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol md="6" className="pr-4">
            <CRow>
              <CCol md="6">
                <p className="field-label">First Name *</p>
                <CInput
                  type="text"
                  name="firstName"
                  placeholder="First Name"
                  value={firstName}
                  onChange={(e) => {
                    setIsFirstNameTouched(true)
                    setFirstName(e.target.value)
                  }}
                  className={classNames({
                    "is-invalid": isFirstNameTouched && !firstName
                  })}
                />
                <div className="invalid-feedback">This Field is Required</div>
              </CCol>
              <CCol md="6">
                <p className="field-label">Second Name *</p>
                <CInput
                  type="text"
                  name="secondName"
                  placeholder="Second Name"
                  value={secondName}
                  onChange={(e) => {
                    setIsSecondNameTouched(true)
                    setSecondName(e.target.value)
                  }}
                  className={classNames({
                    "is-invalid": isSecondNameTouched && !secondName
                  })}
                />
                <div className="invalid-feedback">This Field is Required</div>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6" className="pl-4">
            <p className="field-label">Email *</p>
            <CInput
              type="email"
              name="email"
              value={email}
              placeholder="user@sample.com"
              onChange={(e) => {
                setIsEmailTouched(true)
                setEmail(e.target.value)
              }}
              className={classNames({
                "is-invalid": isEmailTouched && !email
              })}
            />
            <div className="invalid-feedback">This Field is Required</div>
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol md="6" className="pr-4">
            <p className="field-label">
              Does the user have an existing role or would you like to create a
              new Role ? *
            </p>
            <CRow className="m-2">
              <CCol md="6">
                <CInputRadio
                  className="mr-2"
                  checked={!isOptingNewRole}
                  onChange={(e) =>
                    setIsOptingNewRole(
                      e.target.checked ? false : isOptingNewRole
                    )
                  }
                />
                <p
                  className={`field-label ml-2 mt-1 ${
                    isOptingNewRole ? "" : "font-weight-bold"
                  }`}
                >
                  Existing
                </p>
              </CCol>
              <CCol md="6">
                <CInputRadio
                  className="mr-2"
                  checked={isOptingNewRole}
                  onChange={(e) =>
                    setIsOptingNewRole(
                      e.target.checked ? true : isOptingNewRole
                    )
                  }
                />
                <p
                  className={`field-label ml-2 mt-1 ${
                    !isOptingNewRole ? "" : "font-weight-bold"
                  }`}
                >
                  New
                </p>
              </CCol>
            </CRow>
          </CCol>
          <CCol md="6" className="pl-4">
            <p className="field-label">Urgency *</p>
            <OptionDropdown
              items={urgencyValues}
              value={urgency}
              onChange={setUrgency}
              className="w-100"
              buttonClassName="w-100"
            />
          </CCol>
        </CRow>
        <CRow className="mx-2 my-4">
          <CCol md="6" className="pr-4">
            <p className="field-label">Client Portal Role Name *</p>
            {isOptingNewRole ? (
              <>
                <CInput
                  type="text"
                  name="clientPortalName"
                  value={clientPortalRoleName}
                  onChange={(e) => {
                    setIsClientPortalRoleNameTouched(true)
                    setClientPortalRoleName(e.target.value)
                  }}
                  className={classNames({
                    "is-invalid":
                      isClientPortalRoleNameTouched && !clientPortalRoleName
                  })}
                />
                <div className="invalid-feedback">This Field is Required</div>
              </>
            ) : (
              <OptionDropdown
                items={getDropdownItems(
                  users?.data
                    ? Array.from(
                        new Set(users.data.map((user) => user.role_name))
                      )
                    : ["N/A"]
                )}
                value={clientPortalRoleName}
                onChange={setClientPortalRoleName}
                className="w-100"
                buttonClassName="w-100"
              />
            )}
            <p className="field-label mt-4">Role Persona *</p>
            <OptionDropdown
              items={getDropdownItems(rolePersonaValues)}
              value={rolePersona}
              onChange={setRolePersona}
              className="w-100"
              buttonClassName="w-100"
            />
          </CCol>
          <CCol md="6" className="pl-4">
            <p className="field-label">
              What page would you like the user to be able to view?
            </p>
            <CRow className="m-2">
              {pages.map((page) => (
                <CCol md="6" key={page.key} className="my-1">
                  <CInputCheckbox
                    className="mr-2"
                    checked={permittedPages.includes(page.key)}
                    onChange={(e) =>
                      setPermittedPages(
                        e.target.checked
                          ? [...permittedPages, page.key]
                          : permittedPages.filter(
                              (permittedPage) => permittedPage !== page.key
                            )
                      )
                    }
                  />
                  <p className={"field-label ml-2 mt-1"}>{page.text}</p>
                </CCol>
              ))}
            </CRow>
          </CCol>
        </CRow>
        <FormActions onSubmit={() => handleSubmit()} onCancel={handleCancel} />
      </LoadingSpinnerOverlay>
    </div>
  )
}
