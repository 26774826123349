import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host, project,ticketId,fileName) => {
    return `${host}/itsm/${project}/${ticketId}/get_attachment/${fileName}`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
