import { dequal } from "dequal"
import { useEffect, useRef } from "react"
import { isAnyAPIFailed } from "utils/nodata"

const shouldSWRResponseBeIgnored = (swrData) => Array.isArray(swrData) ? isAnyAPIFailed(swrData) : swrData?.error

function keepPreviousResponseMiddleware(useSWRNext) {
  return (key, fetcher, config) => {
    // Use a ref to store previous response data that is not ignored.
    const previousDataRef = useRef()

    // Use a ref to store the previous key
    const previousKeyRef = useRef()
    // reset the previous data ref if the key changes
    useEffect(() => {
      const keyNext = key instanceof Function ? key() : key
      if (!dequal(keyNext, previousKeyRef.current)) {
        previousDataRef.current = undefined
        previousKeyRef.current = keyNext
      }
    }, [key])

    const keyNext = key
    // Actual SWR hook.
    const swr = useSWRNext(keyNext, fetcher, config)

    useEffect(() => {
      // Update previous ref if the response is not to be ignored.
      if (!shouldSWRResponseBeIgnored(swr.data)) {
        previousDataRef.current = swr.data
      }
    }, [swr.data])

    // Fallback to previous data if current response is to be ignored.
    const dataOrLaggyData = shouldSWRResponseBeIgnored(swr.data) ? (previousDataRef.current || swr.data) : swr.data

    return Object.assign({}, swr, { data: dataOrLaggyData })
  }
}

export default keepPreviousResponseMiddleware