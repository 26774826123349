import { CDropdown, CDropdownMenu, CDropdownToggle } from "@coreui/react"
import { format } from "date-fns"
import PropTypes from "prop-types"
import React from "react"

import calendarIcon from "../../../assets/icons/calendar.svg"
import DateRangePicker from "../dateRangePicker"

function DatePickerInput({
  className,
  buttonClassName,
  emptyText,
  onChange,
  value,
  range,
  minDate
}) {
  return (
    <CDropdown className={className}>
      <CDropdownToggle
        className={`bg-white text-dark d-flex align-items-center justify-content-between ${buttonClassName}`}
        caret
      >
        {value ? format(value, "dd-MM-yyyy") : emptyText}
        <img
          src={calendarIcon}
          className="ml-3"
          width={22}
          height={22}
          alt="dropdown"
        />
      </CDropdownToggle>
      <div className="invalid-feedback">This field is required</div>
      <CDropdownMenu>
        <DateRangePicker
          dateRange={range}
          minDate={minDate}
          value={value}
          onChange={(dateValue) => {
            onChange(dateValue)
          }}
        />
      </CDropdownMenu>
    </CDropdown>
  )
}

DatePickerInput.defaultProps = {
  className: "",
  buttonClassName: "",
  value: new Date(),
  onChange: () => {},
  emptyText: "No date Selected"
}

DatePickerInput.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  emptyText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.instanceOf(Date),
  range: PropTypes.bool,
  minDate: PropTypes.string
}

export default DatePickerInput
