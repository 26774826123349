import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  makeRequestUrl: (host) => {
    return `${host}/currencies/list`
  },
  checkRequiredArguments: () => {},
  makeResponseData: (json) => {
    return Object.keys(json?.currencies ?? {})
  }
}
export default createFetcher(fetcherConfiguration)
