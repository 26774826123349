import React from "react"
import { useSelector } from "react-redux"
import { computeAllowedPaths } from "utils"

export default function () {
  const policy = useSelector((state) => state?.user?.policy)
  const allowedPaths = React.useMemo(() => computeAllowedPaths(policy), [
    policy
  ])
  return allowedPaths
}
