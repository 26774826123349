import { createFetcher } from "api/helpers"
const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, project, params) => {
    return `${host}/security/${project}/get_security_agent_compliance_details?${params}`
  },
  checkRequiredArguments: (params) => {
    if (params === undefined);
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
