import PropTypes from "prop-types"
import React, { useContext, useEffect } from "react"
import themes from "styles/style.scss"

const { createContext, useState } = React
const ThemeContext = createContext(null)

function ThemeContextProvider(props) {
  const [selectedThemeClassName, setSelectedThemeClassName] =
    useState("theme-deloitte")

  useEffect(() => {
    const selectedTheme = localStorage.getItem("themeSelected")
    if (selectedTheme) {
      setSelectedThemeClassName(selectedTheme)
    }
  }, [])

  const theme = Object.keys(themes)
    .filter((key) => key.startsWith(selectedThemeClassName))
    .reduce(
      (obj, key) => ({
        ...obj,
        [key.split("-")[2]]: themes[key]
      }),
      {}
    )

  return (
    <ThemeContext.Provider
      value={{
        theme,
        selectedThemeClassName,
        setSelectedThemeClassName
      }}
    >
      <div className={selectedThemeClassName}>{props.children}</div>
    </ThemeContext.Provider>
  )
}

const useTheme = () => {
  const { theme } = useContext(ThemeContext)
  return theme
}

ThemeContextProvider.propTypes = {
  children: PropTypes.node
}

export default ThemeContext
export { ThemeContextProvider, useTheme }
