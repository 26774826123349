import { CCol, CInput, CRow } from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"

export function ProvisionZone({ provZonebject, onProvZoneChange }) {
  return (
    <>
      <CRow className="mx-2 mt-3 mb-4">
        <CCol xs="12" md="6">
          <p className="field-label">Azure Virtual Machine Template Location</p>
          <CInput
            type="text"
            name="vmTemplateLocation"
            defaultValue={provZonebject.vmTemplateLocation}
            onBlur={(e) =>
              onProvZoneChange("vmTemplateLocation", e.target.value)
            }
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Virtual Machine RG
          </p>
          <CInput
            type="text"
            name="vmRg"
            defaultValue={provZonebject.vmRg}
            onBlur={(e) => onProvZoneChange("vmRg", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Network Interface Name
          </p>
          <CInput
            type="text"
            name="networkInterfaceName"
            defaultValue={provZonebject.networkInterfaceName}
            onBlur={(e) =>
              onProvZoneChange("networkInterfaceName", e.target.value)
            }
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Azure Virtual Machine Os Disk Type</p>
          <CInput
            type="text"
            name="osDiskType"
            defaultValue={provZonebject.osDiskType}
            onBlur={(e) => onProvZoneChange("osDiskType", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">Azure Virtual Machine Subnet Name</p>
          <CInput
            type="text"
            name="subnetName"
            defaultValue={provZonebject.subnetName}
            onBlur={(e) => onProvZoneChange("subnetName", e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Virtual Machine Size
          </p>
          <CInput
            type="text"
            name="vmSize"
            defaultValue={provZonebject.vmSize}
            onBlur={(e) => onProvZoneChange("vmSize", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Virtual Machine Network ID
          </p>
          <CInput
            type="text"
            name="virtualNetworkId"
            defaultValue={provZonebject.virtualNetworkId}
            onBlur={(e) => onProvZoneChange("virtualNetworkId", e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Azure Virtual Machine Admin User name</p>
          <CInput
            type="text"
            name="username"
            placeholder="Admin Username"
            defaultValue={provZonebject.adminUsername}
            onBlur={(e) => onProvZoneChange("adminUsername", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Virtual Machine Name
          </p>
          <CInput
            type="text"
            name="vmName"
            defaultValue={provZonebject.vmName}
            onBlur={(e) => onProvZoneChange("vmName", e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">Azure Virtual Machine Admin Password</p>
          <CInput
            type="password"
            name="adminPassword"
            defaultValue={provZonebject.adminPassword}
            onBlur={(e) => onProvZoneChange("adminPassword", e.target.value)}
          />
        </CCol>
      </CRow>
      <CRow className="mx-2 my-4">
        <CCol xs="12" md="6">
          <p className="field-label">
            Azure Virtual Machine Virtual Machine Computer Name
          </p>
          <CInput
            type="text"
            name="vmComputerName"
            defaultValue={provZonebject.vmComputerName}
            onBlur={(e) => onProvZoneChange("vmComputerName", e.target.value)}
          />
        </CCol>
        <CCol xs="12" md="6">
          <p className="field-label">
            Re-Enter Azure Virtual Machine Admin Password
          </p>
          <CInput
            type="password"
            name="reEnterAdminPassword"
            defaultValue={provZonebject.reEnterAdminPassword}
            onBlur={(e) =>
              onProvZoneChange("reEnterAdminPassword", e.target.value)
            }
          />
        </CCol>
      </CRow>
    </>
  )
}
ProvisionZone.propTypes = {
  provZonebject: PropTypes.object,
  onProvZoneChange: PropTypes.func
}
