/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import PropTypes from "prop-types"
import { Card } from "reactstrap"

import { WentWrong } from "."

const styles = {
  wrapper: css`
    position: relative;
    height: 100%;
  `,
  card: css`
    min-height: 200px;
    height: 90%;
  `
}

function WentWrongCard ({ imageClassName }) {
  return (
    <div css={styles.wrapper}>
      <Card css={styles.card}>
        <WentWrong className="my-2" imageClassName={imageClassName} />
      </Card>
    </div>
  )
}

WentWrongCard.defaultProps = {
  imageClassName: ""
}

WentWrongCard.propTypes = {
  imageClassName: PropTypes.string
}

export default WentWrongCard
