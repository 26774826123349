import {
  CCard,
  CInput,
  CInputGroup,
  CInputGroupText,
  CRow,
  CInputCheckbox
} from "@coreui/react"
import downArrow from "assets/icons/downArrow.svg"
import classNames from "classnames"
import MediaContext from "components/common/Context/MediaContext"
import { NO_CARDS_PER_PAGE, PAGE_NO_LIMIT } from "constants/Constants"
import PropTypes from "prop-types"
import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import { FaAngleDown, FaSearch } from "react-icons/fa"
import { TiTimes } from "react-icons/ti"
import { formatCurrency } from "utils"

import { SORT_ORDERS } from "../drawer/constants"
import { MultipleExport } from "../export"
import Pagination from "../pagination/Pagination"
import { SidebarTooltipWrapper } from "../tooltip"
import MultiSelectHeader from "./MultiSelectHeader"
import TagSearch from "./TagSearch"

const customTextClass = (text) => {
  if (text === "active") return "bg-success"
  if (text === "warning") return "bg-warning"
  if (text === "inactive") return "bg-danger"
  return "bg-secondary"
}

export const getExcelDataFromTableData = (name, headers, data) => ({
  name,
  columns: headers.map((header) => header.text),
  data: data?.map((eachData) =>
    headers.map((header) => ({ value: eachData[header.key].text }))
  )
})

export default function Table({
  headers = [],
  data = [],
  disableSearch,
  disableSort,
  disableExport,
  enableTagSearch = true,
  defaultSearchText,
  defaultSortKey = "",
  defaultSelectedTags,
  excelFilename,
  // customExcelDatasets,
  noDataComponent,
  currencySymbol,
  fxRate
}) {
  const wrapperRef = useRef(null)
  const { isSmallScreen } = useContext(MediaContext)
  const [noOfCards, setNoOfCards] = useState(NO_CARDS_PER_PAGE)
  const [searchText, setSearchText] = useState("")
  const [selectedTags, setSelectedTags] = useState({})
  const [tagSearchText, setTagSearchText] = useState("")
  const [isTagSearchDropdownOpen, setIsTagSearchDropdownOpen] = useState(false)
  const [selectedKeyForTagSearch, setSelectedKeyForTagSearch] = useState()
  const [sortKey, setSortKey] = useState(defaultSortKey)
  const [sortOrder, setSortOrder] = useState(
    defaultSortKey ? SORT_ORDERS.DESCENDING : SORT_ORDERS.NONE
  )

  const [itemsPerPage, setItemsPerPage] = useState(20)
  const [noOfPages, setNoOfPages] = useState(1)
  const [noOfRecords, setNoOfRecords] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [maxLimit, setMaxLimit] = useState(5)
  const [minLimit, setMinLimit] = useState(0)

  const searchInputRef = useRef(null)

  const canCardsBeExpandable = useMemo(
    () => Boolean(headers.find((header) => header?.showOnCollapsedCard)),
    [headers]
  )

  useEffect(() => {
    if (defaultSearchText) {
      setSearchText(defaultSearchText)
      searchInputRef.current && searchInputRef.current.focus()
    }
  }, [defaultSearchText])

  useEffect(() => {
    if (sortOrder === SORT_ORDERS.NONE) {
      setSortKey(null)
    }
  }, [sortOrder])

  useEffect(() => {
    if (defaultSelectedTags) {
      setSelectedTags(defaultSelectedTags)
    }
  }, [defaultSelectedTags])

  const multiSelectFilterData = useMemo(() => {
    const filterableHeaders = headers.filter(
      (header) => header.multiSelectFilter
    )
    return data.filter((eachData) =>
      filterableHeaders.every((eachHeader) =>
        eachHeader.multiselectedKeys.includes(eachData[eachHeader.key].text)
      )
    )
  }, [data, headers])

  const keyValuePairs = useMemo(() => {
    if (!enableTagSearch) return []
    return headers.reduce(
      (acc, eachHeader) => [
        ...acc,
        ...[
          ...new Set(
            data.reduce(
              (acc, eachData) =>
                eachData[eachHeader.key].tagSearchItems
                  ? [...acc, ...eachData[eachHeader.key].tagSearchItems]
                  : [...acc, eachData[eachHeader.key].text],
              []
            )
          )
        ].map((eachValue) => ({
          key: eachHeader.key,
          value: eachValue,
          count: multiSelectFilterData?.filter((eachData) =>
            Object.entries({
              ...selectedTags,
              [eachHeader.key]: [eachValue]
            }).every(([selectedKey, selectedValues]) =>
              selectedValues.some((selectedValue) =>
                eachData[selectedKey]?.tagSearchItems
                  ? eachData[selectedKey]?.tagSearchItems.includes(
                      selectedValue
                    )
                  : eachData[selectedKey]?.text === selectedValue
              )
            )
          ).length
        }))
      ],
      []
    )
  }, [data, enableTagSearch, headers, multiSelectFilterData, selectedTags])

  const filteredData = useMemo(() => {
    if (disableSearch) return multiSelectFilterData
    if (enableTagSearch) {
      const lowerTagSearchText = tagSearchText.toLowerCase()
      return multiSelectFilterData
        .filter((eachData) =>
          Object.entries(selectedTags).every(([selectedKey, selectedValues]) =>
            selectedValues.some((selectedValue) => {
              return eachData[selectedKey]?.tagSearchItems
                ? eachData[selectedKey]?.tagSearchItems.includes(selectedValue)
                : eachData[selectedKey]?.text === selectedValue
            })
          )
        )
        .filter((eachData) =>
          headers.some((eachHeader) =>
            `${eachData[eachHeader.key].text || ""}`
              .toLowerCase()
              ?.includes(lowerTagSearchText)
          )
        )
    } else {
      const lowerSearchText = searchText.toLowerCase()
      return multiSelectFilterData.filter((eachData) =>
        headers.some((eachHeader) =>
          `${eachData[eachHeader.key].text || ""}`
            .toLowerCase()
            ?.includes(lowerSearchText)
        )
      )
    }
  }, [
    disableSearch,
    multiSelectFilterData,
    enableTagSearch,
    selectedTags,
    searchText,
    headers,
    tagSearchText
  ])

  const sortedData = useMemo(() => {
    if (disableSort || sortOrder === SORT_ORDERS.NONE) return filteredData
    return [...filteredData].sort((aData, bData) =>
      aData[sortKey].text > bData[sortKey].text ? sortOrder : -sortOrder
    )
  }, [disableSort, filteredData, sortKey, sortOrder])
  const sortedExcelDataset = useMemo(
    () => getExcelDataFromTableData(excelFilename, headers, sortedData),
    [sortedData, excelFilename, headers]
  )
  const [isAddingMoreCards, setIsAddingMoreCards] = useState(false)
  const [expandedCardKeys, setExpandedCardKeys] = useState([])
  useEffect(() => {
    setNoOfRecords(filteredData.length)
    setNoOfPages(Math.ceil(filteredData.length / itemsPerPage))
  }, [filteredData.length, itemsPerPage])

  useEffect(() => {
    if (isAddingMoreCards) {
      setTimeout(() => {
        setNoOfCards(noOfCards + NO_CARDS_PER_PAGE)
        setIsAddingMoreCards(false)
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddingMoreCards])

  useEffect(() => {
    const handleScroll = () => {
      if (
        isSmallScreen &&
        noOfCards < sortedData.length &&
        wrapperRef.current &&
        wrapperRef.current?.parentElement.clientHeight +
          wrapperRef.current?.parentElement?.scrollTop >=
          wrapperRef.current?.offsetHeight
      ) {
        setIsAddingMoreCards(true)
      }
    }
    window.addEventListener("scroll", handleScroll, true)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [isSmallScreen, noOfCards, sortedData.length])

  const paginatedData = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage
    const indexOfFirstItem = indexOfLastItem - itemsPerPage
    return sortedData?.slice(indexOfFirstItem, indexOfLastItem)
  }, [currentPage, itemsPerPage, sortedData])

  const excelDataset = useMemo(
    () => getExcelDataFromTableData(excelFilename, headers, data),
    [data, excelFilename, headers]
  )

  if (isSmallScreen)
    return (
      <div ref={wrapperRef}>
        <div className="d-flex mb-3">
          <CInputGroup>
            <CInput
              placeholder="Search"
              className="drawer-search-input"
              value={searchText}
              ref={searchInputRef}
              onChange={(e) => setSearchText(e.target.value)}
            />
            <CInputGroupText className="bg-white drawer-search-icon">
              <TiTimes
                size="25px"
                className="cursor-pointer"
                onClick={() => setSearchText("")}
              />
            </CInputGroupText>
          </CInputGroup>
          <MultipleExport
            fileName={excelFilename}
            excelDatasets={[excelDataset, sortedExcelDataset]}
          />
        </div>
        {Array.isArray(sortedData) && sortedData.length > 0
          ? sortedData.slice(0, noOfCards).map((eachData) => (
              <CCard key={eachData._key}>
                {headers
                  .filter(
                    (header) =>
                      !canCardsBeExpandable ||
                      expandedCardKeys.includes(eachData._key) ||
                      header.showOnCollapsedCard
                  )
                  .map((header) => (
                    <div
                      key={header.key}
                      className="d-flex justify-content-between align-items-center w-100 px-1"
                    >
                      <div className="w-50 mbl-table-heading">
                        {header.ele || header.text}
                      </div>
                      <div className="w-50 mbl-table-value">
                        {
                        header.key.includes("PotentialSavings") || header.key.includes("CurrentVolumeRate") ||
                        header.key.includes("PotentialVolumeRate") || header.key.includes("CurrentHourlyRate")
                        || header.key.includes("PotentialHourlyRate") || header.key.includes("Cost")
                             ? formatCurrency(eachData[header.key].text, currencySymbol, fxRate)
                             : eachData[header.key].ele || eachData[header.key].text
                        }
                      </div>
                    </div>
                  ))}
                {canCardsBeExpandable && (
                  <div className="w-100 d-flex justify-content-center">
                    <img
                      src={downArrow}
                      className={`${
                        expandedCardKeys.includes(eachData._key)
                          ? "inverted"
                          : ""
                      } collapse-icon`}
                      onClick={() =>
                        setExpandedCardKeys(
                          expandedCardKeys.includes(eachData._key)
                            ? expandedCardKeys.filter(
                                (key) => key !== eachData._key
                              )
                            : [...expandedCardKeys, eachData._key]
                        )
                      }
                    />
                  </div>
                )}
              </CCard>
            ))
          : noDataComponent}
      </div>
    )

  return (
    <>
      <div className="stat-div">
        {!disableSearch &&
          (enableTagSearch ? (
            <div className="flex-1">
              <TagSearch
                keyValuePairs={keyValuePairs}
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                keyToDisplayTextMap={headers.reduce(
                  (acc, eachHeader) => ({
                    ...acc,
                    [eachHeader.key]: eachHeader.text
                  }),
                  {}
                )}
                setTagSearchText={setTagSearchText}
                isDropdownOpen={isTagSearchDropdownOpen}
                setIsDropdownOpen={setIsTagSearchDropdownOpen}
                selectedKey={selectedKeyForTagSearch}
                setSelectedKey={setSelectedKeyForTagSearch}
              />
            </div>
          ) : (
            <div className="d-flex align-items-center">
              <CInputGroup>
                <CInput
                  placeholder="Search"
                  className="drawer-search-input"
                  value={searchText}
                  ref={searchInputRef}
                  onChange={(e) => setSearchText(e.target.value)}
                />
                <CInputGroupText className="bg-white drawer-search-icon">
                  <FaSearch size="20px" />
                </CInputGroupText>
              </CInputGroup>
              <TiTimes
                size="30px"
                className="cursor-pointer"
                onClick={() => setSearchText("")}
              />
            </div>
          ))}
        {!disableExport && (
          <div className="d-flex w-10">
            <MultipleExport
              fileName={excelFilename}
              excelDatasets={[excelDataset, sortedExcelDataset]}
            />
          </div>
        )}
      </div>
      <div className="drawer-content">
        <table className="x-scroll-table">
          <thead>
            <tr className="table-header display-mobile-disabled">
              {Array.isArray(headers) &&
                headers.map((header, index) => (
                  <th
                    key={header.key}
                    className={classNames({
                      "sorted-color": sortKey === header.key,
                      "border-right": index !== headers.length - 1
                    })}
                  >
                    <div className="col table-header-data justify-content-center">
                      {header.multiSelectFilter ? (
                        <MultiSelectHeader
                          options={header.multiSelectOptions}
                          selectedKeys={header.multiselectedKeys}
                          setSelectedKeys={header.setMultiSelectedKeys}
                          headerText={header.text}
                        />
                      ) : (
                        <p
                          onClick={() => {
                            if (enableTagSearch) {
                              setSelectedKeyForTagSearch(header.key)
                              setIsTagSearchDropdownOpen(true)
                            }
                          }}
                          className={classNames({
                            "cursor-pointer": enableTagSearch
                          })}
                        >
                          {header.ele || header.text}
                        </p>
                      )}{" "}
                      {!disableSort && (
                        <FaAngleDown
                          className={classNames("sort-icon-style", {
                            inverted:
                              sortKey === header.key &&
                              sortOrder === SORT_ORDERS.DESCENDING
                          })}
                          onClick={() => {
                            if (sortKey === header.key) {
                              setSortOrder(((sortOrder + 2) % 3) - 1)
                            } else {
                              setSortKey(header.key)
                              setSortOrder(SORT_ORDERS.ASCENDING)
                            }
                          }}
                        />
                      )}
                    </div>
                  </th>
                ))}
            </tr>
          </thead>
          <tbody>
            {Array.isArray(paginatedData) && paginatedData.length > 0 ? (
              paginatedData.map((eachData, i) => (
                <tr
                  className={`table-row display-mobile-disabled ${
                    eachData?._onClick ? "cursor-pointer" : ""
                  }`}
                  key={eachData._key}
                  onClick={() => {
                    if (eachData?._onClick) {
                      eachData._onClick()
                    }
                  }}
                >
                  {Array.isArray(headers) &&
                    headers.map((header, index) => (
                                            
                      <td
                        key={`${eachData._key}-${header.key}`}
                        className={classNames({
                          "border-right": index !== headers.length - 1
                        })}
                        data-testid={`${header.key}-${i}`}
                      >
                        {
                          header.tooltipRequired ? (
                          <SidebarTooltipWrapper
                            position={header.tooltipPosition || "right"}
                            showTooltip
                            tableClass
                            tooltipText={eachData[header.key].text}
                          >
                            <div className="table-row-data max-width-200 white-space-break">
                     
                              {eachData[header.key].ele || eachData[header.key].text}
                             
                            </div>
                          </SidebarTooltipWrapper>
                        ) : header.text.includes("/") ? (
                          <CInputCheckbox
                           className="mr-2 ml-5"
                           checked={eachData[header.key].text === "true"}
                           readOnly = {true}/>):
                           (
                          <div className="table-row-data-1">
                            {header.badgeText ? (
                              <>
                                <span
                                  className={`badge ${customTextClass(
                                   eachData[header.key].text
                                  )}`}
                                >
                                   {eachData[header.key].ele || eachData[header.key].text}
                                </span>
                              </>
                            ) : (
                              header.key.includes("PotentialSavings") || header.key.includes("CurrentVolumeRate") ||
                              header.key.includes("PotentialVolumeRate") || header.key.includes("CurrentHourlyRate")
                              || header.key.includes("PotentialHourlyRate") || header.key.includes("Cost")
                              ? formatCurrency(eachData[header.key].text, currencySymbol, fxRate) 
                              : eachData[header.key].ele || eachData[header.key].text
                            )}
                          </div>
                        )}
                      </td>
                            
                    ))}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={headers.length}>{noDataComponent}</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="d-flex flex-column flex-fill justify-content-end">
        <CRow md="12" className="drawer-footer">
          {noOfPages > 0 ? (
            <Pagination
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              minLimit={minLimit}
              setMinLimit={setMinLimit}
              maxLimit={maxLimit}
              setMaxLimit={setMaxLimit}
              pageNumberLimit={PAGE_NO_LIMIT}
              noOfPages={noOfPages}
              noOfRecords={noOfRecords}
              hideItemsPerPageDropdown={false}
            />
          ) : null}
        </CRow>
      </div>
    </>
  )
}

Table.propTypes = {
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  disableSearch: PropTypes.bool,
  disableSort: PropTypes.bool,
  disableExport: PropTypes.bool,
  defaultSearchText: PropTypes.string,
  defaultSortKey: PropTypes.string,
  excelFilename: PropTypes.string.isRequired,
  defaultSelectedTags: PropTypes.object,
  customExcelDatasets: PropTypes.array,
  noDataComponent: PropTypes.node,
  enableTagSearch: PropTypes.bool,
  currencySymbol: PropTypes.string,
  fxRate: PropTypes.number
}
