import {
  CButton,
  CDropdown,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react"
import PropTypes from "prop-types"
import React from "react"
import { FaRegClock } from "react-icons/fa"

function TimePickerInput({
  className,
  buttonClassName,
  emptyText,
  onChange,
  value
}) {
  const selectedTime = new Date()
  if (value?.split(":")[0] && value?.split(":")[1]) {
    selectedTime.setHours(value?.split(":")[0], value?.split(":")[1])
  }

  const selectedHour = selectedTime.getHours()
  const selectedMin = selectedTime.getMinutes()

  const handleChange = (hour, min) => {
    onChange(`${`0${hour}`.slice(-2)}:${("0" + min).slice(-2)}`)
  }
  return (
    <CDropdown className={className}>
      <CDropdownToggle
        className={`bg-white text-dark d-flex align-items-center justify-content-between ${buttonClassName}`}
        caret
      >
        {value || emptyText}
        <FaRegClock size="20px" className="ml-3" />
      </CDropdownToggle>
      <div className="invalid-feedback">This field is required</div>
      <CDropdownMenu className="p-0 overflow-hidden">
        <div className="d-flex max-height-inherit">
          <div className="d-flex flex-column max-height-inherit overflow-auto time-picker-scroll">
            {[...Array(24).keys()].map((hour) => (
              <CButton
                key={hour}
                className={`time-btn ${hour === selectedHour ? "active" : ""}`}
                onClick={() => handleChange(hour, selectedMin)}
              >
                {`0${hour}`.slice(-2)}
              </CButton>
            ))}
          </div>
          <div className="d-flex flex-column max-height-inherit overflow-auto time-picker-scroll">
            {[...Array(60).keys()].map((minute) => (
              <CButton
                key={minute}
                className={`time-btn ${minute === selectedMin ? "active" : ""}`}
                onClick={() => handleChange(selectedHour, minute)}
              >
                {("0" + minute).slice(-2)}
              </CButton>
            ))}
          </div>
        </div>
      </CDropdownMenu>
    </CDropdown>
  )
}

TimePickerInput.defaultProps = {
  className: "",
  buttonClassName: "",
  onChange: () => {},
  emptyText: "No time Selected"
}

TimePickerInput.propTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  emptyText: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string
}

export default TimePickerInput
