import React from "react"

export const accountSelectTooltip = (
  <>
    <p>
      -{" "}
      {
        "Choose the account you would like to see detailed information about (multiple accounts may be selected)."
      }
    </p>
    <p>
      -{" "}
      {
        "ServiceNow Tickets with an empty 'Cloud Service Account' field will not reflect in the Portal."
      }
    </p>
  </>
)

export const responseTooltip =
  "Please note: Survey cannot be submitted anonymously if response is requested back from CMS Team."
export const cmsReportsTooltip = (
  <>
    <p>
      Search for and download reports <br />
      shared by Deloitte in this shared <br />
      repository.
    </p>
    <p>
      <b>
        Note that only Deloitte team <br /> members can upload files.
      </b>
    </p>
  </>
)
export const totalSpendTooltip =
  "This chart gives a Cloud Service Provider cost breakdown of the project's accounts."
export const top5ResourcesTooltip = "CSP Spend by current month and previous month"
export const accountSpendThisMonth = (
  <>
    <p>
      <i>Comparison and Prediction: </i>Below chart denotes total spending for
      the previous month, current month and predicted spending.
    </p>
    <p>
      There may be a slight delay in processing financial data for the first
      couple of days of the month.
    </p>
  </>
)
export const accountBreakdown =
  "Per account breakdown of spend during month vs predicted spend"
export const automatedOptimizationTooltip =
  "System generated optimization recommendations for Compute, Database and Storage. Based on usage levels during the previous 30 days."
export const resourceFilterTooltip =
  "User may filter by Resource / Account / Amount to display on the accompanying Chart. Drop down allows user to view current or previous months."
export const vulnerabilityOverviewTooltip =
  "Clicking on an individual row in the table section will display more detailed information on that vulnerability. Vulnerabilities are considered non-compliant if they are not remediated within 30 days (60 Days for Medium and below severity). An instance is marked as non-compliant if it has one or more non-compliant vulnerabilities."
export const backupComplianceTooltip = (
  <>
    <p>
      The Backup Compliance widget shows status information for the VM’s hosted
      in your Cloud Accounts.
    </p>
    <p>
      <b>Backed Up:</b> VM snapshots have completed successfully within their
      scheduled window.
    </p>
    <p>
      <b>Not Backed Up:</b> VM snapshots have failed or have not been
      configured. Check the detailed status table.
    </p>
  </>
)
export const incidentsOverviewTooltip =
  "Displays Total, Open and Closed Incidents assigned to Security Category by time period selected from the drop down."
export const dateFilterTooltip =
  "Select a pre-defined period or custom period from the calendar."
export const incidentsTooltip = (
  <>
    <p>(Data is displayed based on the above selected timeframe)</p>
    <p>Open Incidents:</p>
    <p>All Incident tickets not closed or resolved.</p>
    <p>Resolved Incidents:</p>
    <p>All Incident tickets that are either closed or resolved.</p>
    <p>Clicking on the drawer will take you to the ServiceNow native tool.</p>
  </>
)
export const serviceRequestTooltip = (
  <>
    <p>(Data is displayed based on the above selected timeframe)</p>
    <p>Open Service Requests:</p>
    <p>All Service Requests tickets not closed or resolved.</p>
    <p>Closed Service Requests:</p>
    <p>All Service Requests that are closed.</p>
    <p>Clicking on the drawer will take you to the ServiceNow native tool.</p>
  </>
)
export const securityComplianceTooltip = (
  <>
    <p>
      Clickable Chart represents Compliant versus Non-Compliant OS
      Vulnerabilities per Account. By clicking on the Bar Chart, you will be
      taken to the Security and Compliance detail page.
    </p>
    <p>
      <i>
        Please note: Vulnerability becomes non-compliant when its deadline to
        fix is missed unless it has an approved Change Request Exception from
        Project Sponsor Deadline to fix OS vulnerabilities: Critical & High
        –&gt; 30 days | Medium –&gt; 60 days
      </i>
    </p>
  </>
)