export default function () {
  let lock = Symbol("Lock")
  let isLocked = false
  return {
    acquire () {
      if (isLocked) {
        console.error("Lock is already held.")
        return false
      }
      isLocked = true
      return lock
    },
    release (obj) {
      if (obj !== lock) {
        console.error("Release failed. Object passed does not match lock.")
        return false
      }
      lock = Symbol("Lock")
      isLocked = false
      return true
    }
  }
}
