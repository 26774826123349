import NoDataIcon from "assets/icons/no_data.svg"
import PropTypes from "prop-types"
import React from "react"

function NoData ({ header, text, className, imageClassName }) {
  return (
    <div
      className={`${className} d-flex flex-column align-items-center justify-content-center flex-fill`}
    >
      <img
        src={NoDataIcon}
        alt="no-data-icon"
        className={`${imageClassName} mb-1 no-data-img`}
      />
      <p className="mb-1 font-weight-bold medium-text no-data-color text-center">
        {header}
      </p>
      <p className="no-data-color text-center">{text}</p>
    </div>
  )
}

NoData.defaultProps = {
  header: "NO DATA TO DISPLAY!",
  text: "There is no data to display at this time.",
  className: "",
  imageClassName: ""
}

NoData.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  imageClassName: PropTypes.string
}

export default NoData
