export default function ({ auth, method = "get", signal = null, body = null }) {
  const { email, token } = auth
  if (email === undefined) { throw Error("Cannot get email for authentication: email is undefined.") }
  if (token === undefined) { throw Error("Cannot get token for authentication: token is undefined.") }

  const requestOptions = {
    method: method,
    mode: "cors",
    signal,
    headers: {
      Accept: "application/json, text/plain, */*",
      Authorization: `Bearer ${token}`,
      client_id: email
    }
  }
  if (typeof method !== "string") throw Error("Invalid method argument.")
  if (
    (method.toLowerCase() === "post" || method.toLowerCase() === "put") &&
    body
  ) {
    requestOptions.body = body
  }
  return Object.freeze(requestOptions)
}
