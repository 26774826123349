import { createFetcher } from "api/helpers"

const fetcherConfig = {
  makeUrl: (host, project) => {
    return `${host}/security/${project}/get_vuln_count`
  },
  checkArgs: (project) => {
    if (!project) {
      throw Error("Project is required.")
    }
  },
  makePayload: (data) => {
    return data
  }
}
export default createFetcher(fetcherConfig)
