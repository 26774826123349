import React from "react"
import { useExtension, useUserAuth } from "utils"

export default function (fetcher) {
  const auth = useUserAuth()
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  if (host === undefined) throw new Error("No api hostname specified.")
  const _fetcher = React.useCallback(
    async (...args) => (await fetcher({ auth, host }, ...args)).data,

    [auth, host, fetcher]
  )
  return _fetcher
}
