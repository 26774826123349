import PropTypes from "prop-types"
import React from "react"
import RequestSuccess from "./RequestSuccess"

const getSuccessMessage = (serviceProvider) => {
  switch (serviceProvider) {
    case "AWS":
      return (
        `Thank you for submitting your request for the AWS Sandbox Account. Once the Business Owner has
          approved the request, the creation process will begin.`
      )
    case "AZURE":
      return (
        `Thank you for submitting your request for the Azure Sandbox Subscription. Once the Business Owner has
          approved the request, the creation process will begin.`
      )
    case "GCP":
      return (
          `Thank you for submitting your request for the GCP Sandbox Project. Once the Business Owner has
          approved the request, the creation process will begin.`
      )
  }
}

export default function SandboxRequestSuccess({ requestNumber, serviceProvider }) {

  return (
    <RequestSuccess
        requestNumber={requestNumber}  
    >{getSuccessMessage(serviceProvider)}</RequestSuccess>
  )
}

SandboxRequestSuccess.propTypes = {
  requestNumber: PropTypes.string,
  serviceProvider: PropTypes.string
}
