import PropTypes from "prop-types"
import React from "react"

export default function ListComponent(props) {
  const { item, color, onClick, selectedLegends } = props
  return (
    <>
      <div
        className={`px-3 d-flex justify-content-left space-ticket align-items-center cursor-pointer mt-1 ${
          selectedLegends.includes(item)
            ? " w-fit-content font-weight-bold sorted-color"
            : ""
        }`}
        onClick={onClick}
      >
        <div className="mr-1 legend-design " style={{ background: color }} />
        <div className="mx-1">
          <p className="small-Text">{item}</p>
        </div>
      </div>
    </>
  )
}
ListComponent.propTypes = {
  item: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  selectedLegends: PropTypes.array
}