import { CButton, CContainer } from "@coreui/react"
import DeloitteLogo from "assets/icons/brand/Deloitte.svg"
import { ReactComponent as HamburgerIcon } from "assets/icons/hamburgerIcon.svg"
import classNames from "classnames"
import MediaProvider from "components/common/Context/MediaContext"
import { GreyedOut } from "components/common/display"
import { LoadingMessage } from "components/common/loading"
import PropTypes from "prop-types"
import React, { Suspense, useContext,useEffect } from "react"
import { SWRConfig } from "swr"
import { conditionalFetchingMiddleware, keepPreviousResponseMiddleware } from "utils/swrMiddlewares"

import ProjectAccountsMonitor from "./ProjectAccountsMonitor"

const DefaultHeader = React.lazy(() => import("./DefaultHeader"))
const DefaultAside = React.lazy(() => import("./DefaultAside"))


export function DefaultLayout ({ isAuthenticated, children }) {
    const { isSmallScreen, setIsSmallScreen } = useContext(MediaProvider)
    useEffect(() => {
    const handleMediaChange = (media) => {
      if (media.matches) {
        setIsSmallScreen(true)
      } else setIsSmallScreen(false)
    }
    const mediaQuery = window.matchMedia("(max-width: 600px)")
    mediaQuery.addListener(handleMediaChange)
    handleMediaChange(mediaQuery)
    return () => {
      mediaQuery.removeListener(handleMediaChange)
    }
  }, [setIsSmallScreen])
  const [sidebarIsOpen, setSidebarIsOpen] = React.useState(false)
  const [mblSidebar, setMblSidebar] = React.useState(false)
  const sidebarRef = React.useRef(null)

  useEffect(() => {
    function handleClickOutside(event) {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setMblSidebar(false)
      }
    }

    document.addEventListener("mousedown", handleClickOutside)
    return () => {
      document.removeEventListener("mousedown", handleClickOutside)
    }
  }, [sidebarRef, setMblSidebar])

  const sidebar = isAuthenticated
    ? (
    <Suspense fallback={<LoadingMessage />}>
      <DefaultAside isOpen={sidebarIsOpen} setIsOpen={setSidebarIsOpen} />
    </Suspense>
      )
    : (
        ""
      )

  const header = isAuthenticated
    ? (
      <Suspense fallback={<LoadingMessage />}>
        <DefaultHeader
          sidebarIsOpen={sidebarIsOpen}
          setSidebarIsOpen={setSidebarIsOpen}
          className={classNames("app-header", {
            "app-header--sidebar-closed": !sidebarIsOpen,
            "app-header--sidebar-open": sidebarIsOpen
          })}
        />
        {sidebar}
      </Suspense>
    )
    : (
      ""
    )
  
  const mobileHeader = isAuthenticated ? (
    <>
      <Suspense fallback={<LoadingMessage />}>
        <div ref={sidebarRef}>
          <div className="w-100 header-color mbl-pos d-flex flex-row align-items-center">
            <CButton
              onClick={() => setMblSidebar(!mblSidebar)}
              className="header-color d-flex justify-content-start position-fixed left-0"
            >
              <HamburgerIcon size="20px" />
            </CButton>
            <div className="deloitte-logo-mobile">
              <img
                src={DeloitteLogo}
                alt={"deloitte-logo"}
                height="30px"
                width="60px"
              />
            </div>
          </div>
          <DefaultAside
            mobileScreen={isSmallScreen}
            isOpen={mblSidebar}
            setIsOpen={setMblSidebar}
          />
        </div>
      </Suspense>
    </>
  ) : (
    ""
  )

  const projectAccountsMonitor = isAuthenticated
    ? (
    <ProjectAccountsMonitor />
      )
    : (
        ""
      )

  return (
    <SWRConfig
      value={{
        revalidateIfStale: false,
        revalidateOnReconnect: false,
        refreshWhenOffline: false,
        revalidateOnFocus: false,
        dedupingInterval: 840000,
        use: [conditionalFetchingMiddleware, keepPreviousResponseMiddleware],
      }}
    >
      <CContainer className="app m-0 p-0" fluid id="app-container">
        {isSmallScreen ? mobileHeader : header}
        {projectAccountsMonitor}
        {isSmallScreen ? (
          <GreyedOut enable={mblSidebar} mblScreen={true}>
            <CContainer
              className={classNames("app-body", {
                "app-body--unauthenticated": !isAuthenticated,
                "app-body--sidebar-closed": !sidebarIsOpen && isAuthenticated,
                "app-body--sidebar-open": sidebarIsOpen && isAuthenticated
              })}
              tag="main"
              fluid
            >
              {children}
            </CContainer>
          </GreyedOut>
        ) : (
          <CContainer
            className={classNames("app-body", {
              "app-body--unauthenticated": !isAuthenticated,
              "app-body--sidebar-closed": !sidebarIsOpen && isAuthenticated,
              "app-body--sidebar-open": sidebarIsOpen && isAuthenticated
            })}
            tag="main"
            fluid
          >
            {children}
          </CContainer>
        )}
      </CContainer>
    </SWRConfig>
  )
}

DefaultLayout.propTypes = {
  isAuthenticated: PropTypes.bool,
  children: PropTypes.node
}

export default DefaultLayout
