/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import PropTypes from "prop-types"
import { FaExclamationCircle } from "react-icons/fa"
import { Card } from "reactstrap"

const styles = {
  wrapper: css`
    position: relative;
    height: 100%;
  `,
  card: css`
    min-height: 200px;
    height: 90%;
    background-color: rgba(255, 0, 0, 0.2);
    color: rgba(255, 0, 0, 0.8);
  `,
  heading: css`
    margin: auto;
    display: inline-block;
  `
}

export default function ErrorCard({ error, overrideHeight = null }) {
  const cardOverrideStyles = {}
  if (error) {
    console.log(error)
  }
  if (overrideHeight) {
    cardOverrideStyles.height = overrideHeight
  }
  return (
    <div css={styles.wrapper}>
      <Card css={styles.card} style={cardOverrideStyles}>
        <h3 className="pulse-slow cursor-pointer" css={styles.heading}>
          <FaExclamationCircle />
        </h3>
      </Card>
    </div>
  )
}
ErrorCard.propTypes = {
  error: PropTypes.object,
  overrideHeight: PropTypes.number
}
