import ToasterProvider from "components/common/Context/ToasterContext"
import {
  LoadingSpinnerCard,
  LoadingSpinnerOverlay
} from "components/common/loading"
import { SimpleTimeline } from "components/common/timeline"
import {
  getIncidentComments,
  postIncidentTimelineComment
} from "extensions/usoc/api/service"
import PropTypes from "prop-types"
import React, { useContext, useState } from "react"
import { useSelector } from "react-redux"
import useSWR from "swr"
import { getTimeElapsed, useExtension, useUserAuth } from "utils"

async function fetchIncidentComments(auth, extension, projectID, incidentId) {
  const host = extension?.config?.env?.clientApiGateway?.URL
  if (host === undefined) throw new Error("No api hostname specified.")
  const data = await getIncidentComments(
    { auth, host },
    projectID,
    incidentId
  ).catch((err) => {
    throw err
  })
  return data
}

export default function IncidentTimeline({ incidentId }) {
  const auth = useUserAuth()
  const extension = useExtension()
  const host = extension?.config?.env?.clientApiGateway?.URL
  const [message, setMessage] = useState("")
  const [isLoading, setIsLoading] = useState(false)
  const enqueueToast = useContext(ToasterProvider)

  const projectID = useSelector((state) => {
    return state.settings.currentProject.id
  })

  const {
    data: incCommentsData,
    mutate,
    isValidating
  } = useSWR(
    () =>
      projectID && incidentId
        ? `/projects/${projectID}/${incidentId}/get_incident_comments`
        : null,
    () => fetchIncidentComments(auth, extension, projectID, incidentId),
    { suspense: true }
  )

  const timelineItems = Array.isArray(incCommentsData?.data)
    ? incCommentsData.data.map((incComment) => ({
        title: getTimeElapsed(new Date(incComment.comment_created_on)),
        cardTitle: incComment.comment_created_by,
        cardDetailedText: incComment.comment
      }))
    : []
  const handleSubmit = async () => {
    setIsLoading(true)
    const body = {
      comment: message
    }
    const response = await postIncidentTimelineComment(
      { auth, host, body: JSON.stringify(body) },
      projectID,
      incidentId
    )
    if (!response.error) {
      enqueueToast("success", null, "Comment added sucessfully!!")
      mutate()
    } else {
      enqueueToast("failure", null, "Comment can't be added. Try again")
    }
    setMessage("")
    setIsLoading(false)
  }

  return (
    <div className="w-75 m-auto">
      <LoadingSpinnerOverlay isLoading={isLoading} spinnerSize="8rem">
        {isValidating ? (
          <LoadingSpinnerCard />
        ) : (
          <SimpleTimeline
            timelineItems={timelineItems}
            submitMsg={(e) => handleSubmit(e)}
            message={message}
            changeMsg={(e) => setMessage(e.target.value)}
            chronoUpdate={isLoading}
            mutateUpdate={isValidating}
          />
        )}
      </LoadingSpinnerOverlay>
    </div>
  )
}
IncidentTimeline.propTypes = {
  incidentId: PropTypes.string
}
