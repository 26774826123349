import { createFetcher } from "api/helpers"

const fetcherConfiguration = {
  method: "get",
  makeRequestUrl: (host, params) => {
    return `${host}/cp/get_client_portal_feedback${params ? `?${params}` : ""}`
  },
  checkRequiredArguments: () => {
    
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
