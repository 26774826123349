import { CButton, CCol, CRow } from "@coreui/react"
import { useTheme } from "components/common/Context/ThemeContext"
import PropTypes from "prop-types"
import React from "react"

export default function FormActions({
  onSubmit,
  onCancel,
  className = "",
  cancelBtnStyling = "",
  isSubmitDisabled = false,
  submitText = "Submit",
  cancelText = "Cancel"
}) {
  const theme = useTheme()
  const maskSubmitStyle = {
    backgroundColor: theme.serviceRequestButton,
    borderColor: theme.serviceRequestButton
  }
  const maskCancelStyle = {
    backgroundColor: theme.cancelBtnBg,
    borderColor: theme.serviceRequestButton,
    color: theme.serviceRequestButton,
    border: "1px solid"
  }

  return (
    <CRow className={`${className} mx-2 my-4`}>
      <CCol md="12" className="d-flex justify-content-flex-end">
        <CButton
          style={maskCancelStyle}
          variant="outline"
          color="primary"
          onClick={() => onCancel && onCancel()}
          className={`bg-white ${cancelBtnStyling}`}
        >
          {cancelText}
        </CButton>
        <CButton
          style={maskSubmitStyle}
          color="primary"
          data-testid={"form-submit"}
          disabled={isSubmitDisabled}
          onClick={() => onSubmit && onSubmit()}
        >
          {submitText}
        </CButton>
      </CCol>
    </CRow>
  )
}
FormActions.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  className: PropTypes.string,
  cancelBtnStyling: PropTypes.string,
  isSubmitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string
}
