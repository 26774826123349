import { useOktaAuth } from "@okta/okta-react"
import React from "react"
import { useDispatch } from "react-redux"
import { userLogout } from "store/slices/user"

export function Logout () {
  const auth = useOktaAuth()
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(userLogout({ auth }))
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return null
}

export default Logout
