import { createFetcher } from "api/helpers"
const date = new Date()
const firstDay = new Date(date.getFullYear(), date.getMonth() - 1, 1)
const lastDay = new Date(date.getFullYear(), date.getMonth() - 1 + 1, 0)

const fetcherConfiguration = {
  // console.log("Account List:",accountsListString)
  method: "get",
  makeRequestUrl: (host, project, params) => {
    return `${host}/finance/${project}/get_csp_spending?account_list=${params}&start_date=${firstDay.getFullYear()}-${firstDay.getMonth() + 1}-${firstDay.getDate()}&end_date=${lastDay.getFullYear()}-${lastDay.getMonth() + 1}-${lastDay.getDate()}`
  },
  checkRequiredArguments: (params) => {
    if (params === undefined) throw new Error("Incorrect data supplied.")
  },
  makeResponseData: (json) => {
    return json
  }
}
export default createFetcher(fetcherConfiguration)
