import "./export.scss"

import {
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle
} from "@coreui/react"
import Upload from "assets/img/upload.png"
import PropTypes from "prop-types"
import React from "react"
import ReactExport from "react-export-excel"

const ExcelFile = ReactExport.ExcelFile
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet

const stringfyAllValues = (dataset) => {
  dataset.data = dataset?.data?.map((eachData) =>
    eachData?.map((eachValue) => ({
      ...eachValue,
      value: String(eachValue.value)
    }))
  )
  return dataset
}

export default function MultipleExport({
  children = <img src={Upload} alt="upload" style={{ height: "1.5rem" }} />,
  excelDatasets,
  fileName = ""
}) {
  return (
    <CDropdown className="export-btn">
      <CDropdownToggle className="py-0">{children}</CDropdownToggle>
      <CDropdownMenu>
        {Array.isArray(excelDatasets) &&
          excelDatasets.map((dataset, index) => {
            return (
              <ExcelFile
                key={index}
                filename={index === 0 ? fileName : fileName + "_filtered"}
                element={
                  <CDropdownItem>
                    {index === 0 ? "Export All Data" : "Export Filtered Data"}
                  </CDropdownItem>
                }
              >
                <ExcelSheet
                  dataSet={[stringfyAllValues(dataset)]}
                  name={dataset.name}
                  key={dataset.name}
                />
              </ExcelFile>
            )
          })}
      </CDropdownMenu>
    </CDropdown>
  )
}
MultipleExport.propTypes = {
  children: PropTypes.node,
  excelDatasets: PropTypes.array,
  fileName: PropTypes.string
}
