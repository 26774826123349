import { render } from "@testing-library/react"
import { AccountContextProvider } from "components/common/Context/AccountContext"
import { CurrencyContextProvider } from "components/common/Context/CurrencyContext"
import { MediaContextProvider } from "components/common/Context/MediaContext"
import { ThemeContextProvider } from "components/common/Context/ThemeContext"
import { ToasterContextProvider } from "components/common/Context/ToasterContext"
import { LoadingSpinnerCard } from "components/common/loading"
import extension, { ExtensionContext } from "extensions"
import { mockAccountList } from "mocks/responses/itsmResponses"
import PropTypes from "prop-types"
import React from "react"
import { BrowserRouter } from "react-router-dom"
import { SWRConfig } from "swr"
import {
  conditionalFetchingMiddleware,
  keepPreviousResponseMiddleware
} from "utils/swrMiddlewares"

function useLoadedExtension () {
  const [_extension, setExtension] = React.useState(null)
  React.useEffect(() => {
    (async () => {
      const loadedExtension = await extension
      setExtension(loadedExtension)
    })()
  }, [])
  return _extension
}

const accounts = mockAccountList.account_attribute

const Wrapper = ({ children }) => {
  const extension = useLoadedExtension()
  return (
    <>
      <SWRConfig
        value={{
          dedupingInterval: 0,
          provider: () => new Map(),
          use: [conditionalFetchingMiddleware, keepPreviousResponseMiddleware]
        }}
      >
        <ThemeContextProvider>
          <ExtensionContext.Provider value={extension}>
            <MediaContextProvider>
              <ToasterContextProvider>
                <AccountContextProvider
                  accounts={accounts}
                  selectedAccounts={accounts}
                >
                  <CurrencyContextProvider>
                    <BrowserRouter>
                      <React.Suspense fallback={<LoadingSpinnerCard />}>
                        {extension ? children : null}
                        <div id="drawerHolder"></div>
                        <div id="status-modal"></div>
                      </React.Suspense>
                    </BrowserRouter>
                  </CurrencyContextProvider>
                </AccountContextProvider>
              </ToasterContextProvider>
            </MediaContextProvider>
          </ExtensionContext.Provider>
        </ThemeContextProvider>
      </SWRConfig>
    </>
  )
}

Wrapper.propTypes = {
  children: PropTypes.node
}

export default (component, options) => render(component, { wrapper: Wrapper, ...options })
