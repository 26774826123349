import makeHttpRequest from "./makeHttpRequest"
import optionsForCustomAuthorizer from "./optionsForCustomAuthorizer"

export default function (
  staticConfigurations,
  createOptions = optionsForCustomAuthorizer
) {
  return async (runtimeConfigurations, ...args) => {
    const configurations = {
      ...staticConfigurations,
      ...runtimeConfigurations
    }
    const host = configurations.host
    if (host === undefined) { throw Error("No hostname specified in request url.") }
    if (configurations.makeRequestUrl === undefined) { throw Error('Fetcher configuration is missing function "makeRequestUrl"') }
    if (configurations.checkRequiredArguments === undefined) {
      throw Error(
        'Fetcher configuration is missing function "checkRequiredArguments"'
      )
    }
    if (configurations.makeResponseData === undefined) {
      throw Error(
        'Fetcher configuration is missing function "makeResponseData"'
      )
    }

    configurations.checkRequiredArguments(...args)
    if (configurations.makeBody !== undefined) {
      configurations.body = configurations.makeBody(...args)
    }
    const url = configurations.makeRequestUrl(host, ...args)
    const options = createOptions(configurations)

    try {
      const response = await makeHttpRequest(url, options)
      if (configurations.returnRawResponse) {
        const text = await response.text()
        const data = configurations.makeResponseData(text)
        return {
          data,
          error: response.ok ? null : response.statusText || true
        }
      }
      const json = await response.json()
      const data = configurations.makeResponseData(json)
      return {
        data,
        error: response.ok ? null : response.statusText || true
      }
    } catch (error) {
      console.error(`An error occured: ${error}`)
      const data = configurations.makeResponseData()
      if (data) {
        return {
          data,
          error
        }
      } else {
        console.error(`An error occured: ${error}`)
        return {
          data: null,
          error
        }
      }
    }
  }
}
