import { range } from "lodash"
import PropTypes from "prop-types"
import React from "react"

export default function Pagination({
  itemsPerPage,
  setItemsPerPage,
  currentPage,
  setCurrentPage,
  minLimit,
  setMinLimit,
  maxLimit,
  setMaxLimit,
  pageNumberLimit,
  noOfPages,
  hideItemsPerPageDropdown
}) {
  const handleClick = (event) => {
    setCurrentPage(parseInt(event.target.id))
  }

  const renderPageNumbers = [...range(1, noOfPages + 1)].map((page) => {
    if (page < maxLimit + 1 && page > minLimit) {
      return (
        <li
          key={page}
          id={page}
          onClick={handleClick}
          className={currentPage === page ? "active" : null}
        >
          {page}
        </li>
      )
    } else {
      return null
    }
  })

  const handlePrevBtn = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)

      if ((currentPage - 1) % pageNumberLimit === 0) {
        setMaxLimit(maxLimit - pageNumberLimit)
        setMinLimit(minLimit - pageNumberLimit)
      }
    }
  }

  const handleNxtBtn = () => {
    if (currentPage < noOfPages) {
      setCurrentPage(currentPage + 1)

      if (currentPage + 1 > maxLimit) {
        setMaxLimit(maxLimit + pageNumberLimit)
        setMinLimit(minLimit + pageNumberLimit)
      }
    }
  }

  let beforeDot = null
  if (currentPage > pageNumberLimit) {
    beforeDot = <li>&hellip;</li>
  }

  let afterDot = null
  if (maxLimit < noOfPages) {
    afterDot = <li>&hellip;</li>
  }

  const onRecordsChange = (e) => {
    setItemsPerPage(e.target.value)
    setCurrentPage(1)
    setMinLimit(0)
    setMaxLimit(5)
  }

  return (
    <>
      <ul className="pagination">
        <li onClick={handlePrevBtn} style={{ fontWeight: "bolder" }}>
          &lt;
        </li>
        {beforeDot}
        {renderPageNumbers}
        {afterDot}
        <li onClick={handleNxtBtn} style={{ fontWeight: "bolder" }}>
          &gt;
        </li>
      </ul>
      {!hideItemsPerPageDropdown && (
        <select
          defaultValue={itemsPerPage}
          className="records-dd"
          onChange={onRecordsChange}
          data-testid="pagination-itemsPerPage-dd"
        >
          <option value={20}>20 records</option>
          <option value={40}>40 records</option>
          <option value={60}>60 records</option>
        </select>
      )}
    </>
  )
}
Pagination.propTypes = {
  itemsPerPage: PropTypes.number,
  setItemsPerPage: PropTypes.func,
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  minLimit: PropTypes.number,
  setMinLimit: PropTypes.func,
  maxLimit: PropTypes.number,
  setMaxLimit: PropTypes.func,
  pageNumberLimit: PropTypes.number,
  noOfPages: PropTypes.number,
  hideItemsPerPageDropdown: PropTypes.bool
}
